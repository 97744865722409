import { Button, Modal, Backdrop, IconButton } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import { useState, useCallback, useEffect } from "react"
import { DemoContainer } from "@mui/x-date-pickers/internals/demo"
import { LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker"
import InfoTwoToneIcon from "@mui/icons-material/InfoTwoTone"
import CloseIcon from "@mui/icons-material/Close"
import { FullScreen, useFullScreenHandle } from "react-full-screen"
import { Card, CardContent, CardHeader, ButtonGroup } from "@mui/material"
import HighchartsReact from "highcharts-react-official"
import fullScreen from "highcharts/modules/full-screen"
import wordCloud from "highcharts/modules/wordcloud.js"
import "highcharts/css/annotations/popup.css"
import Highcharts from "highcharts/highstock"
import indicatorsAll from "highcharts/indicators/indicators-all"
import annotationsAdvanced from "highcharts/modules/annotations-advanced"
import priceIndicator from "highcharts/modules/price-indicator"
import { getGraphData } from "../../utils/functions"
import { STOCKS_WITH_SECTORS, SINGLE_GRAPH_DURATION } from "../../constants"

wordCloud(Highcharts)
require("highcharts/modules/exporting")(Highcharts)
indicatorsAll(Highcharts)
annotationsAdvanced(Highcharts)
priceIndicator(Highcharts)
fullScreen(Highcharts)

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: "white !important"
    },
    icon: {
        fill: "blue"
    },
    modal: {
        margin: "auto",
        marginTop: "5%",
        flexGrow: 1,
        alignItems: "center",
        justifyContent: "center"
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 3, 2)
    },
    fullscreenBackground: {
        border: "solid 2px red !important",
        height: "100% !important"
    },
    summaryRoot: {
        justifyContent: "flex-start !important"
    },
    summaryContent: {
        display: "flex",
        alignItems: "center",
        flexGrow: "unset !important",
        marginLeft: "10px"
    }
}))

const SectorViewDashboard = ({
    displayDate,
    graphDateTime,
    checkFutureData,
    setRun,
    setGraphToggle,
    setViews,
    buzzGraph,
    sentiGraph,
    buzzGraphChange,
    sentiGraphChange,
    formatDayjs,
    setGraphData,
    setBuzzGraph,
    setSentiGraph,
    setBuzzGraphChange,
    setSentiGraphChange,
    graphViews,
    GRAPH_NORMALIZE_FACTOR,
    sectors,
    graphData
}) => {
    const classes = useStyles()
    const [dateChangeModal, setDateChangeModal] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [btnGroups, setbtnGroups] = useState([1, 1, 1, 1])
    const screen1 = useFullScreenHandle()
    const screen2 = useFullScreenHandle()
    const screen3 = useFullScreenHandle()
    const screen4 = useFullScreenHandle()

    const handleGetGraphWithDate = async () => {
        setDateChangeModal(false)
    }

    const topBar = (key, title) => {
        let KVPair = ["buzz", "sentiment", "changeBuzz", "changeSentiment"]
        return (
            <div className='row mb-2 ' style={{}}>
                <h5 className='col-md-6 m-0'>{title}</h5>
                <ButtonGroup
                    className='col-md-auto'
                    style={{
                        textAlign: "right",
                        marginRight: "1px !important",
                        marginLeft: "auto",
                        paddingLeft: "auto"
                    }}
                    size='small'
                    aria-label='text button group'>
                    <Button
                        style={{
                            backgroundColor:
                                btnGroups[key - 1] === 1
                                    ? "#4b8fcd"
                                    : "lightGrey",
                            height: "2rem",
                            color: btnGroups[key - 1] === 1 ? "white" : "black"
                        }}
                        size='small'
                        variant='contained'
                        onClick={() => {
                            setViews(key, "one_day")
                            let temp = btnGroups
                            temp[key - 1] = 1
                            setbtnGroups((prevState) => {
                                return temp
                            })
                        }}>
                        1 day
                    </Button>
                    <Button
                        style={{
                            backgroundColor:
                                btnGroups[key - 1] === 2
                                    ? "#4b8fcd"
                                    : "lightGrey",
                            height: "2rem",
                            color: btnGroups[key - 1] === 2 ? "white" : "black"
                        }}
                        size='small'
                        variant='contained'
                        onClick={() => {
                            setViews(key, "one_week")
                            let temp = btnGroups
                            temp[key - 1] = 2
                            setbtnGroups((prevState) => {
                                return temp
                            })
                        }}>
                        7 days
                    </Button>
                    <Button
                        style={{
                            backgroundColor:
                                btnGroups[key - 1] === 3
                                    ? "#4b8fcd"
                                    : "lightGrey",
                            height: "2rem",
                            color: btnGroups[key - 1] === 3 ? "white" : "black"
                        }}
                        size='small'
                        variant='contained'
                        onClick={() => {
                            setViews(key, "two_week")
                            let temp = btnGroups
                            temp[key - 1] = 3
                            setbtnGroups((prevState) => {
                                return temp
                            })
                        }}>
                        14 days
                    </Button>
                    <Button
                        style={{
                            backgroundColor:
                                btnGroups[key - 1] === 4
                                    ? "#4b8fcd"
                                    : "lightGrey",
                            height: "2rem",
                            color: btnGroups[key - 1] === 4 ? "white" : "black"
                        }}
                        size='small'
                        variant='contained'
                        onClick={() => {
                            setViews(key, "one_month")
                            let temp = btnGroups
                            temp[key - 1] = 4
                            setbtnGroups((prevState) => {
                                return temp
                            })
                        }}>
                        30 days
                    </Button>
                    {/* <Button
                            style={{
                                backgroundColor:
                                    btnGroups[key - 1] === 5
                                        ? "#4b8fcd"
                                        : "lightGrey",
                                height: "2rem",
                                color: btnGroups[key - 1] === 5 ? "white" : "black"
                            }}
                            size='small'
                            variant='contained'
                            onClick={() => {
                                setViews(key, "two_month")
                                let temp = btnGroups
                                temp[key - 1] = 5
                                setbtnGroups((prevState) => {
                                    return temp
                                })
                            }}>
                            60 days
                        </Button> */}
                </ButtonGroup>
            </div>
        )
    }

    const fetchGraphData = async (payload, graphType, stateKey) => {
        const response = await getGraphData({
            ...payload,
            graph_type: graphType
        })

        setGraphData((prevGraphData) => ({
            ...prevGraphData,
            [stateKey]: response
        }))

        return response
    }

    const findSeries = (Sname, data, changeFlag) => {
        let data1 = null
        let data2 = null
        let data3 = null
        let data4 = null
        if (changeFlag) {
            data1 = data.find((o) => o.fields === Sname + "_st_change_percent")
            data2 = data.find(
                (o) => o.fields === Sname + "_news_change_percent"
            )
            data3 = data.find((o) => o.fields === Sname + "_st_change")
            data4 = data.find((o) => o.fields === Sname + "_news_change")
            return { news: data2, st: data1, newsAbs: data4, stAbs: data3 }
        } else {
            data1 = data.find((o) => o.fields === Sname + "_st")
            data2 = data.find((o) => o.fields === Sname + "_news")

            return { news: data2, st: data1 }
        }
    }

    const useGraphUpdate = (graphData, graphViews, key, processFunction) => {
        useEffect(() => {
            if (graphData[key]) {
                processFunction(graphData[key])
            }
        }, [graphData[key], graphViews[key]])
    }

    const mapSectorDataByDuration = (
        durationData,
        sector,
        isSentiment = false
    ) => {
        const sectorObj = STOCKS_WITH_SECTORS.find(
            (sectorItem) => sectorItem.sector === sector
        )

        if (sectorObj) {
            let total = 0
            sectorObj.tickers.forEach((ticker) => {
                if (isSentiment) {
                    total +=
                        (durationData[ticker] || 0) / sectorObj.tickers.length
                } else {
                    total += durationData[ticker] || 0
                }
            })

            return total
        }

        // If sector not found, return 0
        return 0
    }

    // Get Single Buzz Graph View
    // 1.
    const getSingleBuzzGraphView = (
        { category },
        sectorList,
        arrB,
        response
    ) => {
        const stockDataByDuration = getSingleAbsoluteGraphData(
            response,
            category
        )

        const singleGraphData = getSingleBuzzGraphData(stockDataByDuration)

        setBuzzGraph(() => {
            return {
                title: {
                    text: "Trending stocks by Social Buzz"
                },
                chart: {
                    type: "bar",
                    borderColor: "#c0c0c0",
                    borderWidth: 1.25,
                    borderRadius: 5
                },
                lang: {
                    thousandsSep: ","
                },
                colors: ["#43a84a", "#f5d561"],
                legend: {
                    enabled: false,
                    title: {
                        text: "",
                        style: {
                            fontStyle: "italic"
                        }
                    }
                },
                plotOptions: {
                    series: {
                        stacking: "normal",
                        cursor: "pointer",
                        events: {
                            click: function () {
                                drawBuzzGraph(arrB, sectorList, response)
                            }
                        }
                    }
                },
                credits: {
                    text: "finsoftai.com",
                    href: 'javascript:window.open("http://www.finsoftai.com/", "_blank")',
                    style: {
                        fontSize: 14,
                        textDecoration: "underline",
                        color: "#0248b3"
                    },
                    position: {
                        align: "right",
                        x: -10
                    }
                },
                stockTools: {
                    gui: {
                        enabled: false
                    }
                },
                xAxis: [
                    {
                        title: {
                            text: category,
                            style: {
                                fontWeight: "bold",
                                fontSize: "17px",
                                fontFamily: "Poppins"
                            }
                        },
                        categories: SINGLE_GRAPH_DURATION,
                        labels: {
                            style: {
                                fontFamily: "Poppins",
                                fontWeight: "bold",
                                color: "#A8A8A8"
                            }
                        }
                    }
                ],

                yAxis: [
                    {
                        title: {
                            text: "Average Social Buzz",
                            style: {
                                fontWeight: "bold",
                                fontSize: "17px",
                                fontFamily: "Poppins"
                            }
                        }
                    }
                ],
                exporting: {
                    buttons: {
                        contextButton: {
                            menuItems: ["viewFullscreen"]
                        }
                    }
                },
                tooltip: {
                    useHTML: true,
                    headerFormat:
                        '<div style="text-align:center;">  {point.x:,.0f} </div>',
                    thousandsSep: ",",
                    pointFormat:
                        '<div style="text-align:center;"><b>Buzz volume: {point.y:,.1f}</b><p>Social Media:{point.st:,.2f} | News:{point.news:,.2f} </p></div>'
                },

                series: singleGraphData
            }
        })
    }

    // 2.
    const getSingleSentimentGraphView = (
        { category },
        sectorList,
        arrB,
        response
    ) => {
        const stockDataByDuration = getSingleAbsoluteGraphData(
            response,
            category,
            true
        )

        const singleGraphData = {
            name: "Score",
            type: "bar",
            pointStart: 0,
            data: stockDataByDuration
                .slice(0, stockDataByDuration.length / 2)
                .map((prevIndex, currIndex) => ({
                    y:
                        (prevIndex +
                            stockDataByDuration.slice(
                                stockDataByDuration.length / 2
                            )[currIndex]) /
                        2,
                    st: prevIndex,
                    news: stockDataByDuration.slice(
                        stockDataByDuration.length / 2
                    )[currIndex]
                })),
            color: "#43a84a",
            negativeColor: "#e64736"
        }

        setSentiGraph(() => {
            return {
                title: {
                    text: "Trending stocks by Sentiment"
                },
                chart: {
                    type: "bar",
                    borderColor: "#c0c0c0",
                    borderWidth: 1.25,
                    borderRadius: 5
                },
                legend: {
                    enabled: false
                },
                plotOptions: {
                    series: {
                        stacking: "normal",
                        cursor: "pointer",
                        events: {
                            click: function (event) {
                                drawSentimentGraph(arrB, sectorList, response)
                            }
                        }
                    }
                    // visible: false,
                },
                credits: {
                    text: "finsoftai.com",
                    href: 'javascript:window.open("http://www.finsoftai.com/", "_blank")',
                    style: {
                        fontSize: 14,
                        textDecoration: "underline",
                        color: "#0248b3"
                    },
                    position: {
                        align: "right",
                        x: -10
                    }
                },
                stockTools: {
                    gui: {
                        enabled: false
                    }
                },
                xAxis: [
                    {
                        title: {
                            text: category,
                            style: {
                                fontWeight: "bold",
                                fontSize: "15px",
                                fontFamily: "Poppins"
                            }
                        },
                        categories: SINGLE_GRAPH_DURATION,

                        labels: {
                            style: {
                                fontFamily: "Poppins",
                                fontWeight: "bold",
                                color: "#A8A8A8"
                            }
                        }
                    }
                ],
                // legend:{enabled:false},
                yAxis: [
                    {
                        title: {
                            text: "Average Social Sentiment Score",
                            style: {
                                fontWeight: "bold",
                                fontSize: "15px",
                                fontFamily: "Poppins"
                            }
                        }
                    }
                ],
                exporting: {
                    buttons: {
                        contextButton: {
                            menuItems: ["viewFullscreen"]
                        }
                    }
                },
                tooltip: {
                    useHTML: true,
                    headerFormat:
                        '<div style="text-align:center;">  {point.x:,.0f} </div>',
                    thousandsSep: ",",
                    pointFormat:
                        '<div style="text-align:center;"><b>Social Sentiment: {point.y:,.3f}</b><p>Social Media:{point.st:,.3f} | News:{point.news:,.3f} </p></div>'
                    // valueDecimal:1,
                    // valueDecimal:1,
                },
                series: singleGraphData
            }
        })
    }

    // 3.
    const getSingleBuzzChangeGraphView = (
        { category },
        sectorList,
        arrB,
        response
    ) => {
        const one_day_data = findSeries("one_day", response, 1, "buzz_change")
        const one_week_data = findSeries("one_week", response, 1, "buzz_change")
        const two_week_data = findSeries("two_week", response, 1, "buzz_change")
        const one_month_data = findSeries(
            "one_month",
            response,
            1,
            "buzz_change"
        )

        const { data: arr_one_day } = getSeries(
            one_day_data["news"],
            one_day_data["st"],
            one_day_data["newsAbs"],
            one_day_data["stAbs"]
        )
        const { data: arr_one_week } = getSeries(
            one_week_data["news"],
            one_week_data["st"],
            one_week_data["newsAbs"],
            one_week_data["stAbs"]
        )
        const { data: arr_two_week } = getSeries(
            two_week_data["news"],
            two_week_data["st"],
            two_week_data["newsAbs"],
            two_week_data["stAbs"]
        )
        const { data: arr_one_month } = getSeries(
            one_month_data["news"],
            one_month_data["st"],
            one_month_data["newsAbs"],
            one_month_data["stAbs"]
        )

        const stockDataByDuration = getSingleChangeGraphData(
            [arr_one_day, arr_one_week, arr_two_week, arr_one_month],
            category
        )

        const singleGraphData = {
            name: "Score",
            type: "bar",
            pointStart: 0,
            data: stockDataByDuration
                .slice(0, stockDataByDuration.length / 2)
                .map((prevIndex, currIndex) => ({
                    y:
                        (prevIndex +
                            stockDataByDuration.slice(
                                stockDataByDuration.length / 2
                            )[currIndex]) /
                        2,
                    stPercent: prevIndex,
                    newsPercent: stockDataByDuration.slice(
                        stockDataByDuration.length / 2
                    )[currIndex]
                })),
            color: "#43a84a",
            negativeColor: "#e64736"
        }

        setBuzzGraphChange(() => {
            return {
                title: {
                    text: "Trending stocks by Change in Social Buzz"
                },
                chart: {
                    type: "bar",
                    borderColor: "#c0c0c0",
                    borderWidth: 1.25,
                    borderRadius: 5,
                    styledmode: "on"
                },
                plotOptions: {
                    series: {
                        stacking: "normal",
                        cursor: "pointer",
                        events: {
                            click: function (event) {
                                drawBuzzChangeGraph(arrB, sectorList, response)
                            }
                        }
                    }
                },
                legend: {
                    enabled: false
                },
                colors: ["#43a84a", "#f5d561"],
                lang: {
                    thousandsSep: ","
                },
                credits: {
                    text: "finsoftai.com",
                    href: 'javascript:window.open("http://www.finsoftai.com/", "_blank")',
                    style: {
                        fontSize: 14,
                        textDecoration: "underline",
                        color: "#0248b3"
                    },
                    position: {
                        align: "right",
                        x: -10
                    }
                },
                stockTools: {
                    gui: {
                        enabled: false
                    }
                },
                xAxis: [
                    {
                        title: {
                            text: category,
                            style: {
                                fontWeight: "bold",
                                fontSize: "15px",
                                fontFamily: "Poppins"
                            }
                        },
                        categories: SINGLE_GRAPH_DURATION,

                        labels: {
                            style: {
                                fontFamily: "Poppins",
                                fontWeight: "bold",
                                color: "#A8A8A8"
                            }
                        }
                    }
                ],
                // legend:{enabled:false},
                yAxis: [
                    {
                        title: {
                            text: "Average Change in Social Buzz",
                            style: {
                                fontWeight: "bold",
                                fontSize: "15px",
                                fontFamily: "Poppins"
                            }
                        },
                        labels: {
                            formatter: function () {
                                return this.value + "%"
                            }
                        }
                    }
                ],
                exporting: {
                    buttons: {
                        contextButton: {
                            menuItems: ["viewFullscreen"]
                        }
                    }
                },

                tooltip: {
                    useHTML: true,
                    headerFormat:
                        '<div style="text-align:center;">  {point.x:,.0f} </div>',
                    thousandsSep: ",",
                    pointFormat:
                        '<div style="text-align:center;"><b>Change in Buzz volume : {point.y:,.2f}%</b><p>Social Media : {point.stPercent:,.2f}% | News : {point.newsPercent:,.2f}%</p></div>', // <br></br>Social Media value: {point.stAbs:,.2f} | News value: {point.newsAbs:,.2f}
                    // valueDecimal:1,
                    padding: 4
                },
                series: singleGraphData
            }
        })
    }

    // 4.
    const getSingleSentimentChangeGraphView = (
        { category },
        sectorList,
        arrB,
        response
    ) => {
        const one_day_data = findSeries("one_day", response, 1, "buzz_change")
        const one_week_data = findSeries("one_week", response, 1, "buzz_change")
        const two_week_data = findSeries("two_week", response, 1, "buzz_change")
        const one_month_data = findSeries(
            "one_month",
            response,
            1,
            "buzz_change"
        )

        const { data: arr_one_day } = getSeries(
            one_day_data["news"],
            one_day_data["st"],
            one_day_data["newsAbs"],
            one_day_data["stAbs"]
        )
        const { data: arr_one_week } = getSeries(
            one_week_data["news"],
            one_week_data["st"],
            one_week_data["newsAbs"],
            one_week_data["stAbs"]
        )
        const { data: arr_two_week } = getSeries(
            two_week_data["news"],
            two_week_data["st"],
            two_week_data["newsAbs"],
            two_week_data["stAbs"]
        )
        const { data: arr_one_month } = getSeries(
            one_month_data["news"],
            one_month_data["st"],
            one_month_data["newsAbs"],
            one_month_data["stAbs"]
        )

        const stockDataByDuration = getSingleChangeGraphData(
            [arr_one_day, arr_one_week, arr_two_week, arr_one_month],
            category
        )

        const singleGraphData = {
            name: "Score",
            type: "bar",
            pointStart: 0,
            data: stockDataByDuration
                .slice(0, stockDataByDuration.length / 2)
                .map((prevIndex, currIndex) => ({
                    y:
                        (prevIndex +
                            stockDataByDuration.slice(
                                stockDataByDuration.length / 2
                            )[currIndex]) /
                        2,
                    stPercent: prevIndex,
                    newsPercent: stockDataByDuration.slice(
                        stockDataByDuration.length / 2
                    )[currIndex]
                })),
            color: "#43a84a",
            negativeColor: "#e64736"
        }

        setSentiGraphChange(() => {
            return {
                title: {
                    text: "Trending stocks by Change in Sentiment"
                },
                chart: {
                    type: "bar",
                    borderColor: "#c0c0c0",
                    borderWidth: 1.25,
                    borderRadius: 5,
                    styledmode: "on"
                },
                plotOptions: {
                    series: {
                        stacking: "normal",
                        cursor: "pointer",
                        events: {
                            click: function (event) {
                                drawSentimentChangeGraph(
                                    arrB,
                                    sectorList,
                                    response
                                )
                            }
                        }
                    }
                },
                legend: {
                    enabled: false
                },
                colors: ["#43a84a", "#f5d561"],
                lang: {
                    thousandsSep: ","
                },
                credits: {
                    text: "finsoftai.com",
                    href: 'javascript:window.open("http://www.finsoftai.com/", "_blank")',
                    style: {
                        fontSize: 14,
                        textDecoration: "underline",
                        color: "#0248b3"
                    },
                    position: {
                        align: "right",
                        x: -10
                    }
                },
                stockTools: {
                    gui: {
                        enabled: false
                    }
                },
                xAxis: [
                    {
                        title: {
                            text: category,
                            style: {
                                fontWeight: "bold",
                                fontSize: "15px",
                                fontFamily: "Poppins"
                            }
                        },
                        categories: SINGLE_GRAPH_DURATION,

                        labels: {
                            style: {
                                fontFamily: "Poppins",
                                fontWeight: "bold",
                                color: "#A8A8A8"
                            }
                        }
                    }
                ],
                yAxis: [
                    {
                        title: {
                            text: "Average Change in Social Sentiment Score",
                            style: {
                                fontWeight: "bold",
                                fontSize: "15px",
                                fontFamily: "Poppins"
                            }
                        },
                        labels: {
                            formatter: function () {
                                return this.value + "%"
                            }
                        }
                    }
                ],
                exporting: {
                    buttons: {
                        contextButton: {
                            menuItems: ["viewFullscreen"]
                        }
                    }
                },

                tooltip: {
                    useHTML: true,
                    headerFormat:
                        '<div style="text-align:center;">  {point.x:,.0f} </div>',
                    thousandsSep: ",",
                    pointFormat:
                        '<div style="text-align:center;"><b>Change in Buzz volume : {point.y:,.2f}%</b><p>Social Media : {point.stPercent:,.2f}% | News : {point.newsPercent:,.2f}%</p></div>', // <br></br>Social Media value: {point.stAbs:,.2f} | News value: {point.newsAbs:,.2f}
                    // valueDecimal:1,
                    padding: 4
                },
                series: singleGraphData
            }
        })
    }

    const getSingleAbsoluteGraphData = (data, sector, isSentiment) => {
        const labels = [
            "one_day_st",
            "one_week_st",
            "two_week_st",
            "one_month_st",
            "one_day_news",
            "one_week_news",
            "two_week_news",
            "one_month_news"
        ]

        const dataByDuration = data.filter((durationData) =>
            labels.includes(durationData.fields)
        )

        if (sector) {
            const stStockDataByDuration = dataByDuration
                .map((durationData) => {
                    if (
                        durationData.fields === "one_day_st" ||
                        durationData.fields === "one_week_st" ||
                        durationData.fields === "two_week_st" ||
                        durationData.fields === "one_month_st"
                    ) {
                        return mapSectorDataByDuration(
                            durationData,
                            sector,
                            isSentiment
                        )
                    }
                })
                .filter((item) => item === 0 || item)

            const newsStockDataByDuration = dataByDuration
                .map((durationData) => {
                    if (
                        durationData.fields === "one_day_news" ||
                        durationData.fields === "one_week_news" ||
                        durationData.fields === "two_week_news" ||
                        durationData.fields === "one_month_news"
                    ) {
                        return mapSectorDataByDuration(
                            durationData,
                            sector,
                            isSentiment
                        )
                    }
                })
                .filter((item) => item === 0 || item)

            return [...stStockDataByDuration, ...newsStockDataByDuration]
        }

        return dataByDuration
    }

    const getSingleBuzzGraphData = (arr) => {
        // Check if the array has an odd length
        if (arr.length % 2 !== 0) {
            return "Array length must be even."
        }

        // Find the middle index
        const middleIndex = arr.length / 2

        // Divide the array into two halves
        const firstHalf = arr.slice(0, middleIndex)
        const secondHalf = arr.slice(middleIndex)

        const result = {
            name: "Score",
            type: "bar",
            pointStart: 0,
            data: firstHalf.map((prevIndex, currIndex) => {
                const st = firstHalf[currIndex] * GRAPH_NORMALIZE_FACTOR
                const news = secondHalf[currIndex]
                const y = st + news

                return {
                    y,
                    st,
                    news
                }
            }),
            color: "#43a84a",
            negativeColor: "#e64736"
        }

        return result
    }

    const getSingleChangeGraphData = (
        [one_day_data, one_week_data, two_week_data, one_month_data],
        sector
    ) => {
        const getSafeValue = (data, key) => {
            const sectorObj = data.find((item) => item.name === sector)
            const value = sectorObj?.[key] ?? 0
            return isNaN(value) ? 0 : value
        }

        const oneDayStPercent = getSafeValue(one_day_data, "stPercent")
        const oneWeekStPercent = getSafeValue(one_week_data, "stPercent")
        const twoWeekStPercent = getSafeValue(two_week_data, "stPercent")
        const oneMonthStPercent = getSafeValue(one_month_data, "stPercent")

        const oneDayNewsPercent = getSafeValue(one_day_data, "newsPercent")
        const oneWeekNewsPercent = getSafeValue(one_week_data, "newsPercent")
        const twoWeekNewsPercent = getSafeValue(two_week_data, "newsPercent")
        const oneMonthNewsPercent = getSafeValue(one_month_data, "newsPercent")

        return [
            oneDayStPercent,
            oneWeekStPercent,
            twoWeekStPercent,
            oneMonthStPercent,
            oneDayNewsPercent,
            oneWeekNewsPercent,
            twoWeekNewsPercent,
            oneMonthNewsPercent
        ]
    }

    const drawBuzzGraph = (arrB, sectorList, response) => {
        setBuzzGraph(() => {
            return {
                title: {
                    text: "Trending Sectors by Social Buzz"
                },
                chart: {
                    type: "bar",
                    borderColor: "#c0c0c0",
                    borderWidth: 1.25,
                    borderRadius: 5,
                    height: 550
                },
                lang: {
                    thousandsSep: ","
                },
                colors: ["#43a84a", "#f5d561"],
                legend: {
                    enabled: false,
                    title: {
                        text: "",
                        style: {
                            fontStyle: "italic"
                        }
                    }
                },
                plotOptions: {
                    series: {
                        stacking: "normal",
                        cursor: "pointer",
                        events: {
                            click: function (event) {
                                getSingleBuzzGraphView(
                                    event.point,
                                    sectorList,
                                    arrB,
                                    response
                                )
                            }
                        }
                    }
                },
                credits: {
                    text: "finsoftai.com",
                    href: 'javascript:window.open("http://www.finsoftai.com/", "_blank")',
                    style: {
                        fontSize: 14,
                        textDecoration: "underline",
                        color: "#0248b3"
                    },
                    position: {
                        align: "right",
                        x: -10
                    }
                },
                stockTools: {
                    gui: {
                        enabled: false
                    }
                },
                xAxis: [
                    {
                        title: {
                            text: "Sectors",
                            style: {
                                fontWeight: "bold",
                                fontSize: "17px",
                                fontFamily: "Poppins"
                            }
                        },
                        categories: sectorList,
                        labels: {
                            style: {
                                fontFamily: "Poppins",
                                fontWeight: "bold",
                                color: "#A8A8A8"
                            }
                        }
                    }
                ],
                yAxis: [
                    {
                        title: {
                            text: "Average Social Buzz",
                            style: {
                                fontWeight: "bold",
                                fontSize: "17px",
                                fontFamily: "Poppins"
                            }
                        }
                    }
                ],
                exporting: {
                    buttons: {
                        contextButton: {
                            menuItems: ["viewFullscreen"]
                        }
                    }
                },
                tooltip: {
                    useHTML: true,
                    headerFormat:
                        '<div style="text-align:center;">  {point.x:,.0f} </div>',
                    thousandsSep: ",",
                    pointFormat:
                        '<div style="text-align:center;"><b>Buzz volume: {point.y:,.1f}</b><p>Social Media:{point.st:,.2f} | News:{point.news:,.2f} </p></div>'
                },
                series: arrB
            }
        })
    }

    const drawSentimentGraph = (arrB, sectorList, response) => {
        setSentiGraph(() => {
            return {
                title: {
                    text: "Trending Sectors by Sentiment"
                },
                chart: {
                    type: "bar",
                    borderColor: "#c0c0c0",
                    borderWidth: 1.25,
                    borderRadius: 5,
                    height: 550
                },
                colors: ["#43a84a", "#f5d561"],
                legend: {
                    enabled: false
                },
                plotOptions: {
                    series: {
                        stacking: "normal",
                        cursor: "pointer",
                        events: {
                            click: function (event) {
                                getSingleSentimentGraphView(
                                    event.point,
                                    sectorList,
                                    arrB,
                                    response
                                )
                            }
                        }
                    }
                },
                credits: {
                    text: "finsoftai.com",
                    href: 'javascript:window.open("http://www.finsoftai.com/", "_blank")',
                    style: {
                        fontSize: 14,
                        textDecoration: "underline",
                        color: "#0248b3"
                    },
                    position: {
                        align: "right",
                        x: -10
                    }
                },
                stockTools: {
                    gui: {
                        enabled: false
                    }
                },
                xAxis: [
                    {
                        title: {
                            text: "Sectors",
                            style: {
                                fontWeight: "bold",
                                fontSize: "17px",
                                fontFamily: "Poppins"
                            }
                        },
                        categories: sectorList,
                        labels: {
                            style: {
                                fontFamily: "Poppins",
                                fontWeight: "bold",
                                color: "#A8A8A8"
                            }
                        }
                    }
                ],
                // legend:{enabled:false},
                yAxis: [
                    {
                        title: {
                            text: "Average Social Sentiment Score",
                            style: {
                                fontWeight: "bold",
                                fontSize: "15px",
                                fontFamily: "Poppins"
                            }
                        }
                    }
                ],
                exporting: {
                    buttons: {
                        contextButton: {
                            menuItems: ["viewFullscreen"]
                        }
                    }
                },
                tooltip: {
                    useHTML: true,
                    headerFormat:
                        '<div style="text-align:center;">  {point.x:,.0f} </div>',
                    thousandsSep: ",",
                    pointFormat:
                        '<div style="text-align:center;"><b>Social Sentiment: {point.y:,.3f}</b><p>Social Media:{point.st:,.3f} | News:{point.news:,.3f} </p></div>'
                    // valueDecimal:1,
                    // valueDecimal:1,
                },
                series: arrB
            }
        })
    }

    const drawBuzzChangeGraph = (arrBTWO, sectorList, response) => {
        setBuzzGraphChange(() => {
            return {
                title: {
                    text: "Trending Sectors by Change in Social Buzz"
                },
                chart: {
                    type: "bar",
                    borderColor: "#c0c0c0",
                    borderWidth: 1.25,
                    borderRadius: 5,
                    styledmode: "on",
                    height: 550
                },
                plotOptions: {
                    series: {
                        stacking: "normal",
                        cursor: "pointer",
                        events: {
                            click: function (event) {
                                getSingleBuzzChangeGraphView(
                                    event.point,
                                    sectorList,
                                    arrBTWO,
                                    response
                                )
                            }
                        }
                    }
                },
                legend: {
                    enabled: false
                },
                colors: ["#43a84a", "#f5d561"],
                lang: {
                    thousandsSep: ","
                },
                credits: {
                    text: "finsoftai.com",
                    href: 'javascript:window.open("http://www.finsoftai.com/", "_blank")',
                    style: {
                        fontSize: 14,
                        textDecoration: "underline",
                        color: "#0248b3"
                    },
                    position: {
                        align: "right",
                        x: -10
                    }
                },
                stockTools: {
                    gui: {
                        enabled: false
                    }
                },
                xAxis: [
                    {
                        title: {
                            text: "Sectors",
                            style: {
                                fontWeight: "bold",
                                fontSize: "17px",
                                fontFamily: "Poppins"
                            }
                        },
                        categories: sectorList,

                        labels: {
                            style: {
                                fontFamily: "Poppins",
                                fontWeight: "bold",
                                color: "#A8A8A8"
                            }
                        }
                    }
                ],
                // legend:{enabled:false},
                yAxis: [
                    {
                        title: {
                            text: "Average Change in Social Buzz",
                            style: {
                                fontWeight: "bold",
                                fontSize: "15px",
                                fontFamily: "Poppins"
                            }
                        },
                        labels: {
                            formatter: function () {
                                return this.value + "%"
                            }
                        }
                    }
                ],
                exporting: {
                    buttons: {
                        contextButton: {
                            menuItems: ["viewFullscreen"]
                        }
                    }
                },

                tooltip: {
                    useHTML: true,
                    headerFormat:
                        '<div style="text-align:center;">  {point.x:,.0f} </div>',
                    thousandsSep: ",",
                    pointFormat:
                        '<div style="text-align:center;"><b>Change in Buzz volume : {point.y:,.2f}%</b><p>Social Media : {point.stPercent:,.2f}% | News : {point.newsPercent:,.2f}%</p></div>', // <br></br>Social Media value: {point.stAbs:,.2f} | News value: {point.newsAbs:,.2f}
                    // valueDecimal:1,
                    padding: 4
                },
                series: arrBTWO
            }
        })
    }

    const drawSentimentChangeGraph = (arrB, sectorList, response) => {
        setSentiGraphChange(() => {
            return {
                title: {
                    text: "Trending Sectors by Change in Sentiment"
                },
                chart: {
                    type: "bar",
                    borderColor: "#c0c0c0",
                    borderWidth: 1.25,
                    borderRadius: 5,
                    styledmode: "on",
                    height: 550
                },
                plotOptions: {
                    series: {
                        stacking: "normal",
                        cursor: "pointer",
                        events: {
                            click: function (event) {
                                getSingleSentimentChangeGraphView(
                                    event.point,
                                    sectorList,
                                    arrB,
                                    response
                                )
                            }
                        }
                    }
                },
                legend: {
                    enabled: false
                },
                lang: {
                    thousandsSep: ","
                },
                credits: {
                    text: "finsoftai.com",
                    href: 'javascript:window.open("http://www.finsoftai.com/", "_blank")',
                    style: {
                        fontSize: 14,
                        textDecoration: "underline",
                        color: "#0248b3"
                    },
                    position: {
                        align: "right",
                        x: -10
                    }
                },
                stockTools: {
                    gui: {
                        enabled: false
                    }
                },
                xAxis: [
                    {
                        title: {
                            text: "Sectors",
                            style: {
                                fontWeight: "bold",
                                fontSize: "17px",
                                fontFamily: "Poppins"
                            }
                        },
                        categories: sectorList,

                        labels: {
                            style: {
                                fontFamily: "Poppins",
                                fontWeight: "bold",
                                color: "#A8A8A8"
                            }
                        }
                    }
                ],
                // legend:{enabled:false},
                yAxis: [
                    {
                        title: {
                            text: "Average Change in Social Sentiment Score",
                            style: {
                                fontWeight: "bold",
                                fontSize: "15px",
                                fontFamily: "Poppins"
                            }
                        },
                        labels: {
                            formatter: function () {
                                return this.value + "%"
                            }
                        }
                    }
                ],
                exporting: {
                    buttons: {
                        contextButton: {
                            menuItems: ["viewFullscreen"],
                            className: "step-four"
                        }
                    }
                },
                tooltip: {
                    useHTML: true,
                    headerFormat:
                        '<div style="text-align:center;">  {point.x:,.0f} </div>',
                    thousandsSep: ",",
                    pointFormat:
                        '<div style="text-align:center;"><b>Change in Social Sentiment : {point.y:,.2f}%</b><p>Social Media : {point.stPercent:,.2f}% | News : {point.newsPercent:,.2f}%</p></div>' //<br></br>Social Media value: {point.stAbs:,.3f} | News value: {point.newsAbs:,.3f}
                    // valueDecimal:1,
                    // valueDecimal:1,
                },
                series: arrB
            }
        })
    }

    const getSeries = (
        news_change_percent,
        st_change_percent,
        news_change,
        st_change
    ) => {
        let data = {}

        if (news_change && st_change) {
            const calculateABSValue = (change, percentageChange) => {
                const originalValue = change / (percentageChange / 100)
                return originalValue
            }

            data = STOCKS_WITH_SECTORS.map((sectorObj) => {
                let newsPercent = 0
                let stPercent = 0
                let newsAbs = 0
                let stAbs = 0
                let percentStSum = 0
                let percentNewsSum = 0
                let absStSum = 0
                let absNewsSum = 0

                sectorObj.tickers.forEach((ticker) => {
                    stPercent = st_change_percent[ticker] || 0
                    stAbs =
                        calculateABSValue(
                            st_change[ticker],
                            st_change_percent[ticker]
                        ) || 0

                    percentStSum += stAbs * stPercent
                    absStSum += stAbs

                    newsPercent = news_change_percent[ticker] || 0
                    newsAbs =
                        calculateABSValue(
                            news_change[ticker],
                            news_change_percent[ticker]
                        ) || 0

                    percentNewsSum += newsAbs * newsPercent
                    absNewsSum += newsAbs
                })

                let y = 0
                if (percentNewsSum && percentStSum) {
                    y = parseFloat(
                        0.5 * (percentNewsSum / absNewsSum) +
                            0.5 * (percentStSum / absStSum)
                    )
                } else if (percentNewsSum) {
                    y = percentNewsSum / absNewsSum
                } else if (percentStSum) {
                    y = percentStSum / absStSum
                }

                return {
                    name: sectorObj.sector,
                    y,
                    newsPercent: percentNewsSum / absNewsSum,
                    stPercent: percentStSum / absStSum,
                    newsAbs: absNewsSum,
                    stAbs: absStSum
                }
            })
        } else {
            // Case when only Absolute values
            data = STOCKS_WITH_SECTORS.map((sectorObj) => {
                let newsSum = 0
                let stSum = 0

                sectorObj.tickers.forEach((ticker) => {
                    newsSum +=
                        (news_change_percent[ticker] || 0) /
                        sectorObj.tickers.length
                    stSum +=
                        (st_change_percent[ticker] || 0) /
                        sectorObj.tickers.length
                })

                let y = 0
                if (stSum && newsSum) {
                    y = parseFloat(0.5 * stSum + 0.5 * newsSum)
                } else if (stSum) {
                    y = stSum
                } else if (newsSum) {
                    y = newsSum
                }

                return {
                    name: sectorObj.sector,
                    y,
                    st: stSum,
                    news: newsSum
                }
            })
        }

        return {
            name: "Score",
            type: "bar",
            data: data.sort((a, b) => b.y - a.y),
            pointStart: 0,
            color: "#43a84a",
            negativeColor: "#e64736"
        }
    }

    const getSeriesTwo = (data1, data2) => {
        const data = STOCKS_WITH_SECTORS.map((sectorObj) => {
            let stSum = 0
            let newsSum = 0

            sectorObj.tickers.forEach((ticker) => {
                const stValue = data2[ticker] || 0
                const newsValue = data1[ticker] || 0

                stSum += stValue
                newsSum += newsValue
            })

            const st = stSum * GRAPH_NORMALIZE_FACTOR
            const news = newsSum

            return {
                name: sectorObj.sector,
                y: parseFloat(news + st),
                st,
                news
            }
        }).sort((a, b) => b.y - a.y) // Sort descending by value

        return {
            name: "Score",
            type: "bar",
            data: data,
            pointStart: 0,
            color: "#43a84a",
            negativeColor: "#e64736"
        }
    }

    const processAndDrawBuzzGraph = useCallback(
        (response) => {
            const temp = findSeries(graphViews.buzz, response, 0, "buzz")

            const arr1 = getSeriesTwo(temp["news"], temp["st"])
            const currentSectorList = arr1.data.map((sector) => sector.name)

            drawBuzzGraph(arr1, currentSectorList, response)
        },
        [graphViews.buzz, sectors]
    )

    const processAndDrawSentimentGraph = useCallback(
        (response) => {
            const temp = findSeries(
                graphViews.sentiment,
                response,
                0,
                "sentiment"
            )

            const arr1 = getSeries(temp["news"], temp["st"])
            const currentSectorList = arr1.data.map((sector) => sector.name)

            drawSentimentGraph(arr1, currentSectorList, response)
        },
        [graphViews.sentiment, sectors]
    )

    // Function to process data and draw the buzz change graph
    const processAndDrawBuzzChangeGraph = useCallback(
        (response) => {
            const data = findSeries(
                graphViews.changeBuzz,
                response,
                1,
                "buzz_change"
            )

            const arr1 = getSeries(
                data["news"],
                data["st"],
                data["newsAbs"],
                data["stAbs"]
            )

            const currentSectorList = arr1.data.map((sector) => sector.name)

            drawBuzzChangeGraph(arr1, currentSectorList, response)
        },
        [graphViews.changeBuzz, sectors]
    )

    // Function to process data and draw the sentiment change graph
    const processAndDrawSentimentChangeGraph = useCallback(
        (response) => {
            const data = findSeries(graphViews.changeSentiment, response, 1)

            const arr1 = getSeries(
                data["news"],
                data["st"],
                data["newsAbs"],
                data["stAbs"]
            )

            const currentSectorList = arr1.data.map((sector) => sector.name)

            drawSentimentChangeGraph(arr1, currentSectorList, response)
        },
        [graphViews.changeSentiment, sectors]
    )

    const getGraphs = async () => {
        if (!isLoading) {
            setIsLoading(true)

            const date = formatDayjs(graphDateTime)

            const payload = {
                date
            }

            let apiResponse = {}

            const buzzGraphData = await fetchGraphData(
                payload,
                "social_buzz",
                "buzz"
            )
            processAndDrawBuzzGraph(buzzGraphData)
            apiResponse = { ...apiResponse, buzz: buzzGraphData }

            const sentimentGraphData = await fetchGraphData(
                payload,
                "sentiment",
                "sentiment"
            )
            processAndDrawSentimentGraph(sentimentGraphData)
            apiResponse = { ...apiResponse, sentiment: sentimentGraphData }

            const buzzChangeGraphData = await fetchGraphData(
                payload,
                "social_buzz_change",
                "changeBuzz"
            )
            processAndDrawBuzzChangeGraph(buzzChangeGraphData)
            apiResponse = { ...apiResponse, changeBuzz: buzzChangeGraphData }

            const sentimentChangeGraphData = await fetchGraphData(
                payload,
                "sentiment_change",
                "changeSentiment"
            )
            processAndDrawSentimentChangeGraph(sentimentChangeGraphData)
            apiResponse = {
                ...apiResponse,
                changeSentiment: sentimentChangeGraphData
            }
        }

        setIsLoading(false)
    }

    useGraphUpdate(graphData, graphViews, "buzz", processAndDrawBuzzGraph)
    useGraphUpdate(
        graphData,
        graphViews,
        "sentiment",
        processAndDrawSentimentGraph
    )
    useGraphUpdate(
        graphData,
        graphViews,
        "changeBuzz",
        processAndDrawBuzzChangeGraph
    )

    useGraphUpdate(
        graphData,
        graphViews,
        "changeSentiment",
        processAndDrawSentimentChangeGraph
    )

    useEffect(() => {
        getGraphs()
    }, [graphDateTime])

    return (
        <>
            <div className='flex items-center justify-between my-2 step-one'>
                <div className='flex flex-col gap-2'>
                    <div className='flex gap-1 flex-col'>
                        <h3
                            className='transition-modal-description'
                            style={{ textAlign: "left" }}>
                            Trending Dashboards
                        </h3>
                        <div className='flex gap-2 items-center'>
                            <p className='m-0'>* As on Morning (Pre-Market):</p>
                            <Button
                                variant='text'
                                className='text-gray-500 text-lg border'
                                size='medium'
                                onClick={() => {
                                    setDateChangeModal(() => {
                                        return true
                                    })
                                }}>
                                {displayDate}
                            </Button>
                        </div>
                        <Modal
                            aria-labelledby='transition-modal-title'
                            aria-describedby='transition-modal-description'
                            className={classes.modal}
                            open={dateChangeModal}
                            onClose={() => {
                                setDateChangeModal(() => {
                                    return false
                                })
                            }}
                            closeAfterTransition
                            BackdropComponent={Backdrop}
                            BackdropProps={{
                                timeout: 500
                            }}
                            style={{
                                left: "35%",
                                marginTop: "3%"
                            }}>
                            <div
                                classes='container-fluid '
                                className={classes.paper}
                                style={{ maxWidth: "50%" }}>
                                <div className='row'>
                                    <h2
                                        className='col-10 transition-modal-description'
                                        style={{
                                            textAlign: "left"
                                        }}>
                                        Set ET Date & Time
                                    </h2>
                                    <div className='row'>
                                        <LocalizationProvider
                                            dateAdapter={AdapterDayjs}>
                                            <DemoContainer
                                                components={["DateTimePicker"]}>
                                                <DateTimePicker
                                                    timezone='America/New_York'
                                                    value={graphDateTime}
                                                    onChange={(newValue) => {
                                                        checkFutureData(
                                                            newValue
                                                        )
                                                    }}
                                                    views={[
                                                        "year",
                                                        "month",
                                                        "day"
                                                    ]}
                                                />
                                            </DemoContainer>
                                        </LocalizationProvider>
                                    </div>
                                    <div className='row justify-content-center mt-3'>
                                        <Button
                                            className='col-md-4 confirm-btn'
                                            style={{
                                                backgroundColor: "#0d6efd",
                                                color: "white"
                                            }}
                                            size='small'
                                            variant='contained'
                                            onClick={handleGetGraphWithDate}>
                                            Confirm
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </Modal>
                    </div>
                </div>
                <div className='flex items-center self-end gap-6'>
                    <a
                        href='https://chatgpt.com/g/g-67a40bd4bab48191ad62319ec3b0bfad-ssi-social-sentiment-insight-ai-assistant'
                        target='_blank'
                        rel='noreferrer'>
                        <Button
                            variant='contained'
                            style={{
                                backgroundColor: "rgb(75, 143, 205)",
                                color: "white",
                                fontSize: 16,
                                textTransform: "none" // Prevent text transformation
                            }}
                            size='medium'
                            disableElevation // Disable button elevation
                        >
                            Ask SSi Bot
                        </Button>
                    </a>
                    <div>
                        <IconButton
                            variant='outlined'
                            onClick={() => {
                                setGraphToggle(false)
                            }}>
                            <CloseIcon />
                        </IconButton>
                    </div>
                </div>
            </div>
            <div className='row items-center justify-center font-poppins text-lg font-normal'>
                <div className='col-md-6 mt-2'>
                    <FullScreen handle={screen1} onChange={getGraphs}>
                        <Card
                            style={
                                screen1.active
                                    ? {
                                          borderRadius: 20,
                                          backgroundColor: "#f4f4f4",
                                          height: "100%",
                                          overflowY: "scroll"
                                      }
                                    : {
                                          borderRadius: 20,
                                          backgroundColor:
                                              "rgba(229, 229, 229, 0.42)"
                                      }
                            }>
                            <CardContent className='step-five'>
                                {topBar(1, "Trending Sectors by Social Buzz")}
                                <HighchartsReact
                                    highcharts={Highcharts}
                                    options={buzzGraph}
                                />
                            </CardContent>
                        </Card>
                    </FullScreen>
                </div>
                <div className='col-md-6 mt-2'>
                    <FullScreen handle={screen2} onChange={getGraphs}>
                        <Card
                            style={
                                screen2.active
                                    ? {
                                          borderRadius: 20,
                                          backgroundColor: "#f4f4f4",
                                          height: "100%",
                                          overflowY: "scroll"
                                      }
                                    : {
                                          borderRadius: 20,
                                          backgroundColor:
                                              "rgba(229, 229, 229, 0.42)"
                                      }
                            }>
                            <CardContent>
                                {topBar(2, "Trending Sectors by Sentiment")}

                                <HighchartsReact
                                    highcharts={Highcharts}
                                    options={sentiGraph}
                                />
                            </CardContent>
                        </Card>
                    </FullScreen>
                </div>
                <div className='col-md-6 mt-2'>
                    <FullScreen handle={screen3} onChange={getGraphs}>
                        <Card
                            style={
                                screen3.active
                                    ? {
                                          borderRadius: 20,
                                          backgroundColor: "#f4f4f4",
                                          height: "100%",
                                          overflowY: "scroll"
                                      }
                                    : {
                                          borderRadius: 20,
                                          backgroundColor:
                                              "rgba(229, 229, 229, 0.42)"
                                      }
                            }>
                            <CardContent>
                                {topBar(
                                    3,
                                    "Trending Sectors by Change in Social Buzz"
                                )}
                                <HighchartsReact
                                    highcharts={Highcharts}
                                    options={buzzGraphChange}
                                />
                            </CardContent>
                        </Card>
                    </FullScreen>
                </div>
                <div className='col-md-6 mt-2 step-two'>
                    <FullScreen handle={screen4} onChange={getGraphs}>
                        <Card
                            style={
                                screen4.active
                                    ? {
                                          borderRadius: 20,
                                          backgroundColor: "#f4f4f4",
                                          height: "100%",
                                          overflowY: "scroll"
                                      }
                                    : {
                                          borderRadius: 20,
                                          backgroundColor:
                                              "rgba(229, 229, 229, 0.42)"
                                      }
                            }>
                            <CardContent>
                                <div className='step-three'>
                                    {topBar(
                                        4,
                                        "Trending Sectors by Change in Sentiment"
                                    )}
                                </div>
                                <HighchartsReact
                                    highcharts={Highcharts}
                                    options={sentiGraphChange}
                                />
                            </CardContent>
                        </Card>
                    </FullScreen>
                </div>
            </div>
        </>
    )
}

export default SectorViewDashboard
