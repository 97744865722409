import "./custom.css";

import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Grid, IconButton, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { RemoveCircleOutline as RemoveIcon } from "@material-ui/icons";
// import ToggleButton from "react-bootstrap/ToggleButton";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import PropTypes from "prop-types";
import React from "react";
// import ToggleButtonGroup from "react-bootstrap/ToggleButtonGroup";
import { Draggable, Container as DraggableContainer } from "react-smooth-dnd";

import Context from "./context";
import Field from "./Field";
import Operator from "./Operator";
import Value from "./Value";



const removeIconStyles = (t) => ({
    removeButton: {
        marginRight: t.spacing(-1),
        marginTop: t.spacing(0.75),
    },
    removeIcon: {
        fill: "rgba(195,210,225,255)",
    },
});

const useRuleStyles = makeStyles((t) => {
    return {
        ...removeIconStyles(t),
        container: {
            "& > div": {
                marginBottom: t.spacing(0.5),
                marginTop: t.spacing(2),
                marginLeft: t.spacing(2.5),
                paddingRight: 10,
            },
            "cursor": "move",
        },
        field: {
            "& > div": {
                marginLeft: t.spacing(4.5),
            },
        },
        valueGridItem: {
            flex: "auto",
        },
    };
});

const Rule = (props) => {
    const classes = useRuleStyles();
    const context = React.useContext(Context);

    const { id, level, position, rule } = props;
    const { combinator, field, operator, rules, value } = rule;

    const { dispatch } = context;

    const testId = `${level}-${position}`;
    // console.log(combinator, rule, "comc");
    return combinator ? (
        <RuleGroup combinator={combinator} id={id} level={level + 1} rules={rules} />
    ) : (
        <Grid container className={classes.container} data-testid={`rule-${testId}`}>
            {/* <Grid item>
                <IconButton
                    className={classes.removeButton}
                    data-testid={`rule-${testId}-remove`}
                    size="small"
                    onClick={() => {
                        dispatch({ type: "remove-node", id });
                    }}
                >
                    <RemoveIcon className={classes.removeIcon} />
                </IconButton>
            </Grid> */}
            <Grid item>
                <Field field={field} id={id} testId={testId} />
            </Grid>
            <Grid item>
                <Operator field={field} id={id} operator={operator} testId={testId} />
            </Grid>
            <Grid item className={classes.valueGridItem}>
                <Value field={field} id={id} operator={operator} testId={testId} value={value} />
            </Grid>
            <Grid item>
                <Button
                    // variant="outlined"
                    className="m-auto mb-2 p-2"
                    data-testid={`rule-${testId}-remove`}
                    size="small"
                    style={{ fontWeight: "700", backgroundColor: "white", marginBottom:4 }}
                    onClick={() => {
                        dispatch({ type: "remove-node", id });
                    }}
                >
                    <FontAwesomeIcon
                        icon={faTimes}
                        style={{ color: "gray", fontSize:20 }}
                    />
                </Button>
            </Grid>
            {/* <Grid item>
                <Button
                    variant="primary"
                    disabled
                    className="m-auto"
                    // data-testid={`rule-${testId}-remove`}
                    size="small"
                    style={{ fontWeight: "900", backgroundColor: "white", borderRadius: 5, color: "darkGray" }}
                >
                    { mainCombinator}
                </Button> */}
            {/* </Grid> */}
        </Grid>
    );
};

Rule.propTypes = {
    id: PropTypes.number.isRequired,
    level: PropTypes.number.isRequired,
    position: PropTypes.number.isRequired,
    rule: PropTypes.object.isRequired,
};

const useRuleGroupStyles = makeStyles((t) => ({
    actionButton: {
        "& svg": {
            marginRight: t.spacing(0.5),
            marginTop: t.spacing(0.25),
        },
        "textTransform": "none",
    },
    combinator: {
        height: 36,
        padding: t.spacing(0, 1.5),
    },
    selectToggle: {
        backgroundColor: "#024ea2 !important",
        color: "white !important",
    },
    group: {
        borderLeft: (props) => (props.level > 0 ? `2px solid ${t.palette.divider}` : "none"),
        paddingLeft: t.spacing(1.5),
        marginBottom: t.spacing(0.5),
        marginTop: (props) => (props.level > 0 ? t.spacing(0.5) : "none"),
    },
    ...removeIconStyles(t),
}));

const RuleGroup = (props) => {
    const classes = useRuleGroupStyles(props);
    const context = React.useContext(Context);

    const { combinator, combinators, id, level, rules } = props;
    const testId = `group-${level}`;

    const { dispatch, maxLevels } = context;
    return level <= maxLevels ? (
        <Grid
            container
            className={classes.group}
            data-testid={testId}
            direction="column"
            spacing={1}
            style={
                level % 2
                    ? {
                        margin: "auto",
                        width: "100%",
                        backgroundColor: "rgba(244,244,244,255)",
                        borderRadius: 10,
                        paddingRight: 12,
                        paddingTop: 10,
                        marginTop: 5,
                        marginBottom: 5,
                    }
                    : {
                        margin: "auto",
                        width: "100%",
                        backgroundColor: "white",
                        borderRadius: 10,
                        paddingRight: 12,
                        paddingTop: 10,
                        marginTop: 5,
                        marginBottom: 5,
                    }
            }
        >
            <Grid item>
                <Grid container spacing={2} style={{ width: "auto" }}>
                    <Grid item>
                        <IconButton
                            className={classes.removeButton}
                            data-testid={`${testId}-remove`}
                            disabled={level === 0}
                            size="small"
                            onClick={() => {
                                dispatch({ type: "remove-node", id });
                            }}
                        >
                            <RemoveIcon className={level > 0 ? classes.removeIcon : null} />
                        </IconButton>
                    </Grid>
                    <Grid item>
                        <ToggleButtonGroup
                            exclusive
                            size="small"
                            value={combinator}
                            variant="primary"
                            onChange={(event, value) => {
                                if (value) {
                                    dispatch({ type: "set-combinator", id, value });
                                }
                            }}
                            style={{ backgroundColor: "white" }}
                        >
                            {combinators.map((item) => (
                                <ToggleButton
                                    key={item.value}
                                    data-testid={`${testId}-combinator-${item.value}`}
                                    classes={{ selected: classes.selectToggle }}
                                    className={classes.combinator}
                                    value={item.value}
                                >
                                    <Typography variant="body2">{item.label}</Typography>
                                </ToggleButton>
                            ))}
                        </ToggleButtonGroup>
                    </Grid>
                    <Grid item>
                        <div style={{ height: "100%", borderRight: "solid 1px lightGray" }}></div>
                    </Grid>
                    <Grid item>
                        <Button
                            className={classes.actionButton}
                            // color="primary"
                            variant="outlined"
                            style={{ color: "gray", backgroundColor: "white" }}
                            data-testid={`${testId}-add-rule`}
                            onClick={() => {
                                dispatch({ type: "add-rule", id });
                            }}
                        >
                            {/* <AddIcon /> */}+ Rule
                        </Button>
                    </Grid>
                    {level < maxLevels && (
                        <Grid item>
                            <Button
                                className={classes.actionButton}
                                // color="primary"
                                variant="outlined"
                                style={{ color: "gray", backgroundColor: "white" }}
                                data-testid={`${testId}-add-group`}
                                onClick={() => {
                                    dispatch({ type: "add-group", id });
                                }}
                            >
                                {/* <AddIcon /> */}+ Group
                            </Button>
                        </Grid>
                    )}
                </Grid>
            </Grid>
            {rules?.length > 0 && (
                <Grid item>
                    <DraggableContainer
                        animationDuration={500}
                        onDrop={({ addedIndex, removedIndex }) => {
                            dispatch({ type: "move-rule", addedIndex, id, removedIndex });
                        }}
                    >
                        {rules.map((rule, position) => (
                            <Draggable key={rule.id}>
                                <Rule id={rule.id} level={level} position={position} rule={rule} />
                            </Draggable>
                        ))}
                    </DraggableContainer>
                </Grid>
            )}
        </Grid>
    ) : (
        <span />
    );
};

RuleGroup.defaultProps = {
    combinator: "and",
    combinators: [
        { label: "AND", value: "and" },
        { label: "OR", value: "or" },
    ],
    rules: [],
};

RuleGroup.propTypes = {
    combinator: PropTypes.string,
    combinators: PropTypes.array,
    id: PropTypes.number.isRequired,
    level: PropTypes.number.isRequired,
    rules: PropTypes.array,
};

export default RuleGroup;
