import {
    Card,
    CardHeader,
    CardContent,
    Tooltip,
    IconButton,
    Menu,
    ListItemIcon
} from "@material-ui/core"
import GetAppIcon from "@material-ui/icons/GetApp"
import FilterListIcon from "@material-ui/icons/FilterList"
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward"
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward"
import SortMenuItem from "./SortMenuItem"
import { useState } from "react"

const SORT_ITEMS = [
    { index: 0, sortKey: "Relevance" },
    { index: 1, sortKey: "Date" },
    { index: 2, sortKey: "Sentiment" }
]

const Table = ({
    sourceName,
    sourceLogo,
    anchorElSource,
    handleClosePopup,
    handleMenuSource,
    selectedSourceIndex,
    setSortSource,
    getSortData,
    downloadSource,
    sourceCount,
    getSource
}) => {
    const [ascending, setAscending] = useState(true)

    return (
        <div className='col-md-6  mb-5'>
            <Card
                style={{
                    width: "100%",
                    boxShadow: "1px 1px 7px 1px lightgrey",
                    border: "1px solid #919191",
                    borderRadius: 10,
                    backgroundColor: "#f4f4f4",
                    height: "100%",
                    maxHeight: "90vh"
                }}>
                <CardHeader
                    avatar={
                        sourceLogo ? (
                            <img src={sourceLogo} alt='' height={60}></img>
                        ) : (
                            <></>
                        )
                    }
                    title={
                        <div className='d-flex'>
                            <h3
                                style={{
                                    fontWeight: 600,
                                    marginTop: 4,
                                    color: "rgb(68,68,68)",
                                    fontFamily: "Verdana, Arial"
                                }}>
                                {sourceName}
                            </h3>
                            <Tooltip
                                arrow
                                title={<h6>Sort Documents</h6>}
                                placement='top'>
                                <div
                                    style={{
                                        marginLeft: "auto",
                                        marginRight: "0",
                                        height: 50,
                                        marginTop: -4
                                    }}>
                                    <IconButton
                                        aria-controls='simple-menu'
                                        aria-haspopup='true'
                                        color='inherit'
                                        aria-label='Person'
                                        onClick={handleMenuSource}>
                                        <FilterListIcon style={{}} />
                                    </IconButton>
                                    <Menu
                                        id='simple-menu'
                                        keepMounted
                                        anchorEl={anchorElSource}
                                        anchorOrigin={{
                                            vertical: "top",
                                            horizontal: "right"
                                        }}
                                        open={Boolean(anchorElSource)}
                                        onClose={handleClosePopup}>
                                        {SORT_ITEMS.map((item) => {
                                            const { index, sortKey } = item

                                            return (
                                                <SortMenuItem
                                                    index={index}
                                                    sortKey={sortKey}
                                                    selectedSourceIndex={
                                                        selectedSourceIndex
                                                    }
                                                    handleClosePopup={
                                                        handleClosePopup
                                                    }
                                                    sourceName={sourceName}
                                                    getSortData={getSortData}
                                                    setSortSource={
                                                        setSortSource
                                                    }
                                                    setAscending={setAscending}
                                                    ascending={ascending}
                                                />
                                            )
                                        })}
                                    </Menu>
                                </div>
                            </Tooltip>
                            <Tooltip
                                arrow
                                title={
                                    <h6>
                                        Click to download complete document{" "}
                                    </h6>
                                }
                                placement='top'>
                                <IconButton
                                    className='tenth-step'
                                    color='inherit'
                                    onClick={downloadSource}
                                    style={{
                                        marginRight: "1%",
                                        height: 50,
                                        marginTop: -4
                                    }}>
                                    {" "}
                                    <GetAppIcon />
                                </IconButton>
                            </Tooltip>
                            <Tooltip
                                arrow
                                title={
                                    <h6>
                                        P-Postive, Nu-Neutral, N-Negative and
                                        T-Total{" "}
                                    </h6>
                                }
                                placement='top'>
                                <span
                                    style={{
                                        marginRight: "3%",
                                        fontSize: 22,
                                        fontWeight: 600,
                                        marginTop: 2,
                                        color: "rgb(68,68,68)",
                                        fontFamily: "Verdana, Arial"
                                    }}>
                                    P: {sourceCount.positive} Nu:{" "}
                                    {sourceCount.neutral} N:{" "}
                                    {sourceCount.negative} T:{" "}
                                    {sourceCount.total}
                                </span>
                            </Tooltip>
                        </div>
                    }
                    style={{ marginTop: 12, marginLeft: 25 }}
                />
                <CardContent
                    style={{
                        overflowY: "scroll",
                        height: "100%",
                        overflowX: "hidden",
                        marginRight: "20px",
                        marginLeft: "20px",
                        paddingBottom: "15%"
                    }}>
                    <div className='flex'>
                        <p>
                            Sorted by {SORT_ITEMS[selectedSourceIndex].sortKey}
                        </p>
                        <ListItemIcon>
                            {ascending ? (
                                <ArrowUpwardIcon />
                            ) : (
                                <ArrowDownwardIcon />
                            )}
                        </ListItemIcon>
                    </div>
                    {getSource() ? (
                        getSource()
                    ) : (
                        <big style={{ color: "#f6f6f6", fontWeight: "bold" }}>
                            Run a Query to Load Data
                        </big>
                    )}
                    <br></br>
                </CardContent>
            </Card>
        </div>
    )
}

export default Table
