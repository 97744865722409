import React from 'react';
import Background from './static/research-login-background.png'
import logo from "./static/ssi-research-logo.png"


const ErrorPage = () => {
  return (

    <div className='container-fluid'>
      <div className='row' style={{ height: "100vh" }}>
        <div style={{
          backgroundImage: `url(${Background})`,
          backgroundRepeat: "no-repeat",
          backgroundColor: (t) =>
            t.palette.mode === "light"
              ? t.palette.grey[50]
              : t.palette.grey[900],
          backgroundSize: 'cover',
          backgroundPosition: "center",
          // backgroundColor: 'blue'
          textAlign: "center"
        }}>
          <h1 className='mt-5'>Error</h1>
          <p>Oops! Something went wrong.</p>
        </div>
      </div>

    </div>
  );
};

export default ErrorPage;
