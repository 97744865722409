import arrayMove from "array-move";
import { dequal } from "dequal";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import Context from "./context";
import operators from "./operators";
import RuleGroup from "./RuleGroup";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { FormControlLabel, Switch } from "@material-ui/core";
// import { makeStyles } from "@material-ui/core/styles";

/**
 * Allows retrieving filters by value, in O(1) time.
 *
 * @param {Array} filters The data descriptions.
 * @returns {Object} Filters map.
 */
export function generateFiltersByValue(filters) {
    const map = {};
    filters.forEach((filter) => {
        const options = filter.options || [];
        options.forEach((option) => {
            const { value } = option;
            if (Object.prototype.hasOwnProperty.call(map, value)) {
                throw new Error(`Duplicated filter: ${value}`);
            }
            map[value] = { ...option };
        });
    });
    return map;
}

/**
 * Flattens filters for autocomplete fields.
 *
 * @param {Array} filters The data descriptions.
 * @returns {List} Filters list.
 */
export function generateFlattenedFilters(filters) {
    const list = [];
    filters.forEach((filter) => {
        filter.options.forEach((option) => {
            list.push({
                group: filter.label,
                ...option,
            });
        });
    });
    return list;
}

/**
 * Sorts filters within their own groups.
 *
 * @param {Array} filters The data descriptions.
 * @returns {Array} The sorted filters.
 */
export function sortFilterGroupsByLabel(filters) {
    filters.forEach((filter) => {
        filter.options = filter.options.sort((a, b) => a.label.localeCompare(b.label));
    });
    return filters;
}

/**
 * Allows retrieving operators by type, in O(1) time.
 *
 * @param {Array} operators cf. `operators.js`.
 * @param {Object} customOperators Custom operators to be used, if any.
 * @returns {Object} Operators map.
 */
export function generateOperatorsByType(operators, customOperators) {
    const map = {};
    const types = [...new Set([].concat(...operators.map((operator) => operator.types)))].sort();

    types.forEach((type) => {
        if (!Object.prototype.hasOwnProperty.call(map, type)) {
            map[type] = [];
        }
        operators.forEach((operator) => {
            if (operator.types.includes(type)) {
                map[type].push({
                    label: operator.label,
                    value: operator.value,
                });
            }
        });
    });
    Object.entries(customOperators || {}).forEach(([key, value]) => {
        map[key] = value.options;
    });
    Object.keys(map).forEach((key) => {
        map[key] = map[key].sort((a, b) => a.label.localeCompare(b.label));
    });
    return map;
}

/**
 * Allows retrieving operators by value, in O(1) time.
 *
 * @param {Array} operators cf. `operators.js`.
 * @param {Object} customOperators Custom operators to be used, if any.
 * @returns {Object} Operators map.
 */
export function generateOperatorsByValue(operators, customOperators) {
    const map = {};
    operators.forEach((operator) => {
        const { value } = operator;
        if (Object.prototype.hasOwnProperty.call(map, value)) {
            throw new Error(`Duplicated operator: ${value}`);
        }
        map[value] = { ...operator };
    });
    Object.values(customOperators || {}).forEach((value) => {
        value.options.forEach((option) => {
            if (!Object.prototype.hasOwnProperty.call(map, option.value)) {
                map[option.value] = { types: [] };
            }
            map[option.value] = {
                ...map[option.value],
                label: option.label,
                value: option.value,
            };
            const { types } = map[option.value];
            if (!types.includes(value.type)) {
                types.push(value.type);
            }
        });
    });
    return map;
}

/**
 * Finds a node by ID.
 *
 * @param {Number} id The node ID.
 * @param {Object} node The starting node.
 * @returns {Object} The node with the given ID, or null if not found.
 */
export const findNodeById = (id, node) => {
    if (node.id === id) {
        return node;
    }
    if (node.rules) {
        for (const rule of node.rules) {
            const found = findNodeById(id, rule);
            if (found) {
                return found;
            }
        }
    }
    return null;
};

/**
 * Finds a node's parent node by ID.
 *
 * @param {Number} id The node ID.
 * @param {Object} node The starting node.
 * @param {Object} parent The starting parent.
 * @returns {Object} The searched node's parent.
 */
export const findParentById = (id, node, parent) => {
    if (!parent) {
        parent = node;
    }
    if (node.id === id) {
        return parent;
    }
    if (node.rules) {
        parent = node;
        for (const rule of node.rules) {
            const found = findParentById(id, rule, parent);
            if (found) {
                return found;
            }
        }
    }
    return null;
};

/**
 * Resets a query's node IDs.
 *
 * @param {Object} query A query with rules.
 * @param {string} mode "random" to set random IDs, or anything else to delete existing ones.
 * @returns {Object} The processed query instance.
 */
export function resetNodeIds(query, mode) {
    try {
        const random = mode === "random";
        if (random) {
            query.id = query.id || Math.random();
        } else {
            delete query.id;
        }
        query.rules.map((rule) => {
            if (random) {
                query.id = query.id || Math.random();
            } else {
                delete query.id;
            }

            query.rules.map((rule) => {
                if (random) {
                    rule.id = rule.id || Math.random();
                } else {
                    delete rule.id;
                }
                if (rule.rules) {
                    resetNodeIds(rule, mode);
                }
                return rule;
            });
        });
    } catch (err) {
        console.log("err");
    }

    return query;
}

/**
 * Deep clones a query.
 *
 * @param {Object} query The query to be cloned.
 * @returns {Object} Another instance of the given query.
 */
export function cloneQuery(query) {
    return JSON.parse(JSON.stringify(query));
}

/**
 * Formats a query by deleting IDs from all nodes.
 *
 * @param {Object} query The query to be formatted.
 * @returns {Object} Another instance of the given query, without IDs.
 */
export function formatQuery(query) {
    query = cloneQuery(query);
    query = resetNodeIds(query);
    return query;
}

/**
 * Verifies if a group is valid, i.e. all rules and nested groups are filled.
 *
 * @param {Object} group The group to validate.
 * @returns {Boolean} True if valid, false otherwise.
 */
export function isGroupValid(group) {
    try {
        if (Object.getOwnPropertyNames(group).length === 0) {
            return false;
        }
        for (const rule of group.rules) {
            if (rule.rules) {
                if (!isGroupValid(rule)) {
                    return false;
                }
            } else if (!isRuleValid(rule)) {
                return false;
            }
        }
    } catch (err) {
        console.log("ISQUERYVALID", err, Object.keys(group));
    }

    return true;
}

/**
 * Verifies if a rule is valid.
 *
 * @param {Object} rule The rule to validate.
 * @returns {Boolean} True if valid, false otherwise.
 */
export function isRuleValid(rule) {
    if (!rule.field || !rule.operator) {
        return false;
    }
    if (/null/gi.test(rule.operator)) {
        return true;
    }
    const { value } = rule;

    if (Array.isArray(value)) {
        return value?.length > 0;
    }
    if (/string/.test(typeof value)) {
        return Boolean(value?.trim());
    }
    return value !== null && value !== undefined;
}

/**
 * Verifies if all fields have a corresponding filter.
 *
 * @param {Object} group The group to validate.
 * @param {Object} filtersByValue The filters to check against.
 * @returns {Boolean} True if valid, false otherwise.
 */
function verifyFilters(group, filtersByValue) {
    try {
        for (const rule of group.rules) {
            if (rule.rules) {
                if (!verifyFilters(rule, filtersByValue)) {
                    return false;
                }
            } else if (!Object.prototype.hasOwnProperty.call(filtersByValue, rule.field)) {
                return false;
            }
        }
    } catch (err) {
        console.log("t", err, Object.keys(group));
    }

    return true;
}

/**
 * Checks if all fields have a corresponding filter.
 *
 * @param {Object} mainQuery The query to validate.
 * @param {Object} context The context with filters to check against.
 * @returns {Boolean} True if valid, false otherwise.
 */
function isQueryValid(query, context) {
    let valid = isGroupValid(query);

    // Check the query is consistent with the available filters.
    if (valid && context?.filtersByValue) {
        valid = verifyFilters(query, context.filtersByValue);
    }
    return valid;
}

const emptyRule = function () {
    return {
        field: null,
        id: Math.random(),
        operator: null,
        value: null,
    };
};

const emptyGroup = function () {
    return {
        combinator: "or",
        id: Math.random(),
        rules: [emptyRule()],
    };
};

function reducer(mainQuery, action) {
    const query = { ...mainQuery };
    switch (action.type) {
        case "add-group": {
            const group = findNodeById(action.id, query);
            group.rules.push(emptyGroup());
            return query;
        }
        case "add-rule": {
            const group = findNodeById(action.id, query);
            try {
                group.rules.push(emptyRule());
            } catch (err) {
                console.log("IN EEORO");
                group["rules"] = [];
                group.rules.push(emptyRule());
            }
            return query;
        }
        case "move-rule": {
            const { addedIndex, id, removedIndex } = action;
            const group = findNodeById(id, query);
            group.rules = arrayMove(group.rules, removedIndex, addedIndex);
            return query;
        }
        case "remove-node": {
            const parent = findParentById(action.id, query);
            parent.rules = parent.rules.filter((rule) => rule.id !== action.id);
            return query;
        }
        case "reset-query": {
            let { query } = action;
            query = resetNodeIds(query, "random");
            return query;
        }
        case "set-combinator": {
            const node = findNodeById(action.id, query);
            node.combinator = action.value;
            return query;
        }
        case "set-field": {
            const node = findNodeById(action.id, query);
            node.field = action.value;
            node.operator = action.operator;
            node.value = null;
            return query;
        }
        case "set-operator": {
            const node = findNodeById(action.id, query);
            node.operator = action.value;
            if (/null/.test(action.value)) {
                node.value = null;
            }
            return query;
        }
        case "set-value": {
            const node = findNodeById(action.id, query);
            node.value = action.value;
            return query;
        }
        case "saved-query": {
            return action.query;
        }
        case "no-rule-query": {
            return emptyGroup();
        }
        default: {
            return query;
        }
    }
}

let newToggle = false;

// const useStyles = makeStyles((theme) => ({
//     track: {
//         color: "green !important",
//     },
// }));

const QueryBuilder = React.memo(
    (props) => {
        const [mainQuery, dispatch] = React.useReducer(
            reducer,
            props.qtwo || {
                combinator: "or",
                rules: [],
            },
        );
        // const classes = useStyles();
        const [showQuery, setShowQuery] = React.useState(false);
        const [context, setContext] = React.useState(() => {
            return null;
        });
        // Generate the context only once, or when the properties change.
        React.useEffect(() => {
            const { customOperators, filters, maxLevels, operators } = props;
            setContext({
                customOperators,
                dispatch,
                filters: props.sortFilters ? sortFilterGroupsByLabel(filters) : filters,
                filtersByValue: generateFiltersByValue(filters),
                flattenedFilters: generateFlattenedFilters(filters),
                maxLevels,
                operators,
                operatorsByValue: generateOperatorsByValue(operators, customOperators),
                operatorsByType: generateOperatorsByType(operators, customOperators),
            });
        }, [dispatch, props, props.filters, props.maxLevels, props.operators]);

        // Reset the query if it was changed externally.
        useEffect(() => {
            if (!props.query?.id) {
                dispatch({ type: "reset-query", query: props.query });
            }
        }, [props.query]);
        useEffect(() => {
            if (!(props.qtwo === null)) {
                if (props.qtwo.length === 0) {
                    dispatch({ type: "no-rule-query", query: props.query });
                }
            }
        }, [props.qtwo]);

        useEffect(() => {
            if (props.qtwo) {
                setTimeout(() => {
                    dispatch({ type: "saved-query", query: props.qtwo });
                }, 10);
            }
        }, [props.filters]);
        // Propagate the change if the query is modified.
        useEffect(() => {
            if (props.onChange) {
                const valid = isQueryValid(mainQuery, context);
                props.onChange(mainQuery, valid);
            }
        }, [context, props, props.onChange, mainQuery]);
        useEffect(() => {
            if (props.new) {
                setTimeout(() => {
                    dispatch({ type: "saved-query", query: emptyGroup() });
                }, 10);
                newToggle = true;
            }
        }, [props.new]);
        return mainQuery.id && context ? (
            <div
                className="container-fluid justify-content-left"
                style={{ textAlign: "left", margin: "auto", width: "100%" }}
            >
                <Context.Provider value={context}>
                    <div className="row justify-content-around">
                        <div className="col-md-8 query-builder">
                            <RuleGroup
                                combinator={mainQuery.combinator}
                                id={mainQuery.id}
                                level={0}
                                rules={mainQuery.rules}
                            />
                        </div>
                        {props.debug && (
                            <div
                                className="col-md-4 third-step"
                                style={{
                                    background:
                                        showQuery &&
                                        "linear-gradient(180deg, rgba(247,247,247,1) 0%, rgba(209,207,207,0.5802696078431373) 100%)",
                                    borderRadius: 12,
                                    padding: 10,
                                    fontSize: 16,
                                    overflowY: "scroll",
                                    height: 330,
                                    marginTop: 15,
                                    boxShadow: showQuery && "3px 3px 10px lightgrey",
                                }}
                            >
                                <Tabs
                                    defaultActiveKey="json-query"
                                    id="uncontrolled-tab-example"
                                    onSelect={(key) => {
                                        if (key === "analyse") window.open("https://tool.finsoftai.com/", "_blank");
                                    }}
                                >
                                    <Tab
                                        eventKey="json-query"
                                        title={
                                            <div style={{ display: "flex", alignItems: "center" }}>
                                                <FormControlLabel
                                                    control={
                                                        <Switch
                                                            checked={showQuery}
                                                            onChange={() => {
                                                                setShowQuery(!showQuery);
                                                            }}
                                                            name="checkedB"
                                                            color="primary"
                                                        />
                                                    }
                                                    label="Debug JSON"
                                                />
                                            </div>
                                        }
                                    >
                                        {showQuery && (
                                            <pre
                                                style={{
                                                    height: "100%",
                                                    marginTop: 8,
                                                }}
                                            >
                                                {JSON.stringify(formatQuery(mainQuery), null, 4)}
                                            </pre>
                                        )}
                                    </Tab>
                                    <Tab eventKey="help" title="Help">
                                        <div>
                                            {" "}
                                            <b>Query Builder</b> enables the research analyst to find targeted social
                                            media and news sentiment for all the events associated with the selected
                                            stock. It obtains the data from our data warehouse and filters noise to get
                                            a focused sentiment for the specific event being researched by the analyst.
                                            The Query Builder can be used by the analyst to create, execute and save a
                                            new query; or execute and modify existing queries.
                                            <ul>
                                                <li>
                                                    To create a query the user must do as follows:
                                                    <ol>
                                                        <li>
                                                            Select the Stock and the start date and end date for the
                                                            specific event.
                                                        </li>
                                                        <li>
                                                            Once the user selects the above, a new search query can be
                                                            created by selecting one or more of the following search
                                                            entities or fields–{" "}
                                                            <b>Author, Person, Company, Keyword and Concept.</b> The
                                                            list of selected search entities can be combined together
                                                            using logical and relational operators.
                                                        </li>
                                                    </ol>
                                                </li>
                                                <li>
                                                    Search Entities Description:
                                                    <ol>
                                                        <li>Author - The person who wrote the article/tweet.</li>
                                                        <li>Person - Person mentioned in the article/tweet.</li>
                                                        <li>
                                                            Company - Organization/Ticker to which the article/tweet is
                                                            related.
                                                        </li>
                                                        <li>Keyword - Significant words in the article/tweet.</li>
                                                        <li>
                                                            Concept - Topic to which the article/tweet is related to.
                                                        </li>
                                                    </ol>{" "}
                                                </li>
                                            </ul>
                                        </div>
                                    </Tab>
                                    <Tab eventKey="analyse" title="Analyse"></Tab>
                                </Tabs>
                            </div>
                        )}
                    </div>
                </Context.Provider>
            </div>
        ) : (
            <span />
        );
    },
    (prevProps, nextProps) => {
        // // Skip re-rendering if the query didn't change.
        // console.log(prevProps, nextProps)
        if (nextProps.new) {
            return false;
        }
        if (!dequal(prevProps.filters, nextProps.filters)) {
            return false;
        }
        if (newToggle && !dequal(prevProps.new, nextProps.new) && !dequal(prevProps.filters, nextProps.filters)) {
            newToggle = false;
            return false;
        }

        return (
            dequal(prevProps.filters, nextProps.filters) ||
            dequal(prevProps.qtwo, nextProps.qtwo) ||
            (dequal(prevProps.qtwo, nextProps.qtwo) && dequal(prevProps.new, nextProps.new))
        );
    },
    //false true false true true
    //true false true true false
    // true false true false true
);

QueryBuilder.formatQuery = formatQuery;
QueryBuilder.isQueryValid = isGroupValid;
QueryBuilder.operators = operators;

QueryBuilder.defaultProps = {
    customOperators: {},
    debug: false,
    filters: [],
    maxLevels: 1,
    operators: [...operators],
    onChange: null,
    query: {},
    sortFilters: true,
    new: false,
    qtwo: emptyGroup(),
    // classList:[]
};

QueryBuilder.propTypes = {
    customOperators: PropTypes.object,
    debug: PropTypes.bool,
    filters: PropTypes.array,
    maxLevels: PropTypes.number,
    operators: PropTypes.array,
    onChange: PropTypes.func,
    query: PropTypes.object,
    qtwo: PropTypes.any,
    sortFilters: PropTypes.bool,
    new: PropTypes.bool,
    queryVersion: PropTypes.number,
};

export default QueryBuilder;
