import React, { Component, useEffect, useState } from 'react';
import { makeStyles } from "@material-ui/core";
import {Box, TextField, Grid, Typography, Button } from "@mui/material";
// import { Button } from 'bootstrap';

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    alignItems: "center",
    marginTop:"2%"
  },
  border: {
    borderBottom: "4px solid black",
    width: "100%"
  },
  content: {
    // paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    fontWeight: 500,
    fontSize: 20,
    color: "black"
  }
}));

let  weights = {
  Credibility:{
      User_classification:0.3, 
      User_premium_room:0.12,
       User_plus_tier:0.05, 
     User_following:0, User_official:0.08, User_follower:0.35,
     User_join_date:0.1
  },
  Usefulness:{
       ST_Sentiment:0.2,
       likes:0.8
      
  },
  Completeness:{
      Body_20:0.4,
      Body_140:0.75,
      Body_1000:1
  },
  Weights:{
    follower_max:20000,
    follower_min:5,
    likes_max:100,
    likes_min:0
  }
}

let dq_total_weights={
  Credibility:0.7,
  Usefulness:0.2,
  Completeness:0.1,

}



const DividerWithText = ( text ) => {
    const classes = useStyles();
    return (
     <div className={classes.container}>
       <div className={classes.border} />
       <span className={classes.content}>{text}</span>
       <div className={classes.border} />
     </div>
    );
   };





const DQHomepage =(props)=>{

const [DQWeights, setDQWeights] = useState(weights)
const [DQParamWeights, setDQParamWeights] = useState(dq_total_weights)


const changeWeights = (section, key, value) =>{
    const updatedValue = {[key]: (value?parseFloat(value):0)}
    setDQWeights((prevState)=>{
      return {...prevState, [section]:{
      ...prevState[section], ...updatedValue
    }  
     }})
}


const changeParamWeights = (key, value) =>{
  const updatedValue = {key:value}
  setDQParamWeights((prevValue)=>{return {...prevValue, ...updatedValue  
   }})
}
const resetWeights = ()=>{
  setDQWeights((prevState)=>{ return weights})
}
useEffect(()=>{
props.setWeights(DQWeights)
},[DQWeights])

return (
        <>
        <div className='container-fluid' style={{ backgroundColor:"white", }}>
            <div className='row w-100 justify-content-center' >
              <Typography variant='h4' align='center'>Stocktwits Data Quality Weights</Typography>
              {DividerWithText("Creditibility{"+ dq_total_weights['Credibility']+"}")}
              <Box style={{textAlign:"center", margin:"auto"}}> 
                <Grid container justifyContent="space-between" spacing={0} style={{width:"100%", backgroundColor:"whitesmoke", marginTop:"1%"}}>
                  <Grid className='mt-1 mb-2' md={3}>
                    <Typography> User Followers</Typography>
                    <TextField type="number" size='small'  varient="outlined" label="% weightage"
                     value = {DQWeights.Credibility.User_follower} onChange={(event)=>{changeWeights("Credibility", "User_follower", event.target.value)}}/>
                  </Grid>
                  <Grid className='mt-1 mb-2' md={3}>
                    <Typography> User Classification</Typography>
                    <TextField type="number" size='small' varient="outlined" label="% weightage"
                     value = {DQWeights.Credibility.User_classification} onChange={(event)=>{changeWeights("Credibility", "User_classification", event.target.value)}} />
                  </Grid>
                  <Grid className='mt-1 mb-2' md={3}>
                    <Typography> User Date of Joining</Typography>
                    <TextField type="number" size='small' varient="outlined" label="% weightage" 
                     value = {DQWeights.Credibility.User_join_date} onChange={(event)=>{changeWeights("Credibility", "User_join_date", event.target.value)}}/>
                  </Grid>
                  <Grid className='mt-1 mb-2' md={3}>
                    <Typography> User Official</Typography>
                    <TextField type="number" size='small' varient="outlined" label="% weightage" 
                     value = {DQWeights.Credibility.User_official} onChange={(event)=>{changeWeights("Credibility", "User_official", event.target.value)}}/>
                  </Grid>
                  <Grid className='mt-1 mb-2' md={3}>
                    <Typography> User Plus Tier</Typography>
                    <TextField type="number" size='small' varient="outlined" label="% weightage" 
                     value = {DQWeights.Credibility.User_plus_tier} onChange={(event)=>{changeWeights("Credibility", "User_plus_tier", event.target.value)}}/>
                  </Grid>
                  <Grid className='mt-1 mb-2' md={3}>
                    <Typography> User Premium Room</Typography>
                    <TextField type="number" size='small' varient="outlined" label="% weightage" 
                     value = {DQWeights.Credibility.User_premium_room} onChange={(event)=>{changeWeights("Credibility", "User_premium_room", event.target.value)}}/>
                  </Grid>
                  <Grid className='mt-1 mb-2' md={3}>
                    <Typography> Follower max value</Typography>
                    <TextField type="number" size='small' varient="outlined" label="% weightage" 
                     value = {DQWeights.Weights.follower_max} onChange={(event)=>{changeWeights("Weights", "follower_max", event.target.value)}}/>
                  </Grid>
                  <Grid className='mt-1 mb-2' md={3}>
                    <Typography> Follower min value</Typography>
                    <TextField type="number" size='small' varient="outlined" label="% weightage" 
                     value = {DQWeights.Weights.follower_min} onChange={(event)=>{changeWeights("Weights", "follower_min", event.target.value)}}/>
                  </Grid>



                </Grid>
              </Box>
              {DividerWithText("Usefulness{"+ dq_total_weights["Usefulness"]+"}")}
              <Box style={{textAlign:"center", margin:"auto"}}> 
                <Grid container justifyContent="space-between" spacing={0} style={{width:"100%", backgroundColor:"whitesmoke", marginTop:"1%"}}>
                  <Grid className='mt-1 mb-2' md={3}>
                    <Typography> Likes</Typography>
                    <TextField type="number" size='small'  varient="outlined" label="% weightage" 
                     value = {DQWeights.Usefulness.likes} onChange={(event)=>{changeWeights("Usefulness", "likes", event.target.value)}}/>
                  </Grid>
                  <Grid className='mt-1 mb-2' md={3}>
                    <Typography> User Defined Sentiment</Typography>
                    <TextField type="number" size='small' varient="outlined" label="% weightage" 
                     value = {DQWeights.Usefulness.ST_Sentiment} onChange={(event)=>{changeWeights("Usefulness", "ST_Sentiment", event.target.value)}}/>
                  </Grid>
                  <Grid className='mt-1 mb-2' md={3}>
                    <Typography> Max likes</Typography>
                    <TextField type="number" size='small' varient="outlined" label="% weightage" 
                     value = {DQWeights.Weights.likes_max} onChange={(event)=>{changeWeights("Weights", "likes_max", event.target.value)}}/>
                  </Grid>
                  <Grid className='mt-1 mb-2' md={3}>
                    <Typography> Min likes</Typography>
                    <TextField type="number" size='small' varient="outlined" label="% weightage" 
                     value = {DQWeights.Weights.likes_min} onChange={(event)=>{changeWeights("Weights", "likes_min", event.target.value)}}/>
                  </Grid>
                </Grid>
              </Box>
              {DividerWithText("Completeness{"+dq_total_weights["Completeness"]+"}")}
              <Box  style={{textAlign:"center", margin:"auto"}}> 
                <Grid container justifyContent="space-between" spacing={0} style={{width:"100%", backgroundColor:"whitesmoke", marginTop:"1%"}}>
                  <Grid className='mt-1 mb-2' md={3}>
                    <Typography> Character Length 20</Typography>
                    <TextField type="number" size='small'  varient="outlined" label="% weightage"
                     value = {DQWeights.Completeness.Body_20} onChange={(event)=>{changeWeights("Completeness","Body_20", event.target.value)}} />
                  </Grid>
                  <Grid className='mt-1 mb-2' md={3}>
                    <Typography> Character Length 140</Typography>
                    <TextField type="number" size='small'  varient="outlined" label="% weightage"
                     value = {DQWeights.Completeness.Body_140} onChange={(event)=>{changeWeights("Completeness","Body_140", event.target.value)}} />
                  </Grid>
                  <Grid className='mt-1 mb-2' md={3}>
                    <Typography> Character Length 1000</Typography>
                    <TextField type="number" size='small'  varient="outlined" label="% weightage"
                     value = {DQWeights.Completeness.Body_1000} onChange={(event)=>{changeWeights("Completeness","Body_1000", event.target.value)}} />
                  </Grid>
                </Grid>
              </Box>
              <div className='row mt-2' style={{textAlign:"end", width:"auto"}}>
                <Button 
                className="col-md-2"  variant="contained" onClick={()=>{resetWeights()}}
                style={{ backgroundColor: "#034ea2", color: "white", marginBottom: 5, fontWeight: 400, fontSize: 20, borderRadius: 10, textTransform: "capitalize" }}
                                
                            >Reset</Button>
              </div>


            </div>
            
        </div>
        </>
    )
}


export default DQHomepage;