import "bootstrap/dist/css/bootstrap.min.css"
import React, { useState, useEffect } from "react"
import logo from "./static/ssi-research-logo.png"
import compositeSentImg from "./static/composite_sent.png"
import pieChartsImg from "./static/piecharts.png"
import graphsImg from "./static/charts.png"
import tablesImg from "./static/tables.png"
import TrendingGraphs from "./TrendingGraphs"
import AlertsWeightage from "./AlertsWeightage"
import {
    Button,
    FormControl,
    Select,
    Modal,
    IconButton,
    Backdrop,
    MenuItem,
    Menu,
    Snackbar,
    SnackbarContent
} from "@material-ui/core"
import { makeStyles, withStyles } from "@material-ui/core/styles"
import PersonIcon from "@material-ui/icons/Person"
import axios from "axios"
import DirectionsWalkIcon from "@material-ui/icons/DirectionsWalk"
import HelpOutlineIcon from "@material-ui/icons/HelpOutline"
import CloseIcon from "@material-ui/icons/Close"
import { useAuthState } from "react-firebase-hooks/auth"
import { useNavigate } from "react-router-dom"
import DisplaySettingsOutlinedIcon from "@mui/icons-material/DisplaySettingsOutlined"
import {
    Card,
    CardContent,
    Typography,
    CardMedia,
    Switch,
    ToggleButton,
    ToggleButtonGroup
} from "@mui/material"
import { auth, logout } from "./firebase"
import Highcharts from "highcharts/highstock"
import indicatorsAll from "highcharts/indicators/indicators-all"
import annotationsAdvanced from "highcharts/modules/annotations-advanced"
import priceIndicator from "highcharts/modules/price-indicator"
import fullScreen from "highcharts/modules/full-screen"
import wordCloud from "highcharts/modules/wordcloud.js"
import "highcharts/css/annotations/popup.css"
import useUser from "./hooks/useUser"

wordCloud(Highcharts)
require("highcharts/modules/exporting")(Highcharts)
indicatorsAll(Highcharts)
annotationsAdvanced(Highcharts)
priceIndicator(Highcharts)
fullScreen(Highcharts)

let initialStockOptions = {
    buzz: {
        chart: {
            type: "bar"
        },
        plotOptions: {
            series: {
                stacking: "normal"
            },
            visible: false
        },
        credits: {
            text: "finsoftai.com",
            href: "http://www.finsoftai.com",
            style: {
                fontSize: 12
            },
            position: {
                align: "right",
                x: -10
            }
        },
        stockTools: {
            gui: {
                enabled: false // disable the built-in toolbar
            }
        },
        xAxis: [
            {
                title: {
                    text: "Tickers",
                    style: { fontWeight: "bold", fontSize: "13px" }
                }
            }
        ],

        yAxis: [
            {
                title: {
                    text: "Buzz Volume",
                    style: { fontWeight: "bold", fontSize: "13px" }
                }
            }
        ],
        title: {
            text: ""
        },
        exporting: {
            buttons: {
                contextButton: {
                    menuItems: ["viewFullscreen"]
                }
            }
        },
        series: [
            {
                data: [{ x: 1, y: 13 }]
            }
        ]
    },
    sentiment: {
        chart: {
            type: "bar",
            styledmode: "on"
        },
        credits: {
            text: "finsoftai.com",
            href: "http://www.finsoftai.com",
            style: {
                fontSize: 12
            },
            position: {
                align: "right",
                x: -10
            }
        },
        stockTools: {
            gui: {
                enabled: false // disable the built-in toolbar
            }
        },
        xAxis: [
            {
                title: {
                    text: "Tickers",
                    style: { fontWeight: "bold", fontSize: "13px" }
                }
            }
        ],

        yAxis: [
            {
                title: {
                    text: "sentiment",
                    style: { fontWeight: "bold", fontSize: "13px" }
                }
            }
        ],
        title: {
            text: ""
        },

        exporting: {
            buttons: {
                contextButton: {
                    menuItems: ["viewFullscreen"]
                }
            }
        },

        labels: {
            formatter: function () {
                return Highcharts.numberFormat(this.value, 2)
            }
        },
        series: [
            {
                name: "Tickers",
                data: [
                    0.481, 0.262, 0.253, 0.215, 0.131, 0.112, 0.94, -0.35, -0.3,
                    -0.8
                ]
            }
        ]
    },
    change: {
        chart: {
            type: "bar",
            styledmode: "on"
        },
        credits: {
            text: "finsoftai.com",
            href: "http://www.finsoftai.com",
            style: {
                fontSize: 12
            },
            position: {
                align: "right",
                x: -10
            }
        },
        stockTools: {
            gui: {
                enabled: false // disable the built-in toolbar
            }
        },
        xAxis: [
            {
                title: {
                    text: "Tickers",
                    style: { fontWeight: "bold", fontSize: "13px" }
                }
            }
        ],

        yAxis: [
            {
                title: {
                    text: "sentiment",
                    style: { fontWeight: "bold", fontSize: "13px" }
                }
            }
        ],
        title: {
            text: ""
        },

        exporting: {
            buttons: {
                contextButton: {
                    menuItems: ["viewFullscreen"]
                }
            }
        },

        labels: {
            formatter: function () {
                return Highcharts.numberFormat(this.value, 2)
            }
        },
        series: [
            {
                name: "Tickers",
                data: [
                    0.481, 0.262, 0.253, 0.215, 0.131, 0.112, 0.94, -0.35, -0.3,
                    -0.8
                ],
                color: "#43a84a",
                negativeColor: "#e64736",

                type: "bar",
                pointStart: 0
            }
        ]
    }
}

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: "white !important"
    },
    icon: {
        fill: "blue"
    },
    outlined: {
        backgroundColor: "blue",
        height: "100%"
    },
    selectMenu: {
        color: "black !important"
    },
    modal: {
        display: "flex",
        width: "100% !important",
        alignItems: "center",
        justifyContent: "center"
    },
    modalTwo: {
        margin: "auto",
        marginTop: "5%",
        flexGrow: 1,
        alignItems: "center",
        justifyContent: "center"
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 2, 2)
        // maxWidth: "80% !important"
    },
    paperTwo: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 3, 2),
        paddingRight: 0
    },
    fullscreenBackground: {
        border: "solid 2px red !important",
        height: "100% !important"
    }
}))

const Navbar = (props) => {
    const [queryName, setQueryName] = useState("")
    const [queryText, setQueryText] = useState("")
    const [queryVersion, setQueryVersion] = useState(0)
    const [modalToggle, setToggle] = useState(false)
    const [screenModalToggle, setScreenModalToggle] = useState(false)
    const [parametersToggle, setParameters] = React.useState("both")
    const { currentUser } = useUser()
    const history = useNavigate()
    const [alertState, setAlertState] = React.useState({
        open: false,
        vertical: "top",
        horizontal: "center",
        severity: "",
        message: ""
    })

    const handleAlertOpen = (newState) => {
        setAlertState({ alertOpen: true, ...newState })
    }

    const handleAlertClose = () => {
        setAlertState({ ...alertState, alertOpen: false })
    }

    const setScreens = (source, event) => {
        // Get the previous state of display screens
        props.setDisplayScreens((prevState) => {
            // Update the state based on the source of the event
            const updatedState = {
                ...prevState,
                [source]: event.target.checked
            }

            // Count the number of enabled screens
            const enabledScreensCount = Object.values(updatedState).filter(
                (screen) => screen
            ).length

            // If no screens are enabled, prevent disabling the current screen
            if (enabledScreensCount === 0 && !event.target.checked) {
                handleAlertOpen({
                    vertical: "top",
                    horizontal: "center",
                    message: "At least one output panel must be enabled",
                    contentStyle: {
                        backgroundColor: "#008080",
                        fontSize: 18,
                        marginTop: "20%"
                    }
                })
                return prevState // Revert to the previous state
            }

            // Otherwise, update the state with the new value
            return updatedState
        })
    }

    const handleSaveScreenLayout = async () => {
        props.setDisplayScreens(props.screensOnDisplay)

        // Call API to save
        try {
            await axios.post(
                `${process.env.REACT_APP_DATA_SERVER_API_URL}/layout`,
                {
                    userId: currentUser.id,
                    config: JSON.stringify(props.screensOnDisplay)
                },
                { headers: { "Access-Control-Allow-Origin": "*" } }
            )
            handleAlertOpen({
                vertical: "top",
                horizontal: "center",
                message: "Layouts saved",
                contentStyle: {
                    backgroundColor: "#008080"
                }
            })
        } catch (err) {
            handleAlertOpen({
                vertical: "top",
                horizontal: "center",
                message: "Layouts couldn't be saved",
                contentStyle: {
                    backgroundColor: "#f90504"
                }
            })
        }

        // Close Modal
        handleScreenModalClose()
    }

    const fetchQuery = async () => {
        try {
            const resp = await axios.post(
                `${process.env.REACT_APP_DATA_SERVER_API_URL}/query/params`,
                {
                    query_name: queryName,
                    query_version: queryVersion
                },
                { headers: { "Access-Control-Allow-Origin": "*" } }
            )

            if (resp.data.status) {
                props.querySetter(resp.data.data)
            }
        } catch (err) {
            console.log(err)
        }
    }

    const handleModalClose = () => {
        setToggle(false)
    }

    const handleScreenModalClose = () => {
        setScreenModalToggle(false)
    }

    const handleChange = async (event) => {
        setQueryText(event.target.value)
        setQueryVersion(event.target.value.split("||")[1])
        setQueryName(event.target.value.split("||")[0])
    }
    const classes = useStyles()

    const handleParametersChange = (event, newAlignment) => {
        setParameters(newAlignment)
        props.parameterFlag(newAlignment)
    }

    useEffect(() => {
        if (currentUser) {
            props.setUpdates(false)
        }
    }, [currentUser])

    useEffect(() => {
        fetchQuery()
    }, [queryName, queryText])

    const [anchorEl, setAnchorEl] = useState(null)

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(() => {
            return null
        })
    }

    return (
        <div>
            <Snackbar
                anchorOrigin={{
                    vertical: alertState.vertical,
                    horizontal: alertState.horizontal
                }}
                open={alertState.alertOpen}
                onClose={handleAlertClose}
                autoHideDuration={2000}
                key={alertState.vertical + alertState.horizontal}
                severity='info'
                bodyStyle={{ backgroundColor: "teal", color: "coral" }}>
                <SnackbarContent
                    message={alertState.message}
                    style={alertState.contentStyle}></SnackbarContent>
            </Snackbar>
            <div
                className='navbar navbar-expand-lg navbar-light'
                style={{ backgroundColor: "white" }}>
                <div className='container-fluid'>
                    <div>
                        <Modal
                            aria-labelledby='transition-modal-title'
                            aria-describedby='transition-modal-description'
                            className={classes.modal}
                            open={modalToggle}
                            keepMounted
                            onClose={handleModalClose}
                            closeAfterTransition
                            BackdropComponent={Backdrop}
                            BackdropProps={{
                                timeout: 500
                            }}>
                            <div
                                classes='container-fluid '
                                className={classes.paper}
                                style={{ maxWidth: "70%" }}>
                                <div className='row'>
                                    <h2
                                        className='col-10 transition-modal-description'
                                        style={{ textAlign: "left" }}>
                                        About SST Research
                                    </h2>
                                    <div
                                        className='col-2'
                                        style={{ textAlign: "right" }}>
                                        <IconButton
                                            variant='outlined'
                                            onClick={() => {
                                                handleModalClose()
                                            }}>
                                            <CloseIcon />
                                        </IconButton>
                                    </div>
                                </div>
                                <hr></hr>
                                <div
                                    className='row'
                                    style={{
                                        textAlign: "left",
                                        fontFamily: "Poppins",
                                        display: "inline-block",
                                        fontSize: "1.1rem",
                                        fontWeight: 400
                                    }}>
                                    <p>
                                        <b>FinSoftAi</b> is developing a suite
                                        of products for Social Trading. Each
                                        product works stand-alone, while
                                        seamlessly interoperating with other
                                        products in the{" "}
                                        <b>
                                            Social Sentiment Insights (SSI)
                                            suite
                                        </b>
                                        . Institutional and Retail Customers can
                                        pick and choose what they want based on
                                        their growing Social Trading
                                        requirements. Planned products are SST
                                        Beat, SST Research, SST Insights, SST
                                        Enterprise and SST Consortium.
                                    </p>
                                    <p>
                                        <b>SST Research</b> enables Equity
                                        Research Analysts, Portfolio Managers
                                        and Traders make the right investment
                                        decisions based on the market pulse. SST
                                        Research filters and uses Machine
                                        Learning to process a huge volume of
                                        news and social media to provide a
                                        detailed analysis of overall and event
                                        specific sentiment for supported stocks.
                                        The analyst can slice and dice sentiment
                                        by source and date range to visualize
                                        the aggregated sentiment and social buzz
                                        (news and tweet volume),while also
                                        charting Sentiment over time versus
                                        stock price, stock volume, news and
                                        social volume etc. The news items and
                                        tweets can be viewed by hovering over
                                        the chart and separately in the document
                                        pane. Each news and tweet is color coded
                                        to reflect positive, negative and
                                        neutral sentiment along with the
                                        sentiment score for the document.
                                    </p>
                                    <p>
                                        The{" "}
                                        <b>
                                            Natural Language Processing (NLP)
                                            Query Builder
                                        </b>{" "}
                                        can be used to build and test queries
                                        for both financial and non-financial
                                        events that influence investor and
                                        trader sentiment. The query builder uses
                                        Machine Learning to display a list of
                                        trending authors, persons, keywords,
                                        companies, and categories for the
                                        selected stock and date range. The
                                        analyst is prompted to build a query
                                        using these trending items for the
                                        specific event being researched. The
                                        developed queries can be backtested and
                                        saved.
                                    </p>
                                    <p>
                                        {" "}
                                        This is a first version of SST Research.
                                        Upcoming versions will support a larger
                                        number of stocks, and additional sources
                                        for sentiment analysis. The next version
                                        will also enable investment decisions
                                        based on <b>Search analytics</b> using
                                        Google Trends etc. Do write to us at{" "}
                                        <a href='mailto:contactus@finsoftai.com'>
                                            contactus@finsoftai.com
                                        </a>{" "}
                                        , if you want us to support specific
                                        stocks or need additional custom
                                        features.
                                    </p>
                                    <p>
                                        {" "}
                                        <b>© 2021FinSoftAi</b>-{" "}
                                        <a href='https://www.finsoftai.com/'>
                                            Social Sentiment Insights
                                        </a>
                                    </p>
                                </div>
                            </div>
                        </Modal>
                    </div>

                    <div
                        className='flex flex-row justify-between items-center py-2'
                        style={{ width: "100%" }}>
                        <a className=''>
                            <img
                                src={logo}
                                alt=''
                                width={200}
                                style={{
                                    filter: "drop-shadow(0px 3px 3px lightgrey)"
                                }}></img>
                        </a>
                        <div className='flex items-center'>
                            <Button
                                className={`${props.classList[1]} p-2 text-white`}
                                size='small'
                                variant='contained'
                                style={{
                                    borderRadius: "5px",
                                    backgroundColor: "#0868d4"
                                }}
                                onClick={() => {
                                    props.newFlag(true)
                                    setQueryText(() => {
                                        return "||"
                                    })
                                    setQueryVersion(() => {
                                        return ""
                                    })
                                    setQueryName(() => {
                                        return ""
                                    })
                                    props.setRunHelp(false)
                                }}>
                                New Query
                            </Button>
                        </div>
                        <div className={props.classList[0]}>
                            <FormControl variant='outlined'>
                                <Select
                                    native
                                    variant='outlined'
                                    value={queryText}
                                    onChange={handleChange}
                                    className={classes.select}
                                    label='Query Name'
                                    key={Math.floor(Math.random() * 99999)}
                                    inputProps={{
                                        name: "query",
                                        id: "outlined-query-native-simple",
                                        classes: {
                                            icon: classes.icon
                                        }
                                    }}
                                    classes={{
                                        outlined: classes.outlined,
                                        root: classes.root,
                                        selectMenu: classes.selectMenu
                                    }}
                                    style={{
                                        backgroundColor: "white",
                                        borderRadius: 5
                                    }}>
                                    <option value='' disabled>
                                        Select Existing Queries
                                    </option>
                                    {props.queryOptions}
                                </Select>
                            </FormControl>
                        </div>
                        <div className='w-fit'>
                            <IconButton
                                size='small'
                                variant='outlined'
                                style={{
                                    height: "50%",
                                    width: "100%",
                                    color: "#474747",
                                    borderRadius: 10,
                                    padding: 8,
                                    fontWeight: 600,
                                    fontSize: 18,
                                    backgroundColor: "#f9ce1b",
                                    boxShadow: " 2px 2px lightgrey"
                                }}
                                onClick={() => {
                                    setQueryText((t) => {
                                        return "Announcement to add TSLA to S&P 500||1"
                                    })
                                    setQueryVersion((t) => {
                                        return "1"
                                    })
                                    setQueryName((t) => {
                                        return "Announcement to add TSLA to S&P 500"
                                    })
                                    props.setRunHelp(true)
                                    props.newFlag(false)
                                    // setTour()
                                }}>
                                Take a Tour
                                <DirectionsWalkIcon />
                            </IconButton>
                        </div>
                        <div className=''>
                            <a
                                href='https://heroic-sopapillas-390aa3.netlify.app/'
                                target='_blank'
                                rel='noreferrer'>
                                <Button
                                    variant='contained'
                                    style={{
                                        backgroundColor: "#0868d4",
                                        color: "white",
                                        fontSize: 16,
                                        textTransform: "none" // Prevent text transformation
                                    }}
                                    size='medium'
                                    disableElevation // Disable button elevation
                                >
                                    ESG Dashboard
                                </Button>
                            </a>
                        </div>
                        <div className=''>
                            <IconButton
                                className='new-step-four'
                                variant='outlined'
                                style={{
                                    fontWeight: 800,
                                    fontSize: 30,
                                    borderRadius: 10
                                }}
                                onClick={() => {
                                    setToggle(true)
                                }}>
                                {/* <h4 style={{ marginTop: 8, marginRight: 5 }}> About </h4> */}
                                <HelpOutlineIcon fontSize='large' />
                            </IconButton>
                        </div>
                        <div className='col-md-auto'>
                            <IconButton
                                variant='outlined'
                                className='new-step-one'
                                style={{
                                    height: "90%",
                                    width: "100%",
                                    fontWeight: 800,
                                    fontSize: 30,
                                    borderRadius: 10
                                }}
                                onClick={() => {
                                    setScreenModalToggle(true)
                                }}>
                                <DisplaySettingsOutlinedIcon fontSize='large' />
                            </IconButton>
                        </div>
                        <div className='col-md-auto text-right d-flex'>
                            <div className='flex flex-col items-center gap-0.5'>
                                <h5
                                    style={{
                                        fontSize: 20,
                                        color: "#7e7f82",
                                        marginRight: 15,
                                        marginBottom: 0
                                    }}>
                                    {currentUser
                                        ? currentUser.displayName
                                        : "Research Analyst"}
                                </h5>
                            </div>
                            <div>
                                <IconButton
                                    aria-controls='simple-menu'
                                    aria-haspopup='true'
                                    color='primary'
                                    aria-label='Person'
                                    style={{
                                        height: 50,
                                        width: 50,
                                        fontSize: 20,
                                        marginRight: 15,
                                        backgroundColor: "#f2f2f2"
                                    }}
                                    onClick={handleClick}>
                                    {currentUser ? (
                                        <img
                                            src={currentUser.photoURL}
                                            style={{
                                                height: 45,
                                                width: 45,
                                                borderRadius: "50%"
                                            }}
                                        />
                                    ) : (
                                        <PersonIcon
                                            style={{
                                                height: 30,
                                                width: 30,
                                                fontSize: 20,
                                                color: "#024ea2"
                                            }}
                                        />
                                    )}
                                </IconButton>
                                <Menu
                                    id='simple-menu'
                                    anchorEl={anchorEl}
                                    keepMounted
                                    open={Boolean(anchorEl)}
                                    onClose={() => {
                                        handleClose(null)
                                    }}>
                                    <MenuItem>
                                        {" "}
                                        <a
                                            onClick={() => {
                                                logout(history)
                                            }}>
                                            Logout
                                        </a>
                                    </MenuItem>
                                </Menu>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <Modal
                    aria-labelledby='transition-modal-title'
                    aria-describedby='transition-modal-description'
                    className={classes.modal}
                    open={screenModalToggle}
                    keepMounted
                    onClose={handleScreenModalClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500
                    }}>
                    <div
                        classes='container-fluid'
                        className={classes.paper}
                        style={{
                            maxWidth: "70%",
                            overflowY: "scroll",
                            maxHeight: "80%"
                        }}>
                        <div className='row'>
                            <h2
                                className='col-10 transition-modal-description'
                                style={{ textAlign: "left" }}>
                                Configure Screen Layout
                            </h2>
                            <div
                                className='col-2'
                                style={{ textAlign: "right" }}>
                                <IconButton
                                    variant='outlined'
                                    onClick={() => {
                                        handleScreenModalClose()
                                    }}>
                                    <CloseIcon />
                                </IconButton>
                            </div>
                            {/* <div className='col-1'></div> */}
                        </div>
                        <hr></hr>
                        <div
                            style={{
                                textAlign: "center",
                                fontFamily: "Poppins"
                            }}>
                            <Typography
                                variant='h6'
                                component='div'
                                style={{ marginBottom: "10px" }}>
                                Input Parameters
                            </Typography>
                            <ToggleButtonGroup
                                color='primary'
                                value={parametersToggle}
                                exclusive
                                size='small'
                                onChange={handleParametersChange}
                                aria-label='Platform'>
                                <ToggleButton value='general'>
                                    General
                                </ToggleButton>
                                <ToggleButton value='esg'>ESG</ToggleButton>
                                <ToggleButton value='both'>Both</ToggleButton>
                            </ToggleButtonGroup>
                        </div>
                        <hr></hr>
                        <div
                            style={{
                                textAlign: "center",
                                fontFamily: "Poppins",
                                margin: "20px 0"
                            }}>
                            <Typography variant='h6' component='div'>
                                Turn ON/OFF Output Panels
                            </Typography>
                        </div>
                        <div
                            className='row justify-content-center'
                            style={{
                                textAlign: "center",
                                fontFamily: "Poppins",
                                fontSize: "1.1rem",
                                fontWeight: 400,
                                width: "100%"
                            }}>
                            <div className='col-md-5 pb-1'>
                                <Card variant='outlined'>
                                    <CardMedia
                                        component='img'
                                        // height="40"
                                        image={compositeSentImg}
                                        alt='green iguana'
                                    />
                                    <CardContent>
                                        <Typography
                                            gutterBottom
                                            variant='h5'
                                            component='div'>
                                            Composte Sentiment
                                        </Typography>
                                        <Typography
                                            variant='body2'
                                            color='text.secondary'></Typography>
                                        <div>
                                            <Switch
                                                checked={
                                                    props.screensOnDisplay
                                                        .composite
                                                }
                                                onChange={(event) => {
                                                    event.persist()
                                                    setScreens(
                                                        "composite",
                                                        event
                                                    )
                                                }}
                                                inputProps={{
                                                    "aria-label": "controlled"
                                                }}></Switch>
                                        </div>
                                    </CardContent>
                                </Card>
                            </div>

                            <div className='col-md-5 pb-1'>
                                <Card variant='outlined'>
                                    <CardMedia
                                        component='img'
                                        // height="40"
                                        image={graphsImg}
                                        alt='green iguana'
                                    />
                                    <CardContent>
                                        <Typography
                                            gutterBottom
                                            variant='h5'
                                            component='div'>
                                            Sentiment Charts
                                        </Typography>
                                        <Typography
                                            variant='body2'
                                            color='text.secondary'>
                                            Displays Sentiment vs Stock price
                                            and Social buzz vs Trading volume
                                            over the query period. Displays
                                            various sentiment based technical
                                            indicators.
                                        </Typography>
                                        <div>
                                            <Switch
                                                checked={
                                                    props.screensOnDisplay.graph
                                                }
                                                onChange={(event) => {
                                                    event.persist()
                                                    setScreens("graph", event)
                                                }}
                                                inputProps={{
                                                    "aria-label": "controlled"
                                                }}></Switch>
                                        </div>
                                    </CardContent>
                                </Card>
                            </div>
                            <div className='col-md-5 pt-1'>
                                <Card variant='outlined'>
                                    <CardMedia
                                        component='img'
                                        style={{}}
                                        image={pieChartsImg}
                                        alt='green iguana'
                                    />
                                    <CardContent>
                                        <Typography
                                            gutterBottom
                                            variant='h5'
                                            component='div'>
                                            Aggregated Sentiment
                                        </Typography>
                                        <Typography
                                            variant='body2'
                                            color='text.secondary'>
                                            Displays the aggregated and colour
                                            coded view of sentiment for each
                                            source over the query period.
                                        </Typography>
                                        <div className='pb-2'>
                                            <Switch
                                                checked={
                                                    props.screensOnDisplay
                                                        .piecharts
                                                }
                                                onChange={(event) => {
                                                    event.persist()
                                                    setScreens(
                                                        "piecharts",
                                                        event
                                                    )
                                                }}
                                                inputProps={{
                                                    "aria-label": "controlled"
                                                }}></Switch>
                                        </div>
                                    </CardContent>
                                </Card>
                            </div>

                            <div className='col-md-5 pt-1'>
                                <Card variant='outlined'>
                                    <CardMedia
                                        component='img'
                                        height='180'
                                        image={tablesImg}
                                        alt='green iguana'
                                    />
                                    <CardContent>
                                        <Typography
                                            gutterBottom
                                            variant='h5'
                                            component='div'>
                                            Document Panel
                                        </Typography>
                                        <Typography
                                            variant='body2'
                                            color='text.secondary'>
                                            Displays source wise documents
                                            returned by the query that are
                                            colour coded by sentiment.
                                        </Typography>
                                        <div>
                                            <Switch
                                                checked={
                                                    props.screensOnDisplay
                                                        .tables
                                                }
                                                onChange={(event) => {
                                                    event.persist()
                                                    setScreens("tables", event)
                                                }}
                                                inputProps={{
                                                    "aria-label": "controlled"
                                                }}></Switch>
                                        </div>
                                    </CardContent>
                                </Card>
                            </div>
                            <hr className='mt-2'></hr>
                            <div
                                className='row justify-content-end align-items-right mt-2'
                                style={{
                                    textAlign: "right !important",
                                    width: "100%"
                                }}>
                                <Button
                                    color='primary'
                                    variant='contained'
                                    className='col-1'
                                    onClick={handleSaveScreenLayout}
                                    style={{
                                        backgroundColor: "#034ea2",
                                        color: "white",
                                        marginRight: "2%",
                                        float: "right !important"
                                    }}>
                                    Save
                                </Button>
                                <Button
                                    color='primary'
                                    onClick={handleScreenModalClose}
                                    variant='contained'
                                    className='col-1'
                                    style={{
                                        backgroundColor: "#034ea2",
                                        color: "white",
                                        padding: "0px 5px",
                                        float: "right !important"
                                    }}>
                                    Cancel
                                </Button>
                            </div>
                        </div>
                    </div>
                </Modal>
            </div>
            <TrendingGraphs
                fetchUserPortfolios={props.fetchUserPortfolios}
                modalToggle={props.dailyModal}
                setModalToggle={async (data) => {
                    await props.setDailyModal(data)
                }}
                portfolios={props.portfolios}
                selectedPortfolio={props.selectedPortfolio}
                userId={currentUser?.uid}
                handleSelectPortfolio={
                    props.handleSelectPortfolio
                }></TrendingGraphs>
            <AlertsWeightage
                modalToggle={props.alertsWeightage}
                setModalToggle={async (data) => {
                    await props.setAlertsWeightageModalOpen(data)
                }}></AlertsWeightage>
        </div>
    )
}

export default Navbar
