import { Button, Modal, Backdrop, IconButton } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import { useEffect, useState, useCallback } from "react"
import { DemoContainer } from "@mui/x-date-pickers/internals/demo"
import { LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker"
import Switcher from "../common/Switcher"
import { marketStatusItems } from "../../constants"
import InfoTwoToneIcon from "@mui/icons-material/InfoTwoTone"
import CloseIcon from "@mui/icons-material/Close"
import { FullScreen, useFullScreenHandle } from "react-full-screen"
import {
    Card,
    CardContent,
    CardHeader,
    Typography,
    Switch,
    ButtonGroup,
    Stack
} from "@mui/material"
import HighchartsReact from "highcharts-react-official"
import fullScreen from "highcharts/modules/full-screen"
import wordCloud from "highcharts/modules/wordcloud.js"
import "highcharts/css/annotations/popup.css"
import Highcharts from "highcharts/highstock"
import indicatorsAll from "highcharts/indicators/indicators-all"
import annotationsAdvanced from "highcharts/modules/annotations-advanced"
import priceIndicator from "highcharts/modules/price-indicator"
import Wordcloud from "../Wordcloud"
import { getGraphData } from "../../utils/functions"
import { SINGLE_GRAPH_DURATION } from "../../constants"

wordCloud(Highcharts)
require("highcharts/modules/exporting")(Highcharts)
indicatorsAll(Highcharts)
annotationsAdvanced(Highcharts)
priceIndicator(Highcharts)
fullScreen(Highcharts)

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: "white !important"
    },
    icon: {
        fill: "blue"
    },
    modal: {
        margin: "auto",
        marginTop: "5%",
        flexGrow: 1,
        alignItems: "center",
        justifyContent: "center"
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 3, 2)
    },
    fullscreenBackground: {
        border: "solid 2px red !important",
        height: "100% !important"
    },
    summaryRoot: {
        justifyContent: "flex-start !important"
    },
    summaryContent: {
        display: "flex",
        alignItems: "center",
        flexGrow: "unset !important",
        marginLeft: "10px"
    }
}))

const StockViewDashboard = ({
    displayDate,
    graphDateTime,
    checkFutureData,
    setMarketStatusKey,
    setHideAlertsToggle,
    marketStatusKey,
    setRun,
    setGraphToggle,
    graphDataCount,
    setViews,
    setGraphDataCount,
    buzzGraph,
    sentiGraph,
    buzzGraphChange,
    sentiGraphChange,
    tickers,
    formatDayjs,
    setGraphData,
    graphViews,
    GRAPH_NORMALIZE_FACTOR,
    setBuzzGraph,
    setSentiGraph,
    setBuzzGraphChange,
    setSentiGraphChange,
    renderAlerts,
    graphData
}) => {
    const classes = useStyles()
    const [dateChangeModal, setDateChangeModal] = useState(false)
    const [btnGroups, setbtnGroups] = useState([1, 1, 1, 1])
    const [isLoading, setIsLoading] = useState(false)

    const screen1 = useFullScreenHandle()
    const screen2 = useFullScreenHandle()
    const screen3 = useFullScreenHandle()
    const screen4 = useFullScreenHandle()

    const useGraphUpdate = (graphData, graphViews, key, processFunction) => {
        useEffect(() => {
            if (graphData[key]) {
                processFunction(graphData[key])
            }
        }, [graphData[key], graphViews[key], processFunction])
    }

    const sortSeries = (data1, data2, key) => {
        let multiplier =
            (key === "sentiment") | (key === "sentimentChange") ? 0.5 : 1
        const MULTIPLIER = key === "buzz" ? GRAPH_NORMALIZE_FACTOR : 1

        const dataList = tickers
            .map((key) => {
                return {
                    value:
                        multiplier * data1[key] +
                        multiplier * data2[key] * MULTIPLIER,
                    name: key,
                    d1: data1[key],
                    d2: data2[key]
                }
            })
            .filter((item) => item.name !== "FB")

        dataList.sort((a, b) => b.value - a.value).splice(30, 1)

        // if (key === "buzz") {
        //     //sortSeries

        //     let tickerList = dataList.map((t) => t.name)
        //     tickerList = tickerList.filter((item) => {
        //         return item !== "fields"
        //     })
        //     return tickerList
        // }
        if (key === "buzzChange") {
            let tickerList = dataList.map((t) => t.name)
            let topTickerList = tickerList.slice(0, 10)

            tickerList = tickerList.filter((item) => {
                return item !== "fields"
            })
            topTickerList = topTickerList.filter((item) => {
                return item !== "fields"
            })

            return { all: tickerList, top: topTickerList }
        } else if (
            (key === "buzz") |
            (key === "sentiment") |
            (key === "sentimentChange")
        ) {
            let tickerList = dataList
                .sort((a, b) => a.value < b.value)
                .map((t) => t.name)

            let topTickerList = tickerList.slice(0, 10)

            tickerList = tickerList.filter((item) => {
                return item !== "fields"
            })
            topTickerList = topTickerList.filter((item) => {
                return item !== "fields"
            })

            return { all: tickerList, top: topTickerList }
        }
    }

    const getSeries = (tickerList, data1, data2, data3, data4) => {
        let data = {}

        if (data3 && data4) {
            data = tickerList.map((key) => {
                const newsPercent = data1[key]
                const stPercent = data2[key]
                const newsAbs = data3[key]
                const stAbs = data4[key]
                let y = 0
                if (newsPercent && stPercent) {
                    y = parseFloat(0.5 * newsPercent + 0.5 * stPercent)
                } else if (newsPercent) {
                    y = newsPercent
                } else if (stPercent) {
                    y = stPercent
                }

                return {
                    y,
                    newsPercent,
                    stPercent,
                    newsAbs,
                    stAbs
                }
            })
        } else {
            data = tickerList.map((key) => {
                const news = data1[key]
                const st = data2[key]
                let y = 0
                if (st && news) {
                    y = parseFloat(0.5 * st + 0.5 * news)
                } else if (st) {
                    y = st
                } else if (news) {
                    y = news
                }
                return {
                    y,
                    st,
                    news
                }
            })
        }

        return {
            name: "Score",
            type: "bar",
            data,
            pointStart: 0,
            color: "#43a84a",
            negativeColor: "#e64736"
        }
    }

    function getSeriesTwo(data1, data2, tickerList) {
        const data = tickerList
            .map((key) => {
                const st = data2[key] * GRAPH_NORMALIZE_FACTOR
                const news = data1[key]

                return {
                    y: parseFloat(news + st),
                    st,
                    news
                }
            })
            .sort((a, b) => a.y < b.y)

        return {
            name: "Score",
            type: "bar",
            data: data,
            pointStart: 0,
            color: "#43a84a",
            negativeColor: "#e64736"
        }
    }

    const handleGetGraphWithDate = async () => {
        setDateChangeModal(false)
    }

    const selectMarketStatus = (marketStatusKey) => {
        // const key = marketStatusKey ?? "all"
        // setMarketStatusKey(key)
        const marketStatusItem = marketStatusItems.find(
            (item) => item.value === marketStatusKey
        )
        setMarketStatusKey(marketStatusItem ?? marketStatusItems[0])

        if (marketStatusItem.value !== "all") setHideAlertsToggle(true)
        else setHideAlertsToggle(false)
    }

    const topBar = (key) => {
        let KVPair = ["buzz", "sentiment", "changeBuzz", "changeSentiment"]
        return (
            <div className='row mb-2 ' style={{}}>
                <div className='col-md-4' style={{ marginLeft: 2 }}>
                    <Stack direction='row' spacing={0.25} alignItems='center'>
                        <Typography>Top 10</Typography>
                        <Switch
                            inputProps={{ "aria-label": "ant design" }}
                            checked={graphDataCount[KVPair[key - 1]]}
                            onChange={(event) => {
                                setDataCount(key, event.target.checked)
                            }}
                        />
                        <Typography>All</Typography>
                    </Stack>
                </div>
                <ButtonGroup
                    className='col-md-auto'
                    style={{
                        textAlign: "right",
                        marginRight: "1px !important",
                        marginLeft: "auto",
                        paddingLeft: "auto"
                    }}
                    size='small'
                    aria-label='text button group'>
                    <Button
                        style={{
                            backgroundColor:
                                btnGroups[key - 1] === 1
                                    ? "#4b8fcd"
                                    : "lightGrey",
                            height: "2rem",
                            color: btnGroups[key - 1] === 1 ? "white" : "black"
                        }}
                        size='small'
                        variant='contained'
                        onClick={() => {
                            setViews(key, "one_day")
                            let temp = btnGroups
                            temp[key - 1] = 1
                            setbtnGroups((prevState) => {
                                return temp
                            })
                        }}>
                        1 day
                    </Button>
                    <Button
                        style={{
                            backgroundColor:
                                btnGroups[key - 1] === 2
                                    ? "#4b8fcd"
                                    : "lightGrey",
                            height: "2rem",
                            color: btnGroups[key - 1] === 2 ? "white" : "black"
                        }}
                        size='small'
                        variant='contained'
                        onClick={() => {
                            setViews(key, "one_week")
                            let temp = btnGroups
                            temp[key - 1] = 2
                            setbtnGroups((prevState) => {
                                return temp
                            })
                        }}>
                        7 days
                    </Button>
                    <Button
                        style={{
                            backgroundColor:
                                btnGroups[key - 1] === 3
                                    ? "#4b8fcd"
                                    : "lightGrey",
                            height: "2rem",
                            color: btnGroups[key - 1] === 3 ? "white" : "black"
                        }}
                        size='small'
                        variant='contained'
                        onClick={() => {
                            setViews(key, "two_week")
                            let temp = btnGroups
                            temp[key - 1] = 3
                            setbtnGroups((prevState) => {
                                return temp
                            })
                        }}>
                        14 days
                    </Button>
                    <Button
                        style={{
                            backgroundColor:
                                btnGroups[key - 1] === 4
                                    ? "#4b8fcd"
                                    : "lightGrey",
                            height: "2rem",
                            color: btnGroups[key - 1] === 4 ? "white" : "black"
                        }}
                        size='small'
                        variant='contained'
                        onClick={() => {
                            setViews(key, "one_month")
                            let temp = btnGroups
                            temp[key - 1] = 4
                            setbtnGroups((prevState) => {
                                return temp
                            })
                        }}>
                        30 days
                    </Button>
                    {/* <Button
                            style={{
                                backgroundColor:
                                    btnGroups[key - 1] === 5
                                        ? "#4b8fcd"
                                        : "lightGrey",
                                height: "2rem",
                                color: btnGroups[key - 1] === 5 ? "white" : "black"
                            }}
                            size='small'
                            variant='contained'
                            onClick={() => {
                                setViews(key, "two_month")
                                let temp = btnGroups
                                temp[key - 1] = 5
                                setbtnGroups((prevState) => {
                                    return temp
                                })
                            }}>
                            60 days
                        </Button> */}
                </ButtonGroup>
            </div>
        )
    }

    const setDataCount = (key, date) => {
        if (key === 1) {
            setGraphDataCount((prevState) => {
                return { ...prevState, buzz: date }
            })
        } else if (key === 2) {
            setGraphDataCount((prevState) => {
                return { ...prevState, sentiment: date }
            })
        } else if (key === 3) {
            setGraphDataCount((prevState) => {
                return { ...prevState, changeBuzz: date }
            })
        } else if (key === 4) {
            setGraphDataCount((prevState) => {
                return { ...prevState, changeSentiment: date }
            })
        }
    }

    const mapStockDataByDuration = (durationData, stock) => {
        if (stock !== "FB/META") return durationData[stock]
        else return durationData["FB"] ?? durationData["META"]
    }

    const getSingleAbsoluteGraphData = (data, stock) => {
        const labels = [
            "one_day_st",
            "one_week_st",
            "two_week_st",
            "one_month_st",
            "one_day_news",
            "one_week_news",
            "two_week_news",
            "one_month_news"
        ]

        const dataByDuration = data.filter((durationData) =>
            labels.includes(durationData.fields)
        )

        if (stock) {
            const stStockDataByDuration = dataByDuration
                .map((durationData) => {
                    if (
                        durationData.fields === "one_day_st" ||
                        durationData.fields === "one_week_st" ||
                        durationData.fields === "two_week_st" ||
                        durationData.fields === "one_month_st"
                    ) {
                        return mapStockDataByDuration(durationData, stock)
                    }
                })
                .filter((item) => item === 0 || item)

            const newsStockDataByDuration = dataByDuration
                .map((durationData) => {
                    if (
                        durationData.fields === "one_day_news" ||
                        durationData.fields === "one_week_news" ||
                        durationData.fields === "two_week_news" ||
                        durationData.fields === "one_month_news"
                    ) {
                        return mapStockDataByDuration(durationData, stock)
                    }
                })
                .filter((item) => item === 0 || item)

            return [...stStockDataByDuration, ...newsStockDataByDuration]
        }

        return dataByDuration
    }

    const getSingleBuzzGraphData = (arr) => {
        // Check if the array has an odd length
        if (arr.length % 2 !== 0) {
            return "Array length must be even."
        }

        // Find the middle index
        const middleIndex = arr.length / 2

        // Divide the array into two halves
        const firstHalf = arr.slice(0, middleIndex)
        const secondHalf = arr.slice(middleIndex)

        const result = {
            name: "Score",
            type: "bar",
            pointStart: 0,
            data: firstHalf.map((prevIndex, currIndex) => {
                const st = firstHalf[currIndex] * GRAPH_NORMALIZE_FACTOR
                const news = secondHalf[currIndex]
                const y = st + news

                return {
                    y,
                    st,
                    news
                }
            }),
            color: "#43a84a",
            negativeColor: "#e64736"
        }

        return result
    }

    const getSingleChangeGraphData = (data, stock) => {
        const labels = [
            "one_day_st_change_percent",
            "one_week_st_change_percent",
            "two_week_st_change_percent",
            "one_month_st_change_percent",
            "one_day_news_change_percent",
            "one_week_news_change_percent",
            "two_week_news_change_percent",
            "one_month_news_change_percent"
        ]

        const dataByDuration = data.filter((durationData) =>
            labels.includes(durationData.fields)
        )

        if (stock) {
            const stStockDataByDuration = dataByDuration
                .map((durationData) => {
                    if (
                        durationData.fields === "one_day_st_change_percent" ||
                        durationData.fields === "one_week_st_change_percent" ||
                        durationData.fields === "two_week_st_change_percent" ||
                        durationData.fields === "one_month_st_change_percent"
                    ) {
                        return mapStockDataByDuration(durationData, stock)
                    }
                })
                .filter((item) => item === 0 || item)

            const newsStockDataByDuration = dataByDuration
                .map((durationData) => {
                    if (
                        durationData.fields === "one_day_news_change_percent" ||
                        durationData.fields ===
                            "one_week_news_change_percent" ||
                        durationData.fields ===
                            "two_week_news_change_percent" ||
                        durationData.fields === "one_month_news_change_percent"
                    ) {
                        return mapStockDataByDuration(durationData, stock)
                    }
                })
                .filter((item) => item === 0 || item)

            return [...stStockDataByDuration, ...newsStockDataByDuration]
        }

        return dataByDuration
    }

    const fetchGraphData = async (payload, graphType, stateKey) => {
        const response = await getGraphData({
            ...payload,
            graph_type: graphType
        })

        setGraphData((prevGraphData) => ({
            ...prevGraphData,
            [stateKey]: response
        }))

        return response
    }

    const findSeries = (Sname, data, changeFlag) => {
        let data1 = null
        let data2 = null
        let data3 = null
        let data4 = null
        if (changeFlag) {
            data1 = data.find((o) => o.fields === Sname + "_st_change_percent")
            data2 = data.find(
                (o) => o.fields === Sname + "_news_change_percent"
            )
            data3 = data.find((o) => o.fields === Sname + "_st_change")
            data4 = data.find((o) => o.fields === Sname + "_news_change")
            return { news: data2, st: data1, newsAbs: data4, stAbs: data3 }
        } else {
            data1 = data.find((o) => o.fields === Sname + "_st")
            data2 = data.find((o) => o.fields === Sname + "_news")

            return { news: data2, st: data1 }
        }
    }

    const processAndDrawBuzzGraph = useCallback(
        (response) => {
            const temp = findSeries(graphViews.buzz, response, 0, "buzz")

            const tickerList = sortSeries(temp["news"], temp["st"], "buzz")

            const currentTickerList = graphDataCount.buzz
                ? tickerList.all
                : tickerList.top

            const arr1 = getSeriesTwo(
                temp["news"],
                temp["st"],
                currentTickerList
            )

            drawBuzzGraph(arr1, currentTickerList, response)
        },
        [graphViews.buzz, graphDataCount.buzz, tickers]
    )

    // Draw Graphs
    const drawBuzzGraph = (arrB, tickerList, response) => {
        setBuzzGraph(() => {
            return {
                title: {
                    text: "Trending stocks by Social Buzz"
                },
                chart: {
                    type: "bar",
                    borderColor: "#c0c0c0",
                    borderWidth: 1.25,
                    borderRadius: 5,
                    height: 550
                },
                lang: {
                    thousandsSep: ","
                },
                colors: ["#43a84a", "#f5d561"],
                legend: {
                    enabled: false,
                    title: {
                        text: "",
                        style: {
                            fontStyle: "italic"
                        }
                    }
                },
                plotOptions: {
                    series: {
                        stacking: "normal",
                        cursor: "pointer",
                        events: {
                            click: function (event) {
                                getSingleBuzzGraphView(
                                    event.point,
                                    tickerList,
                                    arrB,
                                    response
                                )
                            }
                        }
                    }
                },
                credits: {
                    text: "finsoftai.com",
                    href: 'javascript:window.open("http://www.finsoftai.com/", "_blank")',
                    style: {
                        fontSize: 14,
                        textDecoration: "underline",
                        color: "#0248b3"
                    },
                    position: {
                        align: "right",
                        x: -10
                    }
                },
                stockTools: {
                    gui: {
                        enabled: false
                    }
                },
                xAxis: [
                    {
                        title: {
                            text: "Tickers",
                            style: {
                                fontWeight: "bold",
                                fontSize: "17px",
                                fontFamily: "Poppins"
                            }
                        },
                        categories: tickerList,
                        labels: {
                            style: {
                                fontFamily: "Poppins",
                                fontWeight: "bold",
                                color: "#A8A8A8"
                            }
                        }
                    }
                ],
                yAxis: [
                    {
                        title: {
                            text: "Average Social Buzz",
                            style: {
                                fontWeight: "bold",
                                fontSize: "17px",
                                fontFamily: "Poppins"
                            }
                        }
                    }
                ],
                exporting: {
                    buttons: {
                        contextButton: {
                            menuItems: ["viewFullscreen"]
                        }
                    }
                },
                tooltip: {
                    useHTML: true,
                    headerFormat:
                        '<div style="text-align:center;">  {point.x:,.0f} </div>',
                    thousandsSep: ",",
                    pointFormat:
                        '<div style="text-align:center;"><b>Buzz volume: {point.y:,.1f}</b><p>Social Media:{point.st:,.2f} | News:{point.news:,.2f} </p></div>'
                },
                series: arrB
            }
        })
    }

    const drawSentimentGraph = (arrB, tickerList, response) => {
        setSentiGraph(() => {
            return {
                title: {
                    text: "Trending stocks by Sentiment"
                },
                chart: {
                    type: "bar",
                    borderColor: "#c0c0c0",
                    borderWidth: 1.25,
                    borderRadius: 5,
                    height: 550
                },
                colors: ["#43a84a", "#f5d561"],
                legend: {
                    enabled: false
                },
                plotOptions: {
                    series: {
                        stacking: "normal",
                        cursor: "pointer",
                        events: {
                            click: function (event) {
                                getSingleSentimentGraphView(
                                    event.point,
                                    tickerList,
                                    arrB,
                                    response
                                )
                            }
                        }
                    }
                },
                credits: {
                    text: "finsoftai.com",
                    href: 'javascript:window.open("http://www.finsoftai.com/", "_blank")',
                    style: {
                        fontSize: 14,
                        textDecoration: "underline",
                        color: "#0248b3"
                    },
                    position: {
                        align: "right",
                        x: -10
                    }
                },
                stockTools: {
                    gui: {
                        enabled: false
                    }
                },
                xAxis: [
                    {
                        title: {
                            text: "Tickers",
                            style: {
                                fontWeight: "bold",
                                fontSize: "15px",
                                fontFamily: "Poppins"
                            }
                        },
                        categories: tickerList,
                        // plotLines: [
                        //     {
                        //         value: graphDataCount.sentiment ? null : 4.5,
                        //         width: 1,
                        //         color: "#aaa",
                        //         zIndex: 10
                        //     }
                        // ],
                        labels: {
                            style: {
                                fontFamily: "Poppins",
                                fontWeight: "bold",
                                color: "#A8A8A8"
                            }
                        }
                    }
                ],
                // legend:{enabled:false},
                yAxis: [
                    {
                        title: {
                            text: "Average Social Sentiment Score",
                            style: {
                                fontWeight: "bold",
                                fontSize: "15px",
                                fontFamily: "Poppins"
                            }
                        }
                    }
                ],
                exporting: {
                    buttons: {
                        contextButton: {
                            menuItems: ["viewFullscreen"]
                        }
                    }
                },
                tooltip: {
                    useHTML: true,
                    headerFormat:
                        '<div style="text-align:center;">  {point.x:,.0f} </div>',
                    thousandsSep: ",",
                    pointFormat:
                        '<div style="text-align:center;"><b>Social Sentiment: {point.y:,.3f}</b><p>Social Media:{point.st:,.3f} | News:{point.news:,.3f} </p></div>'
                    // valueDecimal:1,
                    // valueDecimal:1,
                },
                series: arrB
            }
        })
    }

    const drawBuzzChangeGraph = (arrBTWO, tickerList, response) => {
        setBuzzGraphChange(() => {
            return {
                title: {
                    text: "Trending stocks by Change in Social Buzz"
                },
                chart: {
                    type: "bar",
                    borderColor: "#c0c0c0",
                    borderWidth: 1.25,
                    borderRadius: 5,
                    styledmode: "on",
                    height: 550
                },
                plotOptions: {
                    series: {
                        stacking: "normal",
                        cursor: "pointer",
                        events: {
                            click: function (event) {
                                getSingleBuzzChangeGraphView(
                                    event.point,
                                    tickerList,
                                    arrBTWO,
                                    response
                                )
                            }
                        }
                    }
                },
                legend: {
                    enabled: false
                },
                colors: ["#43a84a", "#f5d561"],
                lang: {
                    thousandsSep: ","
                },
                credits: {
                    text: "finsoftai.com",
                    href: 'javascript:window.open("http://www.finsoftai.com/", "_blank")',
                    style: {
                        fontSize: 14,
                        textDecoration: "underline",
                        color: "#0248b3"
                    },
                    position: {
                        align: "right",
                        x: -10
                    }
                },
                stockTools: {
                    gui: {
                        enabled: false
                    }
                },
                xAxis: [
                    {
                        title: {
                            text: "Tickers",
                            style: {
                                fontWeight: "bold",
                                fontSize: "15px",
                                fontFamily: "Poppins"
                            }
                        },
                        categories: tickerList,
                        // plotLines: [
                        //     {
                        //         value: graphDataCount.changeBuzz ? null : 4.5,
                        //         width: 1,
                        //         color: "#aaa",
                        //         zIndex: 10
                        //     }
                        // ],
                        labels: {
                            style: {
                                fontFamily: "Poppins",
                                fontWeight: "bold",
                                color: "#A8A8A8"
                            }
                        }
                    }
                ],
                // legend:{enabled:false},
                yAxis: [
                    {
                        title: {
                            text: "Average Change in Social Buzz",
                            style: {
                                fontWeight: "bold",
                                fontSize: "15px",
                                fontFamily: "Poppins"
                            }
                        },
                        labels: {
                            formatter: function () {
                                return this.value + "%"
                            }
                        }
                    }
                ],
                exporting: {
                    buttons: {
                        contextButton: {
                            menuItems: ["viewFullscreen"]
                        }
                    }
                },

                tooltip: {
                    useHTML: true,
                    headerFormat:
                        '<div style="text-align:center;">  {point.x:,.0f} </div>',
                    thousandsSep: ",",
                    pointFormat:
                        '<div style="text-align:center;"><b>Change in Buzz volume : {point.y:,.2f}%</b><p>Social Media : {point.stPercent:,.2f}% | News : {point.newsPercent:,.2f}%</p></div>', // <br></br>Social Media value: {point.stAbs:,.2f} | News value: {point.newsAbs:,.2f}
                    // valueDecimal:1,
                    padding: 4
                },
                series: arrBTWO
            }
        })
    }

    const drawSentimentChangeGraph = (arrB, tickerList, response) => {
        setSentiGraphChange(() => {
            return {
                title: {
                    text: "Trending stocks by Change in Sentiment"
                },
                chart: {
                    type: "bar",
                    borderColor: "#c0c0c0",
                    borderWidth: 1.25,
                    borderRadius: 5,
                    styledmode: "on",
                    height: 550
                },
                plotOptions: {
                    series: {
                        stacking: "normal",
                        cursor: "pointer",
                        events: {
                            click: function (event) {
                                getSingleSentimentChangeGraphView(
                                    event.point,
                                    tickerList,
                                    arrB,
                                    response
                                )
                            }
                        }
                    }
                },
                legend: {
                    enabled: false
                },
                lang: {
                    thousandsSep: ","
                },
                credits: {
                    text: "finsoftai.com",
                    href: 'javascript:window.open("http://www.finsoftai.com/", "_blank")',
                    style: {
                        fontSize: 14,
                        textDecoration: "underline",
                        color: "#0248b3"
                    },
                    position: {
                        align: "right",
                        x: -10
                    }
                },
                stockTools: {
                    gui: {
                        enabled: false
                    }
                },
                xAxis: [
                    {
                        title: {
                            text: "Tickers",
                            style: {
                                fontWeight: "bold",
                                fontSize: "15px",
                                fontFamily: "Poppins"
                            }
                        },
                        categories: tickerList,
                        // plotLines: [
                        //     {
                        //         value: graphDataCount.changeSentiment
                        //             ? null
                        //             : 4.5,
                        //         width: 1,
                        //         color: "#aaa",
                        //         zIndex: 10
                        //     }
                        // ],
                        labels: {
                            style: {
                                fontFamily: "Poppins",
                                fontWeight: "bold",
                                color: "#A8A8A8"
                            }
                        }
                    }
                ],
                // legend:{enabled:false},
                yAxis: [
                    {
                        title: {
                            text: "Average Change in Social Sentiment Score",
                            style: {
                                fontWeight: "bold",
                                fontSize: "15px",
                                fontFamily: "Poppins"
                            }
                        },
                        labels: {
                            formatter: function () {
                                return this.value + "%"
                            }
                        }
                    }
                ],
                exporting: {
                    buttons: {
                        contextButton: {
                            menuItems: ["viewFullscreen"],
                            className: "step-four"
                        }
                    }
                },
                tooltip: {
                    useHTML: true,
                    headerFormat:
                        '<div style="text-align:center;">  {point.x:,.0f} </div>',
                    thousandsSep: ",",
                    pointFormat:
                        '<div style="text-align:center;"><b>Change in Social Sentiment : {point.y:,.2f}%</b><p>Social Media : {point.stPercent:,.2f}% | News : {point.newsPercent:,.2f}%</p></div>' //<br></br>Social Media value: {point.stAbs:,.3f} | News value: {point.newsAbs:,.3f}
                    // valueDecimal:1,
                    // valueDecimal:1,
                },
                series: arrB
            }
        })
    }

    // Get Single Buzz Graph View
    // 1.
    const getSingleBuzzGraphView = (
        { category },
        tickerList,
        arrB,
        response
    ) => {
        const stockDataByDuration = getSingleAbsoluteGraphData(
            response,
            category
        )

        const singleGraphData = getSingleBuzzGraphData(stockDataByDuration)

        setBuzzGraph(() => {
            return {
                title: {
                    text: "Trending stocks by Social Buzz"
                },
                chart: {
                    type: "bar",
                    borderColor: "#c0c0c0",
                    borderWidth: 1.25,
                    borderRadius: 5
                },
                lang: {
                    thousandsSep: ","
                },
                colors: ["#43a84a", "#f5d561"],
                legend: {
                    enabled: false,
                    title: {
                        text: "",
                        style: {
                            fontStyle: "italic"
                        }
                    }
                },
                plotOptions: {
                    series: {
                        stacking: "normal",
                        cursor: "pointer",
                        events: {
                            click: function () {
                                drawBuzzGraph(arrB, tickerList, response)
                            }
                        }
                    }
                },
                credits: {
                    text: "finsoftai.com",
                    href: 'javascript:window.open("http://www.finsoftai.com/", "_blank")',
                    style: {
                        fontSize: 14,
                        textDecoration: "underline",
                        color: "#0248b3"
                    },
                    position: {
                        align: "right",
                        x: -10
                    }
                },
                stockTools: {
                    gui: {
                        enabled: false
                    }
                },
                xAxis: [
                    {
                        title: {
                            text: category,
                            style: {
                                fontWeight: "bold",
                                fontSize: "17px",
                                fontFamily: "Poppins"
                            }
                        },
                        categories: SINGLE_GRAPH_DURATION,
                        labels: {
                            style: {
                                fontFamily: "Poppins",
                                fontWeight: "bold",
                                color: "#A8A8A8"
                            }
                        }
                    }
                ],

                yAxis: [
                    {
                        title: {
                            text: "Average Social Buzz",
                            style: {
                                fontWeight: "bold",
                                fontSize: "17px",
                                fontFamily: "Poppins"
                            }
                        }
                    }
                ],
                exporting: {
                    buttons: {
                        contextButton: {
                            menuItems: ["viewFullscreen"]
                        }
                    }
                },
                tooltip: {
                    useHTML: true,
                    headerFormat:
                        '<div style="text-align:center;">  {point.x:,.0f} </div>',
                    thousandsSep: ",",
                    pointFormat:
                        '<div style="text-align:center;"><b>Buzz volume: {point.y:,.1f}</b><p>Social Media:{point.st:,.2f} | News:{point.news:,.2f} </p></div>'
                },

                series: singleGraphData
            }
        })
    }

    // 2.
    const getSingleSentimentGraphView = (
        { category },
        tickerList,
        arrB,
        response
    ) => {
        const stockDataByDuration = getSingleAbsoluteGraphData(
            response,
            category
        )

        const singleGraphData = {
            name: "Score",
            type: "bar",
            pointStart: 0,
            data: stockDataByDuration
                .slice(0, stockDataByDuration.length / 2)
                .map((prevIndex, currIndex) => ({
                    y:
                        (prevIndex +
                            stockDataByDuration.slice(
                                stockDataByDuration.length / 2
                            )[currIndex]) /
                        2,
                    st: prevIndex,
                    news: stockDataByDuration.slice(
                        stockDataByDuration.length / 2
                    )[currIndex]
                })),
            color: "#43a84a",
            negativeColor: "#e64736"
        }

        setSentiGraph(() => {
            return {
                title: {
                    text: "Trending stocks by Sentiment"
                },
                chart: {
                    type: "bar",
                    borderColor: "#c0c0c0",
                    borderWidth: 1.25,
                    borderRadius: 5
                },
                legend: {
                    enabled: false
                },
                plotOptions: {
                    series: {
                        stacking: "normal",
                        cursor: "pointer",
                        events: {
                            click: function (event) {
                                drawSentimentGraph(arrB, tickerList, response)
                            }
                        }
                    }
                    // visible: false,
                },
                credits: {
                    text: "finsoftai.com",
                    href: 'javascript:window.open("http://www.finsoftai.com/", "_blank")',
                    style: {
                        fontSize: 14,
                        textDecoration: "underline",
                        color: "#0248b3"
                    },
                    position: {
                        align: "right",
                        x: -10
                    }
                },
                stockTools: {
                    gui: {
                        enabled: false
                    }
                },
                xAxis: [
                    {
                        title: {
                            text: category,
                            style: {
                                fontWeight: "bold",
                                fontSize: "15px",
                                fontFamily: "Poppins"
                            }
                        },
                        categories: SINGLE_GRAPH_DURATION,
                        // plotLines: [
                        //     {
                        //         value: graphDataCount.sentiment ? null : 4.5,
                        //         width: 1,
                        //         color: "#aaa",
                        //         zIndex: 10
                        //     }
                        // ],
                        labels: {
                            style: {
                                fontFamily: "Poppins",
                                fontWeight: "bold",
                                color: "#A8A8A8"
                            }
                        }
                    }
                ],
                // legend:{enabled:false},
                yAxis: [
                    {
                        title: {
                            text: "Average Social Sentiment Score",
                            style: {
                                fontWeight: "bold",
                                fontSize: "15px",
                                fontFamily: "Poppins"
                            }
                        }
                    }
                ],
                exporting: {
                    buttons: {
                        contextButton: {
                            menuItems: ["viewFullscreen"]
                        }
                    }
                },
                tooltip: {
                    useHTML: true,
                    headerFormat:
                        '<div style="text-align:center;">  {point.x:,.0f} </div>',
                    thousandsSep: ",",
                    pointFormat:
                        '<div style="text-align:center;"><b>Social Sentiment: {point.y:,.3f}</b><p>Social Media:{point.st:,.3f} | News:{point.news:,.3f} </p></div>'
                    // valueDecimal:1,
                    // valueDecimal:1,
                },
                series: singleGraphData
            }
        })
    }

    // 3.
    const getSingleBuzzChangeGraphView = (
        { category },
        tickerList,
        arrB,
        response
    ) => {
        const stockDataByDuration = getSingleChangeGraphData(response, category)

        const singleGraphData = {
            name: "Score",
            type: "bar",
            pointStart: 0,
            data: stockDataByDuration
                .slice(0, stockDataByDuration.length / 2)
                .map((prevIndex, currIndex) => ({
                    y:
                        (prevIndex +
                            stockDataByDuration.slice(
                                stockDataByDuration.length / 2
                            )[currIndex]) /
                        2,
                    stPercent: prevIndex,
                    newsPercent: stockDataByDuration.slice(
                        stockDataByDuration.length / 2
                    )[currIndex]
                })),
            color: "#43a84a",
            negativeColor: "#e64736"
        }

        setBuzzGraphChange(() => {
            return {
                title: {
                    text: "Trending stocks by Change in Social Buzz"
                },
                chart: {
                    type: "bar",
                    borderColor: "#c0c0c0",
                    borderWidth: 1.25,
                    borderRadius: 5,
                    styledmode: "on"
                },
                plotOptions: {
                    series: {
                        stacking: "normal",
                        cursor: "pointer",
                        events: {
                            click: function (event) {
                                drawBuzzChangeGraph(arrB, tickerList, response)
                            }
                        }
                    }
                },
                legend: {
                    enabled: false
                },
                colors: ["#43a84a", "#f5d561"],
                lang: {
                    thousandsSep: ","
                },
                credits: {
                    text: "finsoftai.com",
                    href: 'javascript:window.open("http://www.finsoftai.com/", "_blank")',
                    style: {
                        fontSize: 14,
                        textDecoration: "underline",
                        color: "#0248b3"
                    },
                    position: {
                        align: "right",
                        x: -10
                    }
                },
                stockTools: {
                    gui: {
                        enabled: false
                    }
                },
                xAxis: [
                    {
                        title: {
                            text: category,
                            style: {
                                fontWeight: "bold",
                                fontSize: "15px",
                                fontFamily: "Poppins"
                            }
                        },
                        categories: SINGLE_GRAPH_DURATION,
                        // plotLines: [
                        //     {
                        //         value: graphDataCount.changeBuzz ? null : 4.5,
                        //         width: 1,
                        //         color: "#aaa",
                        //         zIndex: 10
                        //     }
                        // ],
                        labels: {
                            style: {
                                fontFamily: "Poppins",
                                fontWeight: "bold",
                                color: "#A8A8A8"
                            }
                        }
                    }
                ],
                // legend:{enabled:false},
                yAxis: [
                    {
                        title: {
                            text: "Average Change in Social Buzz",
                            style: {
                                fontWeight: "bold",
                                fontSize: "15px",
                                fontFamily: "Poppins"
                            }
                        },
                        labels: {
                            formatter: function () {
                                return this.value + "%"
                            }
                        }
                    }
                ],
                exporting: {
                    buttons: {
                        contextButton: {
                            menuItems: ["viewFullscreen"]
                        }
                    }
                },

                tooltip: {
                    useHTML: true,
                    headerFormat:
                        '<div style="text-align:center;">  {point.x:,.0f} </div>',
                    thousandsSep: ",",
                    pointFormat:
                        '<div style="text-align:center;"><b>Change in Buzz volume : {point.y:,.2f}%</b><p>Social Media : {point.stPercent:,.2f}% | News : {point.newsPercent:,.2f}%</p></div>', // <br></br>Social Media value: {point.stAbs:,.2f} | News value: {point.newsAbs:,.2f}
                    // valueDecimal:1,
                    padding: 4
                },
                series: singleGraphData
            }
        })
    }

    // 4.
    const getSingleSentimentChangeGraphView = (
        { category },
        tickerList,
        arrB,
        response
    ) => {
        const stockDataByDuration = getSingleChangeGraphData(response, category)

        const singleGraphData = {
            name: "Score",
            type: "bar",
            pointStart: 0,
            data: stockDataByDuration
                .slice(0, stockDataByDuration.length / 2)
                .map((prevIndex, currIndex) => ({
                    y:
                        (prevIndex +
                            stockDataByDuration.slice(
                                stockDataByDuration.length / 2
                            )[currIndex]) /
                        2,
                    stPercent: prevIndex,
                    newsPercent: stockDataByDuration.slice(
                        stockDataByDuration.length / 2
                    )[currIndex]
                })),
            color: "#43a84a",
            negativeColor: "#e64736"
        }

        setSentiGraphChange(() => {
            return {
                title: {
                    text: "Trending stocks by Change in Sentiment"
                },
                chart: {
                    type: "bar",
                    borderColor: "#c0c0c0",
                    borderWidth: 1.25,
                    borderRadius: 5,
                    styledmode: "on"
                },
                plotOptions: {
                    series: {
                        stacking: "normal",
                        cursor: "pointer",
                        events: {
                            click: function (event) {
                                drawSentimentChangeGraph(
                                    arrB,
                                    tickerList,
                                    response
                                )
                            }
                        }
                    }
                },
                legend: {
                    enabled: false
                },
                colors: ["#43a84a", "#f5d561"],
                lang: {
                    thousandsSep: ","
                },
                credits: {
                    text: "finsoftai.com",
                    href: 'javascript:window.open("http://www.finsoftai.com/", "_blank")',
                    style: {
                        fontSize: 14,
                        textDecoration: "underline",
                        color: "#0248b3"
                    },
                    position: {
                        align: "right",
                        x: -10
                    }
                },
                stockTools: {
                    gui: {
                        enabled: false
                    }
                },
                xAxis: [
                    {
                        title: {
                            text: category,
                            style: {
                                fontWeight: "bold",
                                fontSize: "15px",
                                fontFamily: "Poppins"
                            }
                        },
                        categories: SINGLE_GRAPH_DURATION,
                        // plotLines: [
                        //     {
                        //         value: graphDataCount.changeBuzz ? null : 4.5,
                        //         width: 1,
                        //         color: "#aaa",
                        //         zIndex: 10
                        //     }
                        // ],
                        labels: {
                            style: {
                                fontFamily: "Poppins",
                                fontWeight: "bold",
                                color: "#A8A8A8"
                            }
                        }
                    }
                ],
                yAxis: [
                    {
                        title: {
                            text: "Average Change in Social Sentiment Score",
                            style: {
                                fontWeight: "bold",
                                fontSize: "15px",
                                fontFamily: "Poppins"
                            }
                        },
                        labels: {
                            formatter: function () {
                                return this.value + "%"
                            }
                        }
                    }
                ],
                exporting: {
                    buttons: {
                        contextButton: {
                            menuItems: ["viewFullscreen"]
                        }
                    }
                },

                tooltip: {
                    useHTML: true,
                    headerFormat:
                        '<div style="text-align:center;">  {point.x:,.0f} </div>',
                    thousandsSep: ",",
                    pointFormat:
                        '<div style="text-align:center;"><b>Change in Buzz volume : {point.y:,.2f}%</b><p>Social Media : {point.stPercent:,.2f}% | News : {point.newsPercent:,.2f}%</p></div>', // <br></br>Social Media value: {point.stAbs:,.2f} | News value: {point.newsAbs:,.2f}
                    // valueDecimal:1,
                    padding: 4
                },
                series: singleGraphData
            }
        })
    }

    // Function to process data and draw the sentiment graph
    const processAndDrawSentimentGraph = useCallback(
        (response) => {
            const temp = findSeries(
                graphViews.sentiment,
                response,
                0,
                "sentiment"
            )

            const tickerList = sortSeries(temp["news"], temp["st"], "sentiment")

            const currentTickerList = graphDataCount.sentiment
                ? tickerList.all
                : tickerList.top

            const arr1 = getSeries(currentTickerList, temp["news"], temp["st"])

            drawSentimentGraph(arr1, currentTickerList, response)
        },
        [graphViews.sentiment, graphDataCount.sentiment, tickers]
    )

    // Function to process data and draw the buzz change graph
    const processAndDrawBuzzChangeGraph = useCallback(
        (response) => {
            const data = findSeries(
                graphViews.changeBuzz,
                response,
                1,
                "buzz_change"
            )

            const tickerList = sortSeries(
                data["news"],
                data["st"],
                "buzzChange"
            )

            const currentTickerList = graphDataCount.changeBuzz
                ? tickerList.all
                : tickerList.top

            const arr1 = getSeries(
                currentTickerList,
                data["news"],
                data["st"],
                data["newsAbs"],
                data["stAbs"]
            )

            drawBuzzChangeGraph(arr1, currentTickerList, response)
        },
        [graphViews.changeBuzz, graphDataCount.changeBuzz, tickers]
    )

    // Function to process data and draw the sentiment change graph
    const processAndDrawSentimentChangeGraph = useCallback(
        (response) => {
            const data = findSeries(graphViews.changeSentiment, response, 1)

            const tickerList = sortSeries(
                data["news"],
                data["st"],
                "sentimentChange"
            )

            const currentTickerList = graphDataCount.changeSentiment
                ? tickerList.all
                : tickerList.top

            const arr1 = getSeries(
                currentTickerList,
                data["news"],
                data["st"],
                data["newsAbs"],
                data["stAbs"]
            )

            drawSentimentChangeGraph(arr1, currentTickerList, response)
        },
        [graphViews.changeSentiment, graphDataCount.changeSentiment, tickers]
    )

    const getGraphs = async () => {
        if (!isLoading) {
            setIsLoading(true)

            const date = formatDayjs(graphDateTime)

            const payload = {
                date,
                marketStatusDate: date,
                marketStatusKey: marketStatusKey.value
            }

            let apiResponse = {}

            const buzzGraphData = await fetchGraphData(
                payload,
                "social_buzz",
                "buzz"
            )
            processAndDrawBuzzGraph(buzzGraphData)
            apiResponse = { ...apiResponse, buzz: buzzGraphData }

            const sentimentGraphData = await fetchGraphData(
                payload,
                "sentiment",
                "sentiment"
            )
            processAndDrawSentimentGraph(sentimentGraphData)
            apiResponse = { ...apiResponse, sentiment: sentimentGraphData }

            const buzzChangeGraphData = await fetchGraphData(
                payload,
                "social_buzz_change",
                "changeBuzz"
            )
            processAndDrawBuzzChangeGraph(buzzChangeGraphData)
            apiResponse = { ...apiResponse, changeBuzz: buzzChangeGraphData }

            const sentimentChangeGraphData = await fetchGraphData(
                payload,
                "sentiment_change",
                "changeSentiment"
            )
            processAndDrawSentimentChangeGraph(sentimentChangeGraphData)
            apiResponse = {
                ...apiResponse,
                changeSentiment: sentimentChangeGraphData
            }

            await renderAlerts(apiResponse)
        }

        setIsLoading(false)
    }

    useGraphUpdate(graphData, graphViews, "buzz", processAndDrawBuzzGraph)
    useGraphUpdate(
        graphData,
        graphViews,
        "sentiment",
        processAndDrawSentimentGraph
    )
    useGraphUpdate(
        graphData,
        graphViews,
        "changeBuzz",
        processAndDrawBuzzChangeGraph
    )

    useGraphUpdate(
        graphData,
        graphViews,
        "changeSentiment",
        processAndDrawSentimentChangeGraph
    )

    useEffect(() => {
        getGraphs()
    }, [graphDateTime, marketStatusKey, graphDataCount])

    return (
        <>
            <div className='flex items-center justify-between my-2 step-one'>
                <div className='flex flex-col gap-2'>
                    <div className='flex gap-1 flex-col'>
                        <h3
                            className='transition-modal-description'
                            style={{ textAlign: "left" }}>
                            Trending Dashboards
                        </h3>
                        <div className='flex gap-2 items-center'>
                            <p className='m-0'>* As on Morning (Pre-Market):</p>
                            <Button
                                variant='text'
                                className='text-gray-500 text-lg border'
                                size='medium'
                                onClick={() => {
                                    setDateChangeModal(() => {
                                        return true
                                    })
                                }}>
                                {displayDate}
                            </Button>
                        </div>
                        <Modal
                            aria-labelledby='transition-modal-title'
                            aria-describedby='transition-modal-description'
                            className={classes.modal}
                            open={dateChangeModal}
                            onClose={() => {
                                setDateChangeModal(() => {
                                    return false
                                })
                            }}
                            closeAfterTransition
                            BackdropComponent={Backdrop}
                            BackdropProps={{
                                timeout: 500
                            }}
                            style={{
                                left: "35%",
                                marginTop: "3%"
                            }}>
                            <div
                                classes='container-fluid '
                                className={classes.paper}
                                style={{ maxWidth: "50%" }}>
                                <div className='row'>
                                    <h2
                                        className='col-10 transition-modal-description'
                                        style={{
                                            textAlign: "left"
                                        }}>
                                        Set ET Date & Time
                                    </h2>
                                    <div className='row'>
                                        <LocalizationProvider
                                            dateAdapter={AdapterDayjs}>
                                            <DemoContainer
                                                components={["DateTimePicker"]}>
                                                <DateTimePicker
                                                    timezone='America/New_York'
                                                    value={graphDateTime}
                                                    onChange={(newValue) => {
                                                        checkFutureData(
                                                            newValue
                                                        )
                                                    }}
                                                    views={[
                                                        "year",
                                                        "month",
                                                        "day"
                                                    ]}
                                                />
                                            </DemoContainer>
                                        </LocalizationProvider>
                                    </div>
                                    <div className='row justify-content-center mt-3'>
                                        <Button
                                            className='col-md-4 confirm-btn'
                                            style={{
                                                backgroundColor: "#0d6efd",
                                                color: "white"
                                            }}
                                            size='small'
                                            variant='contained'
                                            onClick={handleGetGraphWithDate}>
                                            Confirm
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </Modal>
                    </div>
                </div>
                <div className='flex flex-col items-center gap-1'>
                    <p className='text-base font-bold'>Market timing Mode</p>
                    <Switcher
                        items={marketStatusItems}
                        onSelect={selectMarketStatus}
                        selected={marketStatusKey}
                    />
                </div>
                <div className='flex items-center self-end gap-6'>
                    <a
                        href='https://chatgpt.com/g/g-67a40bd4bab48191ad62319ec3b0bfad-ssi-social-sentiment-insight-ai-assistant'
                        target='_blank'
                        rel='noreferrer'>
                        <Button
                            variant='contained'
                            style={{
                                backgroundColor: "rgb(75, 143, 205)",
                                color: "white",
                                fontSize: 16,
                                textTransform: "none" // Prevent text transformation
                            }}
                            size='medium'
                            disableElevation // Disable button elevation
                        >
                            Ask SSi Bot
                        </Button>
                    </a>
                    <div>
                        <IconButton
                            variant='outlined'
                            onClick={() => {
                                setGraphToggle(false)
                            }}>
                            <CloseIcon />
                        </IconButton>
                    </div>
                </div>
            </div>
            <div className='row items-center justify-center font-poppins text-lg font-normal'>
                <div className='col-md-6 mt-2'>
                    <FullScreen handle={screen1} onChange={getGraphs}>
                        <Card
                            style={
                                screen1.active
                                    ? {
                                          borderRadius: 20,
                                          backgroundColor: "#f4f4f4",
                                          height: "100%",
                                          overflowY: "scroll"
                                      }
                                    : {
                                          borderRadius: 20,
                                          backgroundColor:
                                              "rgba(229, 229, 229, 0.42)"
                                      }
                            }>
                            <CardHeader
                                title={
                                    <div
                                        className='row ml-2'
                                        style={{
                                            padding: "0px !important",
                                            paddingTop: 5,
                                            paddingBottom: 5
                                        }}>
                                        <h5 className='col-md-6'>
                                            Trending stocks by Social Buzz
                                        </h5>
                                    </div>
                                }
                            />
                            <CardContent className='step-five'>
                                {/* {} */}
                                {topBar(1)}
                                <HighchartsReact
                                    highcharts={Highcharts}
                                    options={buzzGraph}
                                />
                            </CardContent>
                        </Card>
                    </FullScreen>
                </div>
                <div className='col-md-6 mt-2'>
                    <FullScreen handle={screen2} onChange={getGraphs}>
                        <Card
                            style={
                                screen2.active
                                    ? {
                                          borderRadius: 20,
                                          backgroundColor: "#f4f4f4",
                                          height: "100%",
                                          overflowY: "scroll"
                                      }
                                    : {
                                          borderRadius: 20,
                                          backgroundColor:
                                              "rgba(229, 229, 229, 0.42)"
                                      }
                            }>
                            <CardHeader
                                title={
                                    <div
                                        className='row ml-2'
                                        style={{
                                            padding: "0px !important",
                                            paddingTop: 5,
                                            paddingBottom: 5
                                        }}>
                                        <h5 className='col-md-6'>
                                            Trending stocks by Sentiment
                                        </h5>
                                    </div>
                                }
                            />
                            <CardContent>
                                {topBar(2)}

                                <HighchartsReact
                                    highcharts={Highcharts}
                                    options={sentiGraph}
                                />
                            </CardContent>
                        </Card>
                    </FullScreen>
                </div>
                <div className='col-md-6 mt-2'>
                    <FullScreen handle={screen3} onChange={getGraphs}>
                        <Card
                            style={
                                screen3.active
                                    ? {
                                          borderRadius: 20,
                                          backgroundColor: "#f4f4f4",
                                          height: "100%",
                                          overflowY: "scroll"
                                      }
                                    : {
                                          borderRadius: 20,
                                          backgroundColor:
                                              "rgba(229, 229, 229, 0.42)"
                                      }
                            }>
                            <CardHeader
                                title={
                                    <div
                                        className='row ml-2'
                                        style={{
                                            padding: "0px !important",
                                            paddingTop: 5,
                                            paddingBottom: 1
                                        }}>
                                        <h5 className='col-md-12'>
                                            Trending stocks by Change in Social
                                            Buzz
                                        </h5>
                                    </div>
                                }
                            />
                            <CardContent>
                                {topBar(3)}
                                <HighchartsReact
                                    highcharts={Highcharts}
                                    options={buzzGraphChange}
                                />
                            </CardContent>
                        </Card>
                    </FullScreen>
                </div>
                <div className='col-md-6 mt-2 step-two'>
                    <FullScreen handle={screen4} onChange={getGraphs}>
                        <Card
                            style={
                                screen4.active
                                    ? {
                                          borderRadius: 20,
                                          backgroundColor: "#f4f4f4",
                                          height: "100%",
                                          overflowY: "scroll"
                                      }
                                    : {
                                          borderRadius: 20,
                                          backgroundColor:
                                              "rgba(229, 229, 229, 0.42)"
                                      }
                            }>
                            <CardHeader
                                title={
                                    <div
                                        className='row ml-2'
                                        style={{
                                            padding: "0px !important",
                                            paddingTop: 5,
                                            paddingBottom: 5
                                        }}>
                                        <h5 className='col-md-12'>
                                            Trending stocks by Change in
                                            Sentiment
                                        </h5>
                                    </div>
                                }
                            />
                            <CardContent>
                                <div className='step-three'>{topBar(4)}</div>
                                <HighchartsReact
                                    highcharts={Highcharts}
                                    options={sentiGraphChange}
                                />
                            </CardContent>
                        </Card>
                    </FullScreen>
                </div>
                <div>
                    <Wordcloud
                        tickers={tickers}
                        date={formatDayjs(graphDateTime)}
                    />
                </div>
            </div>
        </>
    )
}

export default StockViewDashboard
