import React, { useEffect, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import { auth, logInWithEmailAndPassword, signInWithGoogle } from "./firebase"
import { useAuthState } from "react-firebase-hooks/auth"
import "./static/Login.css"
import Background from "./static/research-login-background.png"
import googleLogo from "./static/google_logo2.png"
import Button from "@mui/material/Button"
import CssBaseline from "@mui/material/CssBaseline"
import Box from "@mui/material/Box"
import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"
import { createTheme, ThemeProvider, styled } from "@mui/material/styles"

const GoogleLogo = styled("img")({
    width: "100%",
    height: "100%",
    borderRadius: "50%"
})

const Copyright = (props) => {
    return (
        <Typography
            variant='body2'
            color='text.secondary'
            align='center'
            {...props}>
            {"Copyright © "}
            <Link color='inherit' href='https://finsoftai.com/'>
                FinSoftAi
            </Link>{" "}
            {new Date().getFullYear()}
            {"."}
        </Typography>
    )
}

const theme = createTheme()

function Login() {
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [user, loading, error] = useAuthState(auth)
    const navigate = useNavigate()

    useEffect(() => {
        if (user) {
            navigate("/board", { replace: true })
        }
    }, [user])

    useEffect(() => {
        console.log("IN login")
    }, [])

    return (
        <ThemeProvider theme={theme}>
            <Grid container component='main' sx={{ height: "100vh" }}>
                <CssBaseline />
                <Grid
                    justifyContent={"flex-end"}
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    sx={{
                        backgroundImage: `url(${Background})`,
                        backgroundRepeat: "no-repeat",
                        backgroundColor: (t) =>
                            t.palette.mode === "light"
                                ? t.palette.grey[50]
                                : t.palette.grey[900],
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        // backgroundColor: 'blue'
                        textAlign: "center"
                    }}>
                    <Box
                        className='login_box'
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "end",
                            height: "100%",
                            width: "100%",
                            backgroundColor: "transparent",
                            pr: "5%"
                        }}>
                        <Box sx={{ mt: 10 }}>
                            <div className='login__container'>
                                <Typography
                                    className='mb-2'
                                    component='h4'
                                    variant='h5'
                                    style={{ color: "#858585" }}>
                                    Sign in
                                </Typography>
                                <input
                                    type='text'
                                    className='login__textBox'
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    placeholder='E-mail Address'
                                />
                                <input
                                    type='password'
                                    className='login__textBox'
                                    value={password}
                                    onChange={(e) =>
                                        setPassword(e.target.value)
                                    }
                                    placeholder='Password'
                                />
                                <button
                                    className='login__btn'
                                    onClick={() =>
                                        logInWithEmailAndPassword(
                                            email,
                                            password
                                        )
                                    }>
                                    Login
                                </button>
                                <div>
                                    <Link to='/reset'>Forgot Password</Link>
                                </div>

                                <div>
                                    New User?{" "}
                                    <Link to='/register'>Sign Up Here</Link>
                                </div>
                                <div>
                                    <hr className='inline-hr' />
                                    <span
                                        style={{
                                            fontSize: 15,
                                            fontWeight: 300,
                                            marginTop: 10,
                                            color: "#858585"
                                        }}>
                                        or login with
                                    </span>
                                    <hr className='inline-hr' />
                                </div>
                                <div
                                    style={{
                                        width: "100%",
                                        textAlign: "center"
                                    }}>
                                    <Button
                                        onClick={signInWithGoogle}
                                        variant='contained'
                                        sx={{
                                            borderRadius: "50%",
                                            minWidth: 0,
                                            p: 1,
                                            m: 1,
                                            boxShadow:
                                                "0px 1px 5px rgba(0, 0, 0, 0.2)",
                                            backgroundColor: "white",
                                            width: 50,
                                            height: 50
                                        }}>
                                        <GoogleLogo
                                            src={googleLogo}
                                            alt='Google'
                                        />
                                    </Button>
                                </div>
                                <Copyright sx={{ mt: 2 }} />
                            </div>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </ThemeProvider>
    )
}
export default Login
