const alertConfig = {
    STRONG_BUY: {
        color: "bg-green-500",
        label: "Strong Buy"
    },
    BUY: {
        color: "bg-green-700",
        label: "Buy"
    },
    SELL: {
        color: "bg-red-500",
        label: "Sell"
    },
    STRONG_SELL: {
        color: "bg-red-700",
        label: "Strong Sell"
    },
    params: [
        {
            label: "Pre-market",
            color: "bg-orange-500"
        },
        {
            label: "Momentum",
            color: "bg-blue-500"
        }
    ]
}

const marketStatusItems = [
    {
        label: "All",
        value: "all"
    },
    {
        label: "Market",
        value: "on"
    },
    {
        label: "Off Market",
        value: "off"
    }
]

const alertTypes = [
    {
        label: "Sentiment",
        value: "PRICE",
        superText: "+"
    },
    {
        label: "Sentiment",
        value: "SENTIMENT"
    }
]

const dashboardViews = [
    {
        label: "Stock",
        value: "STOCK"
    },
    {
        label: "Sector",
        value: "SECTOR"
    }
]

const STOCKS_WITH_SECTORS = [
    {
        sector: "Communication Services",
        tickers: ["NFLX", "DIS", "GOOGL", "META", "ASTS", "RBLX"]
    },
    {
        sector: "Consumer Discretionary",
        tickers: [
            "AMZN",
            "BABA",
            "MAR",
            "TSLA",
            "GME",
            "F",
            "DKNG",
            "LCID",
            "LULU",
            "MMYT",
            "NKE",
            "RIVN",
            "TGT",
            "TTM"
        ]
    },
    {
        sector: "Consumer Staples",
        tickers: ["PG", "WMT", "CELH", "COST", "WBA"]
    },
    {
        sector: "Energy",
        tickers: ["XOM"]
    },
    {
        sector: "Financials",
        tickers: [
            "XYZ",
            "JPM",
            "BAC",
            "GS",
            "V",
            "PYPL",
            "COIN",
            "HOOD",
            "IBN",
            "HDB",
            "MA",
            "NU",
            "SOFI"
        ]
    },
    {
        sector: "Health Care",
        tickers: ["PFE", "NVAX", "JNJ", "CVS", "MRNA", "RDY", "UNH", "VKTX"]
    },
    {
        sector: "Industrials",
        tickers: ["CAT", "UBER", "BA"]
    },
    {
        sector: "Information Technology",
        tickers: [
            "AAPL",
            "MSFT",
            "AMD",
            "NVDA",
            "INTC",
            "APP",
            "AVGO",
            "CRM",
            "CRWD",
            "DELL",
            "INFY",
            "MSTR",
            "MU",
            "PLTR",
            "RDDT",
            "SMCI",
            "SNOW",
            "U",
            "WIT",
            "ZETA"
        ]
    }
]

const SINGLE_GRAPH_DURATION = ["1D", "7D", "14D", "30D"]

export {
    alertConfig,
    marketStatusItems,
    alertTypes,
    dashboardViews,
    STOCKS_WITH_SECTORS,
    SINGLE_GRAPH_DURATION
}
