import React, { useEffect, useState } from "react"
import { useAuthState } from "react-firebase-hooks/auth"
import { Link, useNavigate } from "react-router-dom"
import { auth, signInWithGoogle } from "./firebase"
import "./static/Register.css"
// import loginBackground from "./static/faang_login_bg_new-min.jpg"
import Background from './static/research-login-background.png'
import { registerWithEmailAndPassword } from './firebase'

import googleLogo from "./static/google_logo2.png"
import Button from "@mui/material/Button"
import CssBaseline from "@mui/material/CssBaseline"
import Box from "@mui/material/Box"
import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"
import { createTheme, ThemeProvider, styled } from "@mui/material/styles"

const GoogleLogo = styled("img")({
    width: "100%",
    height: "100%",
    borderRadius: "50%"
})

const Copyright = (props) => {
    return (
        <Typography
            variant='body2'
            color='text.secondary'
            align='center'
            {...props}>
            {"Copyright © "}
            <Link color='inherit' href={process.env.REACT_APP_APP_URL}>
                FinSoftAi
            </Link>{" "}
            {new Date().getFullYear()}
            {"."}
        </Typography>
    )
}

const theme = createTheme()

function Register() {
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [name, setName] = useState("")
    const [user, loading, error] = useAuthState(auth)
    const navigate = useNavigate()
    const register = () => {
        console.log("in registar")
        if (!name) alert("Please enter name")
        registerWithEmailAndPassword(name, email, password)
    }
    useEffect(() => {
        if (loading) return
        if (user) {
            navigate("/login", { replace: true })
        }
    }, [user, loading])
    useEffect(() => {
        console.log("IN register")
    }, [])
    return (
        <ThemeProvider theme={theme}>
            <Grid container component='main' sx={{ height: "100vh" }}>
                <CssBaseline />
                <Grid
                    justifyContent={"flex-end"}
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    sx={{
                        backgroundImage: `url(${Background})`,
                        // backgroundRepeat: 'no-repeat',
                        backgroundColor: (t) =>
                            t.palette.mode === "light"
                                ? t.palette.grey[50]
                                : t.palette.grey[900],
                        backgroundSize: 'cover',
                        backgroundPosition: "center"
                        // backgroundColor: 'blue'
                    }}>
                    <Box
                        className='register_box'
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "end",
                            height: "100%",
                            width: "100%",
                            backgroundColor: "transparent",
                            pr: "5%"
                        }}>
                        <Box sx={{ mt: "auto", mb: "3%", width: "20%" }}>
                            <div className='register__container container'>
                                <div className="row gx-3 justify-content-center">
                                    <Typography
                                        className='mb-2 col-12'
                                        component='h3'
                                        variant='h4'
                                        style={{ color: "#000", fontWeight: 400 }}>
                                        Register Email
                                    </Typography>
                                    <input
                                        type='text'
                                        className='register__textBox col-md-12 col-sm-12'
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                        placeholder='Full Name'
                                    />
                                    <input
                                        type='text'
                                        className='register__textBox col-md-5 col-sm-12'
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        placeholder='E-mail Address'
                                    />
                                    <div className="col-md-auto"></div>
                                    <input
                                        type='password'
                                        className='register__textBox col-md-5 col-sm-12'
                                        value={password}
                                        onChange={(e) =>
                                            setPassword(e.target.value)
                                        }
                                        placeholder='Password'
                                    />
                                    <button
                                        className='register__btn'
                                        onClick={register}>
                                        Register
                                    </button>

                                    <div>
                                        Already have an account?
                                        <br /> <Link to='/login'>Login</Link> now.
                                    </div>
                                    <div>
                                        <hr className='inline-hr' />
                                        <span
                                            style={{
                                                fontSize: 15,
                                                fontWeight: 300,
                                                marginTop: 10,
                                                color: "#858585"
                                            }}>
                                            or login with
                                        </span>
                                        <hr className='inline-hr' />
                                    </div>
                                    <div
                                        style={{
                                            width: "100%",
                                            textAlign: "center"
                                        }}>
                                        <Button
                                            onClick={signInWithGoogle}
                                            variant='contained'
                                            sx={{
                                                borderRadius: "50%",
                                                minWidth: 0,
                                                p: 1,
                                                m: 1,
                                                boxShadow:
                                                    "0px 1px 5px rgba(0, 0, 0, 0.2)",
                                                backgroundColor: "white",
                                                width: 50,
                                                height: 50
                                            }}>
                                            <GoogleLogo
                                                src={googleLogo}
                                                alt='Google'
                                            />
                                        </Button>
                                    </div>

                                    <Copyright sx={{ mt: 2 }} />
                                </div>
                            </div>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </ThemeProvider>
    )
}
export default Register
