import React, { useEffect, useState } from "react"
import wordCloud from "highcharts/modules/wordcloud.js"
import Highcharts, { useSerialIds } from "highcharts/highstock"
import HighchartsReact from "highcharts-react-official"
import {
    Typography,
    Chip,
    Button,
    ButtonGroup,
    FormControl,
    Menu,
    InputLabel,
    MenuItem,
    Card,
    CardHeader,
    CardContent,
    IconButton
} from "@mui/material"
import fullScreen from "highcharts/modules/full-screen"
import api from "./api_ui"
import Moment from "moment"
import FullscreenOutlinedIcon from "@mui/icons-material/FullscreenOutlined"
import { FullScreen, useFullScreenHandle } from "react-full-screen"
import Select from "react-select"

// import "highcharts/css/stocktools/gui.css";
import "highcharts/css/annotations/popup.css"
import { getGraphData } from "../utils/functions"

wordCloud(Highcharts)
require("highcharts/modules/exporting")(Highcharts)
fullScreen(Highcharts)

const Sources = [
    { value: "All", label: "All" },
    { value: "Stocktwits", label: "Social Media" },
    { value: "News", label: "News" }
]

const ticker_options = api.get_tickers().map((ticker) => {
    return { value: ticker, label: ticker }
})

const seriesNames = ["Company", "Keyword", "Author", "Person"]
const colorDict = {
    Company: "#2A9D8F",
    Keyword: "#8E5572",
    Author: "#F4A261",
    Person: "#B7B5E4"
}
const filterWCData = (stdata, newsdata, key) => {
    const fields = ["Author", "Company", "Person", "Keyword"]
    let arr3 = []
    let stDates = {
        one_day: 0,
        one_week: 1,
        two_week: 2,
        one_month: 3,
        two_month: 4
    }
    let newsDates = {
        one_day: 6,
        one_week: 7,
        two_week: 8,
        one_month: 9,
        two_month: 10
    }
    for (const field_name of fields) {
        arr3[field_name] = [
            ...stdata[stDates[key]][field_name],
            ...newsdata[newsDates[key]][field_name]
        ]
    }
    return arr3
}

const filterSourceWCData = (data, key, source = null) => {
    const fields = ["Author", "Company", "Person", "Keyword"]
    let arr3 = []
    let stDates = {
        one_day: 0,
        one_week: 1,
        two_week: 2,
        one_month: 3,
        two_month: 4
    }
    let newsDates = {
        one_day: 6,
        one_week: 7,
        two_week: 8,
        one_month: 9,
        two_month: 10
    }
    for (const field_name of fields) {
        if (source === "Stocktwits") {
            arr3[field_name] = [...data[stDates[key]][field_name]]
        } else if (source === "News") {
            arr3[field_name] = [...data[newsDates[key]][field_name]]
        }
    }
    return arr3
}

const Wordcloud = ({ date }) => {
    const [ticker, setTicker] = useState("TSLA")
    // const [ticker_options, setTickerOptions] = useState([]);
    const [selectedDate, setSelectedDate] = useState(1)
    const [displayDates, setDisplayDates] = useState("")
    const [WCViews, setWCViews] = useState("one_day")
    const [datesList, setDatesList] = useState([
        "2022-06-05",
        "2022-06-05",
        "2022-05-29",
        "2022-05-22",
        "2022-05-06",
        "2022-04-06",
        "2022-03-07",
        "2022-06-05"
    ])
    const [wordcloudSeries, setWordcloudSeries] = useState([])
    const [wcSource, setSource] = useState("All")
    const screen = useFullScreenHandle()
    const customStyles = React.useMemo(
        () => ({
            option: (provided, state) => ({
                ...provided,
                fontFamily: "Roboto, Helvetica"
            }),
            valueContainer: (provided, state) => ({
                ...provided,
                fontFamily: "Roboto, Helvetica"
            }),
            container: (provided, state) => ({
                ...provided,
                fontFamily: "Roboto, Helvetica"
            })
        }),

        []
    )

    const setViews = (date) => {
        let datesKVPair = {
            one_day: datesList[1],
            one_week: datesList[2],
            two_week: datesList[3],
            one_month: datesList[4],
            two_month: datesList[5]
        }
        // let datesKVPairChange = {"one_day":datesList[7],"one_week":datesList[2],"two_week":datesList[3],"one_month":datesList[4],"two_month":datesList[5]}
        setWCViews((prevState) => {
            return date
        })
        setDisplayDates((prevState) => {
            return (
                Moment(datesKVPair[date]).format("DD/MMM/YYYY") +
                " > " +
                Moment(datesList[1]).format("DD/MMM/YYYY")
            )
        })
    }

    const setData = async () => {
        if (wcSource === "All") {
            await getGraphData({ graph_type: "wordcloud", ticker, date }).then(
                (wordcloudItems) => {
                    const data = wordcloudItems[ticker]

                    let mergedData = filterWCData(data.data, data.data, WCViews)

                    let finalSeries = []
                    for (let key of seriesNames) {
                        let counts = mergedData[key].reduce((prev, curr) => {
                            let count = prev.get(curr.name) || 0
                            prev.set(
                                curr.name,
                                parseInt(curr.weight) + count,
                                curr.color
                            )
                            return prev
                        }, new Map())

                        // then, map your counts object back to an array
                        let reducedObjArr = [...counts].map(
                            ([name, weight, color]) => {
                                return { name, weight, color }
                            }
                        )
                        finalSeries.push({
                            data: reducedObjArr,
                            name: key,
                            type: "wordcloud",
                            showInLegend: true,
                            color: colorDict[key],
                            maxFontSize: 18,
                            spiral: "rectangular",
                            minFontSize: 8,
                            xAxis: seriesNames.indexOf(key),
                            colorByPoint: false,
                            shadow: true,
                            style: {
                                width: 16,
                                height: 16
                            }
                        })
                    }

                    setWordcloudSeries(() => {
                        return finalSeries
                    })
                }
            )
            return
        }

        await getGraphData({ graph_type: "wordcloud", ticker, date }).then(
            (wordcloudItems) => {
                const data = wordcloudItems[ticker]

                const mergedData = filterSourceWCData(
                    data.data,
                    WCViews,
                    wcSource
                )

                let finalSeries = []

                for (let key of seriesNames) {
                    finalSeries.push({
                        data: mergedData[key].filter((i) => i.name && i.weight),
                        name: key,
                        type: "wordcloud",
                        showInLegend: true,
                        maxFontSize: 18,
                        spiral: "rectangular",
                        minFontSize: 8,
                        xAxis: seriesNames.indexOf(key),
                        shadow: true,
                        style: {
                            width: 16,
                            height: 16
                        }
                    })
                }
                setWordcloudSeries(() => {
                    return finalSeries
                })
            }
        )
    }

    useEffect(() => {
        if (ticker) {
            setData()
        }
    }, [ticker, WCViews, wcSource, date])

    return (
        <div className='mt-3'>
            <FullScreen handle={screen} onChange={setData}>
                <Card
                    style={
                        screen.active
                            ? {
                                  borderRadius: 20,
                                  backgroundColor: "#f4f4f4",
                                  height: "100%",
                                  overflowY: "scroll"
                              }
                            : {
                                  borderRadius: 20,
                                  backgroundColor: "rgba(229, 229, 229, 0.42)"
                              }
                    }>
                    <CardHeader
                        title={
                            <div
                                className='row mb-2'
                                style={{
                                    display: "flex",
                                    justifyContent: "center"
                                }}>
                                <Typography
                                    className='col-md-10 ml-3'
                                    align='center'
                                    variant='h4'
                                    style={{ fontSize: 25, fontWeight: 600 }}>
                                    {ticker
                                        ? "Trending Words for " + ticker
                                        : ""}
                                </Typography>
                            </div>
                        }
                    />
                    <CardContent>
                        <div
                            className='row mb-2'
                            style={{
                                width: "100%",
                                textAlign: "right",
                                border: "solid 0px blue"
                            }}>
                            <ButtonGroup
                                className='col-md-8'
                                style={{
                                    textAlign: "center",
                                    paddingLeft: "auto"
                                }}
                                size='small'
                                aria-label='text button group'>
                                <Button
                                    style={{
                                        backgroundColor:
                                            selectedDate === 1
                                                ? "#4b8fcd"
                                                : "lightGrey",
                                        height: "2rem",
                                        color:
                                            selectedDate === 1
                                                ? "white"
                                                : "black"
                                    }}
                                    size='small'
                                    variant='contained'
                                    onClick={() => {
                                        setViews("one_day")
                                        setSelectedDate((prevState) => {
                                            return 1
                                        })
                                    }}>
                                    1 day
                                </Button>
                                <Button
                                    style={{
                                        backgroundColor:
                                            selectedDate === 2
                                                ? "#4b8fcd"
                                                : "lightGrey",
                                        height: "2rem",
                                        color:
                                            selectedDate === 2
                                                ? "white"
                                                : "black"
                                    }}
                                    size='small'
                                    variant='contained'
                                    onClick={() => {
                                        setViews("one_week")
                                        setSelectedDate((prevState) => {
                                            return 2
                                        })
                                    }}>
                                    7 days
                                </Button>
                                <Button
                                    style={{
                                        backgroundColor:
                                            selectedDate === 3
                                                ? "#4b8fcd"
                                                : "lightGrey",
                                        height: "2rem",
                                        color:
                                            selectedDate === 3
                                                ? "white"
                                                : "black"
                                    }}
                                    size='small'
                                    variant='contained'
                                    onClick={() => {
                                        setViews("two_week")
                                        setSelectedDate((prevState) => {
                                            return 3
                                        })
                                    }}>
                                    14 days
                                </Button>
                                <Button
                                    style={{
                                        backgroundColor:
                                            selectedDate === 4
                                                ? "#4b8fcd"
                                                : "lightGrey",
                                        height: "2rem",
                                        color:
                                            selectedDate === 4
                                                ? "white"
                                                : "black"
                                    }}
                                    size='small'
                                    variant='contained'
                                    onClick={() => {
                                        setViews("one_month")
                                        setSelectedDate((prevState) => {
                                            return 4
                                        })
                                    }}>
                                    30 days
                                </Button>
                                {/* <Button
                                  style={{
                                      backgroundColor:
                                          selectedDate === 5
                                              ? "#4b8fcd"
                                              : "lightGrey",
                                      height: "2rem",
                                      color:
                                          selectedDate === 5 ? "white" : "black"
                                  }}
                                  size='small'
                                  variant='contained'
                                  onClick={() => {
                                      setViews("two_month")
                                      setSelectedDate((prevState) => {
                                          return 5
                                      })
                                  }}>
                                  60 days
                              </Button> */}
                            </ButtonGroup>
                            <div
                                className='col-md-2'
                                style={{
                                    marginLeft: "auto",
                                    marginRight: "auto"
                                }}>
                                <FormControl
                                    sx={{ minWidth: 60 }}
                                    style={{
                                        width: "75%",
                                        backgroundColor: "white",
                                        color: "black",
                                        textAlign: "left"
                                    }}
                                    size='small'>
                                    <Select
                                        className='basic-single'
                                        classNamePrefix='select'
                                        defaultValue={Sources[0]}
                                        // placeholder="Source"
                                        isSearchable={true}
                                        name='color'
                                        onChange={(event) => {
                                            setSource(() => {
                                                return event.value
                                            })
                                        }}
                                        options={Sources}
                                        styles={customStyles}
                                    />
                                </FormControl>
                            </div>
                            <div className='col-md-2'>
                                <FormControl
                                    sx={{ minWidth: 100 }}
                                    style={{
                                        width: "90%",
                                        backgroundColor: "white",
                                        color: "black",
                                        textAlign: "left"
                                    }}
                                    size='small'>
                                    <Select
                                        className='basic-single'
                                        classNamePrefix='select'
                                        defaultValue={ticker_options[2]}
                                        // placeholder="Source"
                                        isSearchable={true}
                                        name='color'
                                        onChange={(event) => {
                                            setTicker(() => {
                                                return event.value
                                            })
                                        }}
                                        options={ticker_options}
                                        styles={customStyles}
                                    />
                                </FormControl>
                            </div>
                        </div>
                        {/* <Typography variant="h5" className='col-md-3' style={{ textAlign: 'right' }}> Trending Words for {ticker} </Typography> */}
                        <div
                            style={{
                                width: "100%",
                                float: "left",
                                paddingBottom: 15
                            }}
                            id='highcharts-container'>
                            <HighchartsReact
                                highcharts={Highcharts}
                                options={{
                                    series: wordcloudSeries,
                                    xAxis: [
                                        {
                                            width: "20%"
                                        },
                                        {
                                            left: "20%",
                                            width: "20%"
                                        },
                                        {
                                            left: "40%",
                                            width: "20%"
                                        },
                                        {
                                            left: "60%",
                                            width: "20%"
                                        },
                                        {
                                            left: "80%",
                                            width: "20%"
                                        }
                                    ],

                                    exporting: {
                                        enabled: false
                                    },
                                    title: {
                                        // text: ticker ? "Trending Words by " + ticker : "",
                                        text: "",
                                        style: {
                                            fontSize: 22
                                        }
                                    },
                                    stockTools: {
                                        gui: {
                                            enabled: false
                                        }
                                    },
                                    plotOptions: {
                                        wordcloud: {
                                            colors: [
                                                "#264653",
                                                "#2A9D8F",
                                                "#8E5572",
                                                "#F4A261",
                                                "#B7B5E4"
                                            ]
                                        }
                                    },

                                    credits: {
                                        text: "finsoftai.com",
                                        href: 'javascript:window.open("http://www.finsoftai.com/", "_blank")',
                                        style: {
                                            fontSize: 14,
                                            textDecoration: "underline",
                                            color: "#0248b3"
                                        },
                                        position: {
                                            align: "right",
                                            x: -10
                                        }
                                    },
                                    legend: {
                                        enabled: true,
                                        // padding:20,
                                        // itemWidth:245,
                                        align: "center",
                                        layout: "horizontal",
                                        verticalAlign: "top",
                                        itemStyle: {
                                            marginBottom: 30,
                                            fontSize: "16px"
                                            // marginLeft:30
                                            // paddingLeft:20
                                        }
                                        // x: 42,
                                    },
                                    chart: {
                                        height: screen.active ? 700 : 400,
                                        // width:"100%",
                                        spacing: [1, 1, 1, 1],
                                        margin: 1,
                                        renderTo: "highcharts-container",
                                        marginBottom: 20
                                        // marginTop:20
                                    }
                                }}
                            />
                        </div>
                    </CardContent>
                </Card>
            </FullScreen>
        </div>
    )
}

export default Wordcloud
