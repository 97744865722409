import AlertCard from "./AlertCard"
import { alertConfig } from "../../../constants"

const AlertCardContainer = ({ alerts, index }) => {
    return (
        <div className='flex flex-wrap gap-2'>
            {alerts.map((alert, key) => {
                return (
                    <AlertCard
                        {...alert}
                        key={key}
                        color={alertConfig.params[index].color}
                    />
                )
            })}
        </div>
    )
}

export default AlertCardContainer
