import "./App.css"
import React, { createContext, useState } from "react"
import { Route, Routes, Navigate, BrowserRouter } from "react-router-dom"
import Login from "./components/Login"
import { history } from "./components/history"
import OurApp from "./OurApp"
import Register from "./components/Register"
import Reset from "./components/Reset"
import ErrorPage from "./components/ErrorPage"
import { WeightProvider } from "./context/WeightProvider"

const App = () => {
    return (
        <WeightProvider>
            <div className='App'>
                <BrowserRouter history={history}>
                    <Routes>
                        <Route exact path='/login' element={<Login />} />
                        <Route path='/board' element={<OurApp />} />
                        <Route exact path='/register' element={<Register />} />
                        <Route exact path='/reset' element={<Reset />} />
                        <Route exact path='/error' element={<ErrorPage />} />
                        <Route
                            exact
                            path='/'
                            element={<Navigate to='/login' />}></Route>
                    </Routes>
                </BrowserRouter>
            </div>
        </WeightProvider>
    )
}

export default App
