import { Modal, Backdrop, Snackbar, SnackbarContent } from "@material-ui/core"
import { Slide } from "@mui/material"
import { makeStyles } from "@material-ui/core/styles"
import TextField from "@mui/material/TextField"
import Button from "@material-ui/core/Button"
import { useEffect, useState } from "react"
import api from "./api_ui"
import useUser from "./hooks/useUser"
import { STOCKS_WITH_SECTORS } from "../constants"

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: "white !important"
    },
    icon: {
        fill: "blue"
    },
    modal: {
        margin: "auto",
        marginTop: "5%",
        flexGrow: 1,
        alignItems: "center",
        justifyContent: "center"
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 3, 2)
    },
    fullscreenBackground: {
        border: "solid 2px red !important",
        height: "100% !important"
    },
    summaryRoot: {
        justifyContent: "flex-start !important"
    },
    summaryContent: {
        display: "flex",
        alignItems: "center",
        flexGrow: "unset !important",
        marginLeft: "10px"
    }
}))

const PortfolioSelectionModal = ({
    modalToggle,
    setModalToggle,
    selectedPortfolio,
    userId,
    fetchUserPortfolios
}) => {
    const classes = useStyles()
    const [selectedStocks, setSelectedStocks] = useState([])
    const [portfolioName, setPortfolioName] = useState(
        selectedPortfolio?.name || ""
    )
    const [alertState, setAlertState] = useState({
        open: false,
        vertical: "top",
        horizontal: "center",
        severity: "",
        message: ""
    })
    const [error, setError] = useState("")
    const { currentUser } = useUser()

    const handleAlertOpen = (newState) => {
        setAlertState({ alertOpen: true, ...newState })
    }
    const handleAlertClose = () => {
        setAlertState({ ...alertState, alertOpen: false })
    }

    const handleSelectStocks = (ticker) => {
        if (selectedStocks.includes(ticker)) {
            setSelectedStocks(
                selectedStocks.filter((stock) => stock !== ticker)
            )
        } else {
            setSelectedStocks([...selectedStocks, ticker])
        }
    }

    const handleSavePortfolio = async () => {
        const payload = {
            name: portfolioName,
            stocks: JSON.stringify(selectedStocks),
            userId: currentUser.id
        }

        if (!portfolioName) {
            setError("Portfolio name is required")
            return
        } else if (portfolioName.toUpperCase() === "ALL") {
            setError("Custom Portfolio can't be named as 'All'")
            return
        } else if (selectedStocks.length === 0) {
            setError("Select at least one stock")
            return
        }

        try {
            await api.save_user_portfolios(payload)
            setModalToggle(false)
            fetchUserPortfolios()

            handleAlertOpen({
                vertical: "top",
                horizontal: "center",
                message: "Portfolio saved",
                contentStyle: {
                    backgroundColor: "#008080"
                }
            })
        } catch (err) {
            console.log("Error in save_user_portfolios", err)
        }
    }

    const handleDeletePortfolio = async () => {
        try {
            const payload = {
                id: selectedPortfolio.id,
                userId: currentUser.id
            }

            if (selectedPortfolio.name === "All") {
                setError("Default portfolio cannot be deleted")
                return
            }

            await api.delete_user_portfolio(payload)
            setModalToggle(false)

            // Reload the page
            window.location.reload()

            handleAlertOpen({
                vertical: "top",
                horizontal: "center",
                message: "Portfolio deleted",
                contentStyle: {
                    backgroundColor: "#008080"
                }
            })
        } catch (err) {
            console.log("Error in delete_user_portfolio", err)
        }
    }

    useEffect(() => {
        if (selectedPortfolio) {
            const stocks = JSON.parse(selectedPortfolio.stocks)
            setSelectedStocks(stocks)
            setPortfolioName(selectedPortfolio.name)
        }
    }, [selectedPortfolio])

    useEffect(() => {
        setTimeout(() => {
            setError("")
        }, 4000)

        return () => {
            clearTimeout()
        }
    }, [error])

    return (
        <div>
            <Snackbar
                anchorOrigin={{
                    vertical: alertState.vertical,
                    horizontal: alertState.horizontal
                }}
                open={alertState.alertOpen}
                onClose={handleAlertClose}
                autoHideDuration={2000}
                key={alertState.vertical + alertState.horizontal}
                severity='info'
                bodyStyle={{ backgroundColor: "teal", color: "coral" }}>
                <SnackbarContent
                    message={alertState.message}
                    style={alertState.contentStyle}></SnackbarContent>
            </Snackbar>
            <Modal
                aria-labelledby='transition-modal-title'
                aria-describedby='transition-modal-description'
                className={classes.modal}
                open={modalToggle}
                keepMounted
                onClose={() => {
                    setModalToggle(false)
                }}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500
                }}
                style={{
                    width: "50%",
                    marginTop: "0",
                    maxHeight: "90%",
                    overflowY: "scroll",
                    borderRadius: "10px"
                }}>
                <Slide
                    direction='down'
                    in={modalToggle}
                    mountOnEnter
                    unmountOnExit
                    style={{ transformOrigin: "0 0 0" }}
                    {...(modalToggle ? { timeout: 300 } : { timeout: 300 })}>
                    <div class='container-fluid ' className={classes.paper}>
                        <div className='flex flex-col gap-4 py-2'>
                            <h2>Configure Portfolio</h2>
                            <div className='flex gap-2 items-center'>
                                <TextField
                                    variant='outlined'
                                    size='small'
                                    label='My Portfolio'
                                    type='text'
                                    onChange={(event) => {
                                        setPortfolioName(event.target.value)
                                    }}
                                    value={portfolioName}
                                    InputLabelProps={{
                                        shrink: true
                                    }}></TextField>
                                {/* Add a Toggle Button that Checks or Unchecks All Tickers */}
                                <Button
                                    color='primary'
                                    variant='contained'
                                    className=''
                                    onClick={() => {
                                        setSelectedStocks([])
                                    }}
                                    style={{
                                        backgroundColor: "#034ea2",
                                        color: "white"
                                    }}>
                                    Uncheck All
                                </Button>
                            </div>

                            <div className='flex flex-col gap-4'>
                                {STOCKS_WITH_SECTORS.map((condition) => {
                                    const { tickers, sector } = condition

                                    return (
                                        <div className='flex flex-col gap-2'>
                                            <p className='m-0 font-semibold'>
                                                {sector}
                                            </p>
                                            <div className='flex flex-wrap gap-2'>
                                                {tickers.map(
                                                    (ticker, index) => (
                                                        <div
                                                            key={index}
                                                            className='flex items-center'>
                                                            <input
                                                                type='checkbox'
                                                                id={ticker}
                                                                name={ticker}
                                                                checked={selectedStocks.includes(
                                                                    ticker
                                                                )}
                                                                onChange={(
                                                                    event
                                                                ) => {
                                                                    handleSelectStocks(
                                                                        ticker,
                                                                        event
                                                                            .target
                                                                            .checked
                                                                    )
                                                                }}
                                                            />
                                                            <label
                                                                htmlFor={ticker}
                                                                className='ml-2'>
                                                                {ticker}
                                                            </label>
                                                        </div>
                                                    )
                                                )}
                                            </div>
                                        </div>
                                    )
                                })}
                                <div className='flex gap-2'>
                                    <Button
                                        color='primary'
                                        variant='contained'
                                        className='col-1'
                                        onClick={() => {
                                            handleSavePortfolio()
                                        }}
                                        style={{
                                            backgroundColor: "#034ea2",
                                            color: "white"
                                        }}>
                                        Save
                                    </Button>
                                    <Button
                                        color='primary'
                                        variant='contained'
                                        className='col-1'
                                        onClick={handleDeletePortfolio}
                                        style={{
                                            backgroundColor: "red",
                                            color: "white"
                                        }}>
                                        Delete
                                    </Button>
                                </div>
                                <div>
                                    <p className='m-0 text-red-500'>{error}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </Slide>
            </Modal>
        </div>
    )
}

export default PortfolioSelectionModal
