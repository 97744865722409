import React, { Component } from 'react'
import { Container } from "@material-ui/core"
import { Toolbar } from '@material-ui/core'
import { AppBar } from '@material-ui/core'

export default function Footer() {
  const getYear = () => {
    return new Date().getFullYear()
  }

  return (
    <AppBar position="static" style={{ backgroundColor: "#E4E5E6" }}>
      <Container style={{ marginLeft: 35 }}>
        <Toolbar>
          <p style={{ color: "#77787B", marginTop: 15 }}>
            ©️ {getYear()} FinSoftAI Solutions, All Rights Reserved.
          </p>
        </Toolbar>
      </Container>
    </AppBar>
  )
}
