const AlertParameter = ({ label, color }) => {
    return (
        <div className='justify-start items-center gap-2.5 flex md:flex-row flex-col-reverse'>
            <div className='text-black text-sm font-medium'>{label}</div>
            <div className={`w-7 h-1 ${color}`} />
        </div>
    )
}

export default AlertParameter
