import "bootstrap/dist/css/bootstrap.min.css"
import React, { useState, useEffect, useCallback } from "react"
import { makeStyles } from "@material-ui/core/styles"
import { Button, Modal, Backdrop, IconButton } from "@material-ui/core"
import {
    Slide,
    Accordion,
    AccordionSummary,
    AccordionDetails
} from "@mui/material"
import fullScreen from "highcharts/modules/full-screen"
import wordCloud from "highcharts/modules/wordcloud.js"
import "highcharts/css/annotations/popup.css"
import Highcharts from "highcharts/highstock"
import Moment from "moment-timezone"
import indicatorsAll from "highcharts/indicators/indicators-all"
import annotationsAdvanced from "highcharts/modules/annotations-advanced"
import priceIndicator from "highcharts/modules/price-indicator"
import dayjs from "dayjs"
import timezone from "dayjs/plugin/timezone.js"
import utc from "dayjs/plugin/utc"
import * as customParseFormat from "dayjs/plugin/customParseFormat"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import DashboardAlert from "./page/DashboardAlert"
import dateJson from "./static/data/date.json"
import Switcher from "./common/Switcher"
import { marketStatusItems, alertTypes, dashboardViews } from "../constants"
import { generateAlerts } from "../utils/functions"
import alertsJSON from "../components/static/data/alerts.json"
import GraphLoading from "./static/graph_loading.gif"
import LoadingOverlay from "react-loading-overlay"
import Joyride, { ACTIONS, EVENTS } from "react-joyride"
import PortfolioSelectionModal from "./PortfolioSelectionModal"
import api from "./api_ui"
import StockViewDashboard from "./dashboard/StockView"
import SectorViewDashboard from "./dashboard/SectorView"

wordCloud(Highcharts)
require("highcharts/modules/exporting")(Highcharts)
indicatorsAll(Highcharts)
annotationsAdvanced(Highcharts)
priceIndicator(Highcharts)
fullScreen(Highcharts)
dayjs.extend(customParseFormat)
dayjs.extend(utc)
dayjs.extend(timezone)
const GRAPH_NORMALIZE_FACTOR = 20

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: "white !important"
    },
    icon: {
        fill: "blue"
    },
    modal: {
        margin: "auto",
        marginTop: "5%",
        flexGrow: 1,
        alignItems: "center",
        justifyContent: "center"
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 3, 2)
    },
    fullscreenBackground: {
        border: "solid 2px red !important",
        height: "100% !important"
    },
    summaryRoot: {
        justifyContent: "flex-start !important"
    },
    summaryContent: {
        display: "flex",
        alignItems: "center",
        flexGrow: "unset !important",
        marginLeft: "10px"
    }
}))

let initialStockOptions = {
    buzz: {
        chart: {
            type: "bar"
        },
        plotOptions: {
            series: {
                stacking: "normal"
            },
            visible: false
        },
        credits: {
            text: "finsoftai.com",
            href: 'javascript:window.open("http://www.finsoftai.com/", "_blank")',
            style: {
                fontSize: 14,
                textDecoration: "underline",
                color: "#0248b3"
            },
            position: {
                align: "right",
                x: -10
            }
        },
        stockTools: {
            gui: {
                enabled: false // disable the built-in toolbar
            }
        },
        xAxis: [
            {
                title: {
                    text: "Tickers",
                    style: {
                        fontWeight: "bold",
                        fontSize: "17px",
                        fontFamily: "Poppins"
                    }
                }
            }
        ],

        yAxis: [
            {
                title: {
                    text: "Buzz Volume",
                    style: {
                        fontWeight: "bold",
                        fontSize: "17px",
                        fontFamily: "Poppins"
                    }
                }
            }
        ],
        title: {
            text: ""
        },
        exporting: {
            buttons: {
                contextButton: {
                    menuItems: ["viewFullscreen"]
                }
            }
        },
        series: [
            {
                data: [{ x: 1, y: 13 }]
            }
        ]
    },
    sentiment: {
        chart: {
            type: "bar",
            styledmode: "on"
        },
        credits: {
            text: "finsoftai.com",
            href: 'javascript:window.open("http://www.finsoftai.com/", "_blank")',
            style: {
                fontSize: 14,
                textDecoration: "underline",
                color: "#0248b3"
            },
            position: {
                align: "right",
                x: -10
            }
        },
        stockTools: {
            gui: {
                enabled: false // disable the built-in toolbar
            }
        },
        xAxis: [
            {
                title: {
                    text: "Tickers",
                    style: {
                        fontWeight: "bold",
                        fontSize: "17px",
                        fontFamily: "Poppins"
                    }
                }
            }
        ],

        yAxis: [
            {
                title: {
                    text: "sentiment",
                    style: {
                        fontWeight: "bold",
                        fontSize: "17px",
                        fontFamily: "Poppins"
                    }
                }
            }
        ],
        title: {
            text: ""
        },

        exporting: {
            buttons: {
                contextButton: {
                    menuItems: ["viewFullscreen"]
                }
            }
        },

        labels: {
            formatter: function () {
                return Highcharts.numberFormat(this.value, 2)
            }
        },
        series: [
            {
                name: "Tickers",
                data: [
                    0.481, 0.262, 0.253, 0.215, 0.131, 0.112, 0.94, -0.35, -0.3,
                    -0.8
                ]
            }
        ]
    },
    change: {
        chart: {
            type: "bar",
            styledmode: "on"
        },
        credits: {
            text: "finsoftai.com",
            href: 'javascript:window.open("http://www.finsoftai.com/", "_blank")',
            style: {
                fontSize: 14,
                textDecoration: "underline",
                color: "#0248b3"
            },
            position: {
                align: "right",
                x: -10
            }
        },
        stockTools: {
            gui: {
                enabled: false // disable the built-in toolbar
            }
        },
        xAxis: [
            {
                title: {
                    text: "Tickers",
                    style: {
                        fontWeight: "bold",
                        fontSize: "17px",
                        fontFamily: "Poppins"
                    }
                }
            }
        ],

        yAxis: [
            {
                title: {
                    text: "sentiment",
                    style: {
                        fontWeight: "bold",
                        fontSize: "17px",
                        fontFamily: "Poppins"
                    }
                }
            }
        ],
        title: {
            text: ""
        },

        exporting: {
            buttons: {
                contextButton: {
                    menuItems: ["viewFullscreen"]
                }
            }
        },

        labels: {
            formatter: function () {
                return Highcharts.numberFormat(this.value, 2)
            }
        },
        series: [
            {
                name: "Tickers",
                data: [
                    0.481, 0.262, 0.253, 0.215, 0.131, 0.112, 0.94, -0.35, -0.3,
                    -0.8
                ],
                color: "#43a84a",
                negativeColor: "#e64736",

                type: "bar",
                pointStart: 0
            }
        ]
    }
}

const TrendingGraphs = (props) => {
    const classes = useStyles()
    const [graphModalToggle, setGraphToggle] = useState(true)
    const [graphViews, setGraphViews] = useState({
        buzz: "one_day",
        sentiment: "one_day",
        changeBuzz: "one_day",
        changeSentiment: "one_day"
    })
    const [graphDataCount, setGraphDataCount] = useState({
        buzz: false,
        sentiment: false,
        changeBuzz: false,
        changeSentiment: false
    })
    const [buzzGraph, setBuzzGraph] = useState(initialStockOptions["buzz"])
    const [buzzGraphChange, setBuzzGraphChange] = useState(
        initialStockOptions["sentiment"]
    )
    const [sentiGraph, setSentiGraph] = useState(
        initialStockOptions["sentiment"]
    )
    const [sentiGraphChange, setSentiGraphChange] = useState(
        initialStockOptions["change"]
    )
    const [datesList, setDatesList] = useState([])
    const [displayDates, setDisplayDates] = useState({
        buzz: "",
        sentiment: "",
        changeBuzz: "",
        changeSentiment: ""
    })

    const [portfolioSelectionModel, setPortfolioSelectionModel] =
        useState(false)
    const [graphDateTime, setGraphDateTime] = useState(null)
    const [isLoading, setIsLoading] = useState(false)

    const [graphData, setGraphData] = useState({
        buzz: null,
        sentiment: null,
        changeBuzz: null,
        changeSentiment: null
    })
    const [alerts, setAlerts] = useState(alertsJSON)
    const [displayDate, setDisplayDate] = useState(null)
    const [hideAlertsToggle, setHideAlertsToggle] = useState(false)
    const [marketStatusKey, setMarketStatusKey] = useState(marketStatusItems[0])
    const [alertType, setAlertType] = useState(alertTypes[0])
    const [dashboardView, setDashboardView] = useState(dashboardViews[0])
    const [run, setRun] = useState(false)
    const [stepIndex, setIndex] = useState(0)
    const [tickers, setTickerOptions] = useState(api.get_tickers())

    useEffect(() => {
        if (props.modalToggle) {
            setGraphToggle(() => {
                return true
            })
        }
    }, [props.modalToggle])

    useEffect(() => {
        if (props.selectedPortfolio) {
            const tickers = JSON.parse(props.selectedPortfolio.stocks)
            setTickerOptions(tickers)
        }
    }, [props.selectedPortfolio])

    useEffect(() => {
        props.setModalToggle(graphModalToggle)
    }, [graphModalToggle])

    useEffect(() => {
        setViews(1, "one_day")
        setViews(2, "one_day")
        setViews(3, "one_day")
        setViews(4, "one_day")
    }, [datesList])

    // Function to process data and draw the graph

    // Generic function to fetch graph data
    const formatDayjs = (date, format = "YYYY-MM-DDTHH:mm:ssZ") => {
        if (!date) return

        return dayjs(date).format(format)
    }

    // Function to render Alerts
    const renderAlerts = async (apiResponse) => {
        setIsLoading(true)
        const date = formatDayjs(graphDateTime)

        const priceEnabled =
            alertType.value === alertTypes[0].value ? true : false

        // If All is selected, then we don't need to pass marketStatusKey
        let response = apiResponse
        if (marketStatusKey === "all" || marketStatusKey.value === "all") {
            response = null
        }

        const { preMarketAlerts, momentumAlerts } = await generateAlerts(
            date,
            priceEnabled,
            response
        )

        const filteredPreMarketAlerts = preMarketAlerts.filter((alert) =>
            tickers.includes(alert.stock)
        )

        const filteredMomentumAlerts = momentumAlerts.filter((alert) =>
            tickers.includes(alert.stock)
        )

        setAlerts({
            preMarketAlerts: filteredPreMarketAlerts,
            momentumAlerts: filteredMomentumAlerts
        })
        setIsLoading(false)
    }

    useEffect(() => {
        renderAlerts()
    }, [graphDateTime, alertType, tickers])

    const setViews = (key, date) => {
        let datesKVPair = {
            one_day: datesList[1],
            one_week: datesList[2],
            two_week: datesList[3],
            one_month: datesList[4],
            two_month: datesList[5]
        }
        let datesKVPairChange = {
            one_day: datesList[7],
            one_week: datesList[2],
            two_week: datesList[3],
            one_month: datesList[4],
            two_month: datesList[5]
        }
        if (key === 1) {
            setGraphViews((prevState) => {
                return { ...prevState, buzz: date }
            })
            setDisplayDates((prevState) => {
                return {
                    ...prevState,
                    buzz:
                        Moment(datesKVPair[date]).format("DD/MMMM/YYYY") +
                        " > " +
                        Moment(datesList[1]).format("DD/MMMM/YYYY")
                }
            })
        } else if (key === 2) {
            setGraphViews((prevState) => {
                return { ...prevState, sentiment: date }
            })
            setDisplayDates((prevState) => {
                return {
                    ...prevState,
                    sentiment:
                        Moment(datesKVPair[date]).format("DD/MMMM/YYYY") +
                        " > " +
                        Moment(datesList[1]).format("DD/MMMM/YYYY")
                }
            })
        } else if (key === 3) {
            setGraphViews((prevState) => {
                return { ...prevState, changeBuzz: date }
            })
            setDisplayDates((prevState) => {
                return {
                    ...prevState,
                    changeBuzz:
                        Moment(datesKVPairChange[date]).format("DD/MMMM/YYYY") +
                        " > " +
                        Moment(datesList[1]).format("DD/MMMM/YYYY")
                }
            })
        } else if (key === 4) {
            setGraphViews((prevState) => {
                return { ...prevState, changeSentiment: date }
            })
            setDisplayDates((prevState) => {
                return {
                    ...prevState,
                    changeSentiment:
                        Moment(datesKVPairChange[date]).format("DD/MMMM/YYYY") +
                        " > " +
                        Moment(datesList[1]).format("DD/MMMM/YYYY")
                }
            })
        }
    }

    // Check Future Date
    const checkFutureData = (selectedDate) => {
        const fixedTimeValue = selectedDate.hour(8).minute(30)

        const currentDate = dayjs().tz("America/New_York")

        if (fixedTimeValue.diff(currentDate, "days", true) < 0) {
            setGraphDateTime(fixedTimeValue)
        } else {
            alert("Enter a valid date")
        }
    }

    const getDisplayDates = () => {
        if (displayDate) {
            return formatDayjs(displayDate, "DD MMM YYYY HH:mm A")
        }

        return Moment(dateJson["lastPulledDate"])
            .tz("America/New_York")
            .format("DD-MMM-YYYY ")
    }

    const selectAlertType = (key) => {
        const alertType = alertTypes.find((item) => item.value === key)
        setAlertType(alertType ?? alertTypes[0])
    }

    const selectDashboardView = (key) => {
        const view = dashboardViews.find((item) => item.value === key)
        setDashboardView(view ?? dashboardViews[0])
    }

    useEffect(() => {
        let date = graphDateTime ?? dateJson["lastPulledDate"]
        date = formatDayjs(date)

        let displayDate = ""

        // If Date is from JSON
        if (!graphDateTime) {
            displayDate = Moment(date)
                .hour(8)
                .minute(30)
                .format("DD MMM YYYY HH:mm A")
        } else {
            displayDate = Moment(date)
                .tz("America/New_York")
                .format("DD MMM YYYY HH:mm A")
        }

        setDisplayDate(displayDate)
    }, [graphDateTime])

    return (
        <div>
            <Modal
                aria-labelledby='transition-modal-title'
                aria-describedby='transition-modal-description'
                open={graphModalToggle}
                keepMounted
                onClose={() => {
                    setGraphToggle(false)
                }}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500
                }}
                className={`w-[90%] mt-0 max-h-full overflow-y-auto ${classes.modal}`}>
                <div className={`container-fluid ${classes.paper}`}>
                    <div className='flex gap-6'>
                        <Accordion className='w-full flex-1'>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                classes={{
                                    root: classes.summaryRoot,
                                    content: classes.summaryContent
                                }}>
                                <div className='text-black text-xl font-bold'>
                                    Today's Alert
                                </div>
                            </AccordionSummary>
                            <AccordionDetails>
                                <LoadingOverlay
                                    active={isLoading}
                                    spinner={
                                        <img
                                            src={GraphLoading}
                                            className='w-full h-auto'
                                        />
                                    }>
                                    <div className='flex flex-col justify-start gap-4'>
                                        {!hideAlertsToggle && (
                                            <div className='flex flex-col'>
                                                <Switcher
                                                    items={alertTypes}
                                                    onSelect={selectAlertType}
                                                    selected={alertType}
                                                />
                                            </div>
                                        )}
                                        <DashboardAlert
                                            date={displayDate}
                                            alerts={alerts}
                                        />
                                    </div>
                                </LoadingOverlay>
                            </AccordionDetails>
                        </Accordion>
                        <div className='flex flex-col gap-3'>
                            <p className='font-semibold m-0'>Select View</p>
                            <Switcher
                                items={dashboardViews}
                                onSelect={selectDashboardView}
                                selected={dashboardView}
                            />
                        </div>
                        <div className='flex gap-4'>
                            <div className='flex gap-3 flex-col items-center step-eight'>
                                <p className='font-semibold m-0'>
                                    My Portfolios
                                </p>
                                {props.portfolios.length > 0 && (
                                    <select
                                        className='form-select'
                                        value={props.selectedPortfolio.name}
                                        onChange={(e) => {
                                            props.handleSelectPortfolio(
                                                e.target.value
                                            )
                                        }}>
                                        {props.portfolios.map((portfolio) => {
                                            const { id, name } = portfolio
                                            return (
                                                <option key={id} value={name}>
                                                    {name}
                                                </option>
                                            )
                                        })}
                                    </select>
                                )}
                            </div>
                            <Button
                                variant='contained'
                                style={{
                                    backgroundColor: "rgb(75, 143, 205)",
                                    textTransform: "none"
                                }}
                                size='large'
                                className='step-seven h-fit w-1/2 text-white rounded-2 p-2 font-semibold text-lg shadow-md'
                                disableElevation
                                onClick={() => {
                                    setPortfolioSelectionModel(true)
                                }}>
                                Configure Portfolio
                            </Button>
                        </div>
                    </div>
                    <PortfolioSelectionModal
                        selectedPortfolio={props.selectedPortfolio}
                        modalToggle={portfolioSelectionModel}
                        setModalToggle={setPortfolioSelectionModel}
                        fetchUserPortfolios={props.fetchUserPortfolios}
                    />
                    {dashboardView.value === "STOCK" && (
                        <StockViewDashboard
                            displayDate={getDisplayDates()}
                            graphDateTime={graphDateTime}
                            checkFutureData={checkFutureData}
                            setMarketStatusKey={setMarketStatusKey}
                            setHideAlertsToggle={setHideAlertsToggle}
                            marketStatusKey={marketStatusKey}
                            setRun={setRun}
                            setGraphToggle={setGraphToggle}
                            graphDataCount={graphDataCount}
                            setViews={setViews}
                            setGraphDataCount={setGraphDataCount}
                            buzzGraph={buzzGraph}
                            sentiGraph={sentiGraph}
                            buzzGraphChange={buzzGraphChange}
                            sentiGraphChange={sentiGraphChange}
                            tickers={tickers}
                            formatDayjs={formatDayjs}
                            setGraphData={setGraphData}
                            graphViews={graphViews}
                            GRAPH_NORMALIZE_FACTOR={GRAPH_NORMALIZE_FACTOR}
                            setBuzzGraph={setBuzzGraph}
                            setSentiGraph={setSentiGraph}
                            setBuzzGraphChange={setBuzzGraphChange}
                            setSentiGraphChange={setSentiGraphChange}
                            renderAlerts={renderAlerts}
                            graphData={graphData}
                        />
                    )}

                    {dashboardView.value === "SECTOR" && (
                        <SectorViewDashboard
                            displayDate={getDisplayDates()}
                            graphDateTime={graphDateTime}
                            checkFutureData={checkFutureData}
                            setMarketStatusKey={setMarketStatusKey}
                            setHideAlertsToggle={setHideAlertsToggle}
                            marketStatusKey={marketStatusKey}
                            setRun={setRun}
                            setGraphToggle={setGraphToggle}
                            setViews={setViews}
                            buzzGraph={buzzGraph}
                            sentiGraph={sentiGraph}
                            buzzGraphChange={buzzGraphChange}
                            sentiGraphChange={sentiGraphChange}
                            tickers={api.get_sectors()}
                            formatDayjs={formatDayjs}
                            setGraphData={setGraphData}
                            setBuzzGraph={setBuzzGraph}
                            setSentiGraph={setSentiGraph}
                            setBuzzGraphChange={setBuzzGraphChange}
                            setSentiGraphChange={setSentiGraphChange}
                            graphViews={graphViews}
                            graphData={graphData}
                            GRAPH_NORMALIZE_FACTOR={GRAPH_NORMALIZE_FACTOR}
                        />
                    )}
                </div>
            </Modal>
        </div>
    )
}

export default TrendingGraphs
