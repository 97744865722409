import { alertConfig } from "../../../constants"

const AlertCard = ({ stock, result, color }) => {
    return (
        <div className='flex items-center flex-column'>
            <div className='justify-evenly w-full items-end gap-2 px-2 py-2 flex border border-neutral-300'>
                <h1 className='text-black text-xl font-bold whitespace-nowrap m-0'>
                    $ {stock}
                </h1>
                <div
                    className={`min-w-12 px-2 py-2 ${alertConfig[result].color} rounded justify-center items-center flex whitespace-nowrap`}>
                    <p className='text-white text-sm font-semibold m-0'>
                        {alertConfig[result].label}
                    </p>
                </div>
            </div>
            <div className={`w-full h-1 ${color}`} />
        </div>
    )
}

export default AlertCard
