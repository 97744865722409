import { MenuItem, ListItemIcon } from "@material-ui/core"
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward"
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward"

const SortMenuItem = ({
    index,
    sortKey,
    selectedSourceIndex,
    handleClosePopup,
    sourceName,
    getSortData,
    setSortSource,
    ascending,
    setAscending
}) => {
    const handleSortClick = () => {
        setAscending((prevAscending) => !prevAscending)
        setSortSource(sortKey)
        getSortData(sourceName, sortKey, ascending)
        handleClosePopup()
    }

    return (
        <MenuItem
            selected={index === selectedSourceIndex}
            onClick={handleSortClick}>
            {sortKey}
            {index === selectedSourceIndex && (
                <ListItemIcon>
                    {ascending ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}
                </ListItemIcon>
            )}
        </MenuItem>
    )
}

export default SortMenuItem
