import React, { useEffect, useState } from "react"
import { useAuthState } from "react-firebase-hooks/auth"
import { useNavigate } from "react-router-dom"
import { Link } from "react-router-dom"
import { auth, sendPasswordReset } from "./firebase"
import "./static/Reset.css"
// import loginBackground from "./static/faang_login_bg_new-min.jpg"
import Background from './static/research-login-background.png'

import CssBaseline from "@mui/material/CssBaseline"
import Box from "@mui/material/Box"
import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"
import { createTheme, ThemeProvider } from "@mui/material/styles"

const Copyright = (props) => {
    return (
        <Typography
            variant='body2'
            color='text.secondary'
            align='center'
            {...props}>
            {"Copyright © "}
            <Link color='inherit' href={process.env.REACT_APP_APP_URL}>
                FinSoftAi
            </Link>{" "}
            {new Date().getFullYear()}
            {"."}
        </Typography>
    )
}

const theme = createTheme()

function Reset() {
    const [email, setEmail] = useState("")
    const [user, loading, error] = useAuthState(auth)
    const history = useNavigate()
    useEffect(() => {
        if (loading) return
        if (user) history.push("/dashboard")
    }, [user, loading])
    return (
        <ThemeProvider theme={theme}>
            <Grid container component='main' sx={{ height: "100vh" }}>
                <CssBaseline />
                <Grid
                    justifyContent={"flex-end"}
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    sx={{
                        backgroundImage: `url(${Background})`,
                        // backgroundRepeat: 'no-repeat',
                        backgroundColor: (t) =>
                            t.palette.mode === "light"
                                ? t.palette.grey[50]
                                : t.palette.grey[900],
                        backgroundSize: 'cover',
                        backgroundPosition: "center"
                    }}>
                    <Box
                        className='reset_box'
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "end",
                            height: "100%",
                            width: "100%",
                            backgroundColor: "transparent",
                            pr: "5%"
                        }}>
                        <Box sx={{ mt: "auto", mb: "5%", width: "40%" }}>
                            <div className='reset__container'>
                                <Typography
                                    className='mb-2'
                                    component='h3'
                                    variant='h4'
                                    style={{ color: "#000", fontWeight: 400 }}>
                                    Forgot Password?
                                </Typography>
                                <input
                                    type='text'
                                    className='reset__textBox'
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    placeholder='E-mail Address'
                                />
                                <button
                                    className='reset__btn'
                                    onClick={() => sendPasswordReset(email)}>
                                    Send email
                                </button>
                                <div>
                                    Don't have an account?{" "}
                                    <Link to='/register'>Register now.</Link>
                                </div>
                                <div>
                                    Completed password reset?{" "}
                                    <Link to='/login'>Login now.</Link>
                                </div>

                                <Copyright sx={{ mt: 2 }} />
                            </div>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </ThemeProvider>
    )
}
export default Reset
