import React, { Component, useEffect, useState } from "react"
import { makeStyles } from "@material-ui/core"
import {
    Box,
    TextField,
    Grid,
    Typography,
    Button,
    Paper,
    Table,
    IconButton,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TableFooter
} from "@mui/material"
import Slider from "@mui/material/Slider"
import MuiInput from "@mui/material/Input"
import { useTheme } from "@mui/material/styles"
import TableSortLabel from "@mui/material/TableSortLabel"
import newsWeights from "./static/news_dq.json"
import { visuallyHidden } from "@mui/utils"
import PropTypes from "prop-types"
import FirstPageIcon from "@mui/icons-material/FirstPage"
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft"
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight"
import LastPageIcon from "@mui/icons-material/LastPage"

const useStyles = makeStyles((theme) => ({
    container: {
        display: "flex",
        alignItems: "center",
        marginTop: "2%"
    },
    border: {
        borderBottom: "4px solid black",
        width: "100%"
    },
    content: {
        // paddingTop: theme.spacing(0.5),
        paddingBottom: theme.spacing(0.5),
        paddingRight: theme.spacing(2),
        paddingLeft: theme.spacing(2),
        fontWeight: 500,
        fontSize: 20,
        color: "black"
    },
    rail: {
        // width:"60px !important",
        // color: "red"
    }
}))

let weights = {
    Credibility: {
        User_classification: 0.3,
        User_premium_room: 0.12,
        User_plus_tier: 0.05,
        User_following: 0,
        User_official: 0.08,
        User_follower: 0.35,
        User_join_date: 0.1
    },
    Usefulness: {
        ST_Sentiment: 0.2,
        likes: 0.8
    },
    Completeness: {
        Body_20: 0.4,
        Body_140: 0.75,
        Body_1000: 1
    },
    Weights: {
        follower_max: 20000,
        follower_min: 5,
        likes_max: 100,
        likes_min: 0
    }
}

let dq_total_weights = {
    Credibility: 0.7,
    Usefulness: 0.2,
    Completeness: 0.1
}

const columns = [
    { id: "name", label: "Name", minWidth: 150, vals: "name" },
    { id: "category", label: "Category", minWidth: 100, vals: "category" },
    {
        id: "language",
        label: "Language",
        minWidth: 120,
        vals: "language"
        // align: 'right',
        // format: (value) => value.toLocaleString('en-US'),
    },
    {
        id: "country",
        label: "Country",
        minWidth: 120,
        vals: "country"
        // align: 'right',
        // format: (value) => value.toLocaleString('en-US'),
    },
    {
        id: "score",
        label: "Score",
        minWidth: 100,
        vals: "Muliply"
        // align: 'right',
        // format: (value) => value.toFixed(2),
    }
    // {
    //   id: 'initial_score',
    //   label: 'Initial Score',
    //   minWidth: 80,
    //   vals: "Muliply"
    //   // align: 'right',
    //   // format: (value) => value.toFixed(2),
    // },
]

function TablePaginationActions(props) {
    const theme = useTheme()
    const { count, page, rowsPerPage, onPageChange } = props

    const handleFirstPageButtonClick = (event) => {
        onPageChange(event, 0)
    }

    const handleBackButtonClick = (event) => {
        onPageChange(event, page - 1)
    }

    const handleNextButtonClick = (event) => {
        onPageChange(event, page + 1)
    }

    const handleLastPageButtonClick = (event) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1))
    }

    return (
        <Box sx={{ flexShrink: 0, ml: 2.5 }}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label='first page'>
                {theme.direction === "rtl" ? (
                    <LastPageIcon />
                ) : (
                    <FirstPageIcon />
                )}
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label='previous page'>
                {theme.direction === "rtl" ? (
                    <KeyboardArrowRight />
                ) : (
                    <KeyboardArrowLeft />
                )}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label='next page'>
                {theme.direction === "rtl" ? (
                    <KeyboardArrowLeft />
                ) : (
                    <KeyboardArrowRight />
                )}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label='last page'>
                {theme.direction === "rtl" ? (
                    <FirstPageIcon />
                ) : (
                    <LastPageIcon />
                )}
            </IconButton>
        </Box>
    )
}

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired
}

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1
    }
    if (b[orderBy] > a[orderBy]) {
        return 1
    }
    return 0
}

function getComparator(order, orderBy) {
    console.log(orderBy, "3")
    return order === "desc"
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy)
}
function stableSort(array, comparator) {
    console.log(array, "ARR", comparator)
    const stabilizedThis = array.map((el, index) => [el, index])
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0])
        if (order !== 0) {
            return order
        }
        return a[1] - b[1]
    })
    return stabilizedThis.map((el) => el[0])
}

function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort } = props
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property)
    }

    return (
        <TableHead>
            <TableRow>
                {columns.map((column) => (
                    <TableCell
                        key={column.id}
                        sortDirection={orderBy === column.id ? order : false}>
                        <TableSortLabel
                            active={orderBy === column.id}
                            direction={orderBy === column.id ? order : "asc"}
                            onClick={createSortHandler(column.id)}>
                            {column.label}
                            {orderBy === column.id ? (
                                <Box component='span' sx={visuallyHidden}>
                                    {order === "desc"
                                        ? "sorted descending"
                                        : "sorted ascending"}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    )
}

EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(["asc", "desc"]).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired
}
const createData = (name, category, language, country, score) => {
    return { name, category, language, country, score }
}

const DQNewsHomepage = (props) => {
    const [DQWeights, setDQWeights] = useState(newsWeights)
    const [DQParamWeights, setDQParamWeights] = useState(dq_total_weights)
    const [page, setPage] = React.useState(0)
    const [rowsPerPage, setRowsPerPage] = React.useState(10)
    const [order, setOrder] = React.useState("asc")
    const [orderBy, setOrderBy] = React.useState("name")
    const classes = useStyles()
    const handleChangePage = (event, newPage) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value)
        setPage(0)
    }

    const changeWeights = (name, value) => {
        const newState = DQWeights.map((obj) => {
            if (obj.name === name) {
                return { ...obj, Muliply: parseFloat(value) }
            }

            // 👇️ otherwise return the object as is
            return obj
        })
        setDQWeights(() => {
            return newState
        })
    }

    const changeParamWeights = (key, value) => {
        const updatedValue = { key: value }
        setDQParamWeights((prevValue) => {
            return {
                ...prevValue,
                ...updatedValue
            }
        })
    }
    const resetWeights = () => {
        setDQWeights((prevState) => {
            return newsWeights
        })
    }
    const handleRequestSort = (event, property) => {
        const foundRow = columns.find((row) => row.id === property)
        const isAsc = orderBy === property && order === "asc"
        setOrder(isAsc ? "desc" : "asc")
        const propertyVals = {}
        setOrderBy(foundRow["vals"])
    }

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - DQWeights.length) : 0

    const visibleRows = stableSort(
        DQWeights,
        getComparator(order, orderBy)
    ).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    useEffect(() => {
        console.log(visibleRows)
        props.setWeights(DQWeights)
    }, [DQWeights])
    useEffect(() => {}, [order])
    useEffect(() => {
        props.setWeights(DQWeights)
    }, [])
    return (
        <>
            <div
                className='container-fluid'
                style={{ backgroundColor: "whitesmoke" }}>
                <div className='row w-100 justify-content-center'>
                    <Typography variant='h4' align='center'>
                        News Data Quality Weights
                    </Typography>
                    <Box>
                        <Paper sx={{ width: "100%" }}>
                            <TableContainer sx={{ maxHeight: 500 }}>
                                <Table
                                    size='small'
                                    stickyHeader
                                    aria-label='sticky table'>
                                    {/* <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead> */}
                                    <EnhancedTableHead
                                        order={order}
                                        orderBy={orderBy}
                                        onRequestSort={handleRequestSort}
                                        rowCount={DQWeights.length}
                                    />
                                    <TableBody>
                                        {visibleRows
                                            // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                            .map((row) => {
                                                return (
                                                    <TableRow
                                                        hover
                                                        role='checkbox'
                                                        tabIndex={-1}
                                                        key={row.name}
                                                        sx={{
                                                            cursor: "pointer"
                                                        }}>
                                                        {columns.map(
                                                            (column) => {
                                                                const value =
                                                                    row[
                                                                        column
                                                                            .vals
                                                                    ]
                                                                return (
                                                                    <TableCell
                                                                        key={
                                                                            column.id
                                                                        }
                                                                        align={
                                                                            column.align
                                                                        }>
                                                                        {column.id ===
                                                                        "score" ? (
                                                                            <Grid
                                                                                container
                                                                                spacing={
                                                                                    1
                                                                                }
                                                                                alignItems='center'>
                                                                                <Grid
                                                                                    item>
                                                                                    <Slider
                                                                                        min={
                                                                                            0
                                                                                        }
                                                                                        max={
                                                                                            1
                                                                                        }
                                                                                        step={
                                                                                            0.01
                                                                                        }
                                                                                        style={{
                                                                                            width: 90
                                                                                        }}
                                                                                        value={
                                                                                            typeof value ===
                                                                                            "number"
                                                                                                ? value
                                                                                                : 0
                                                                                        }
                                                                                        classes={{
                                                                                            rail: classes.rail
                                                                                        }}
                                                                                        //  onChange={handleSliderChange}
                                                                                        onChange={(
                                                                                            event
                                                                                        ) => {
                                                                                            changeWeights(
                                                                                                row[
                                                                                                    "name"
                                                                                                ],
                                                                                                event
                                                                                                    .target
                                                                                                    .value
                                                                                            )
                                                                                        }}
                                                                                        aria-labelledby='input-slider'
                                                                                    />
                                                                                </Grid>
                                                                                <Grid
                                                                                    item>
                                                                                    <TextField
                                                                                        type='number'
                                                                                        size='small'
                                                                                        varient='outlined'
                                                                                        style={{
                                                                                            maxWidth:
                                                                                                "50%"
                                                                                        }}
                                                                                        onChange={(
                                                                                            event
                                                                                        ) => {
                                                                                            changeWeights(
                                                                                                row[
                                                                                                    "name"
                                                                                                ],
                                                                                                event
                                                                                                    .target
                                                                                                    .value
                                                                                            )
                                                                                        }}
                                                                                        value={
                                                                                            value
                                                                                        }
                                                                                    />
                                                                                </Grid>
                                                                            </Grid>
                                                                        ) : column.format &&
                                                                          typeof value ===
                                                                              "number" ? (
                                                                            column.format(
                                                                                value
                                                                            )
                                                                        ) : (
                                                                            value
                                                                        )}
                                                                    </TableCell>
                                                                )
                                                            }
                                                        )}
                                                    </TableRow>
                                                )
                                            })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TableRow>
                                <TablePagination
                                    rowsPerPageOptions={[5, 10, 25]}
                                    colSpan={3}
                                    count={DQWeights.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    SelectProps={{
                                        inputProps: {
                                            "aria-label": "rows per page"
                                        },
                                        native: true
                                    }}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={
                                        handleChangeRowsPerPage
                                    }
                                    ActionsComponent={TablePaginationActions}
                                />
                            </TableRow>
                        </Paper>
                    </Box>
                    <div
                        className='row mt-2'
                        style={{ textAlign: "end", width: "auto" }}>
                        <Button
                            className='col-md-2'
                            variant='contained'
                            onClick={() => {
                                resetWeights()
                            }}
                            style={{
                                backgroundColor: "#034ea2",
                                color: "white",
                                marginBottom: 5,
                                fontWeight: 400,
                                fontSize: 20,
                                borderRadius: 10,
                                textTransform: "capitalize"
                            }}>
                            Reset
                        </Button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DQNewsHomepage
